/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import NumberFormatter from "../../../../../util/NumberFormatter";
import SimpleInput from "../../../../../components/InputFieldsSimple/SimpleInput";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import { TreatmentResponse } from "../../../../../models/treatment/TreatmentResponse";
import TreatmentCostCalculator from "../../../../../util/TreatmentCostCalculator";
import Button from "../../../../../components/Button";
import { PlusIcon } from "../../../../../common/Icons/PlusIcon";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";

interface Props {
  choose(treatment: TreatmentResponse, quantity: number): void;
  countryDetails?: CountryDetailsResponse;
  index: number;
  result: TreatmentResponse;
}

const TreatmentResultRow = ({ choose, countryDetails, result, index }: Props): ReactElement => {
  const [quantity, setQuantity] = useState<number>(1);

  return (
    <tr key={index} className="px-4 py-2 text-sm font-medium whitespace-nowrap">
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        <b>{result.name}</b>
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(
          TreatmentCostCalculator.getNetTotalPrice(result),
          result.currency
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {result.vat} %
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(
          TreatmentCostCalculator.getGrossTotalPrice(result, countryDetails?.roundingDecimalLength),
          result.currency
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
        <SimpleInput
          autoComplete={AutoCompleteOptions.off}
          className="m-0"
          name="quantity"
          onChange={(value: string) => {
            setQuantity(value.length > 0 ? Number(value) : 1);
          }}
          type="number"
          value={quantity}
          width="80px"
        />
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        <div className="flex justify-end">
          <Button
            className="p-1"
            fullWidth={false}
            onClick={() => {
              choose(result, quantity);
            }}
          >
            <PlusIcon />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TreatmentResultRow;
