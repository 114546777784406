/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  forwardRef,
  Fragment,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import {
  petSpecies,
  quantityUnits,
  strings,
} from "../../common/Strings/Strings";
import CostCalculator from "../../util/CostCalculator";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import NumberFormatter from "../../util/NumberFormatter";
import { Tooltip } from "../../components/Tooltip";
import Field from "../../components/ReactHookFormFields/General/Field";
import { InventoryItemResponse } from "../../models/inventory/item/InventoryItemResponse";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import { PetSpecies } from "../../models/pet/PetSpeciesEnum";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";

interface Props {
  action: (value: number, item: InventoryItemResponse) => void;
  countryDetails?: CountryDetailsResponse;
  item: InventoryItemResponse;
  onDirty(): void;
}

export interface InventoryItemRowRef {
  resetForm(): void;
}

interface InventoryRefillForm {
  refillMultiplierFirst: number;
  refillMultiplierSecond: number;
  refillMultiplierThird: number;
  multiplicationResult: number | null;
}

const defaultFormValues = {
  refillMultiplierFirst: 1,
  refillMultiplierSecond: 1,
  refillMultiplierThird: 1,
  multiplicationResult: null,
};

const InventoryItemRow = forwardRef(
  (
    { item, countryDetails, action, onDirty }: Props,
    ref: Ref<InventoryItemRowRef>
  ): ReactElement => {
    const {
      watch,
      setValue,
      register,
      reset,
      formState: { errors, isValid, isDirty, dirtyFields },
    } = useForm<InventoryRefillForm>({
      mode: "onChange",
      defaultValues: defaultFormValues,
    });

    useImperativeHandle(ref, () => ({
      resetForm: reset,
    }));

    // Subscribe to the form's fields' changes
    const [
      refillMultiplierFirst,
      refillMultiplierSecond,
      refillMultiplierThird,
      multiplicationResult,
    ] = watch([
      "refillMultiplierFirst",
      "refillMultiplierSecond",
      "refillMultiplierThird",
      "multiplicationResult",
    ]);

    useEffect(() => {
      const enableSettingRefillProduct =
        dirtyFields.refillMultiplierFirst ||
        dirtyFields.refillMultiplierSecond ||
        dirtyFields.refillMultiplierThird;

      if (isValid && enableSettingRefillProduct) {
        const refillProduct =
          refillMultiplierFirst *
          refillMultiplierSecond *
          refillMultiplierThird;
        setValue("multiplicationResult", refillProduct);
      } else {
        setValue(
          "multiplicationResult",
          defaultFormValues.multiplicationResult
        );
      }
    }, [
      isValid,
      refillMultiplierFirst,
      refillMultiplierSecond,
      refillMultiplierThird,
      setValue,
      dirtyFields,
    ]);

    // If the multiplication result is set (directly or indirectly), trigger the action
    useEffect(() => {
      if (action !== undefined && multiplicationResult !== null) {
        action(multiplicationResult, item);
      }
    }, [action, item, multiplicationResult]);

    useEffect(() => {
      isDirty && onDirty();
    }, [isDirty, onDirty]);

    return (
      <>
        <td className="px-3 py-2 text-sm font-medium">
          <div className="flex">
            <div>
              {item.stock !== undefined &&
              item.lowLevel !== undefined &&
              item.stock <= item.lowLevel ? (
                <Tooltip content={strings.lowLevel} placement="top">
                  <ExclamationMark className="text-red-500 w-5 h-5 mr-2" />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
            <div>
              {item.name}
              <div className="text-xs text-gray-500">
                {quantityUnits[item?.acquisitionUnit || ""]}
              </div>
            </div>
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium">{item.barcode}</td>
        <td className="px-3 py-2 text-sm font-medium">
          {item.species?.map((s: PetSpecies, index: number) => (
            <Fragment key={index}>
              {petSpecies[s]}
              {item.species && item.species[index + 1] ? ", " : ""}
            </Fragment>
          ))}
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          <span
            className={
              item.stock !== undefined &&
              item.lowLevel !== undefined &&
              item.stock <= item.lowLevel
                ? "text-red-600"
                : ""
            }
          >
            {item.stock ? NumberFormatter.format(item.stock) : "-"}
          </span>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          <div className="d-flex align-items-center">
            <form className="d-flex align-items-center">
              <Field
                classInput="tw-input text-center"
                className="mb-0 mr-1"
                name="refillMultiplierFirst"
                type="number"
                register={register}
                autoComplete={AutoCompleteOptions.off}
                error={errors.refillMultiplierFirst}
                disableNotNumberCharacters
                width="3rem"
                sm
              />
              x
              <Field
                classInput="tw-input text-center"
                className="mb-0 mx-1"
                name="refillMultiplierSecond"
                type="number"
                register={register}
                autoComplete={AutoCompleteOptions.off}
                error={errors.refillMultiplierSecond}
                disableNotNumberCharacters
                width="3rem"
                sm
              />
              x
              <Field
                classInput="tw-input text-center"
                className="mb-0 mx-1"
                name="refillMultiplierThird"
                type="number"
                register={register}
                autoComplete={AutoCompleteOptions.off}
                error={errors.refillMultiplierThird}
                disableNotNumberCharacters
                width="3rem"
                sm
              />
            </form>
            =
            <Field
              className="mb-0 mx-1"
              name="multiplicationResult"
              type="number"
              register={register}
              autoComplete={AutoCompleteOptions.off}
              error={errors.multiplicationResult}
              disableNotNumberCharacters
              width="5rem"
              sm
            />
            {quantityUnits[item?.acquisitionUnit || ""]}
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {item.acquisitionVat !== undefined ? `${item.acquisitionVat}%` : ""}
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPriceOfItem({
              ...item,
              quantity: 1,
              vat: item.acquisitionVat,
              price: item.acquisitionPrice || item.acquisitionPrice || 0,
            }, countryDetails?.roundingDecimalLength),
            item.acquisitionCurrency
          )}
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              item.acquisitionPrice || 0,
              item.acquisitionCurrency
            )}{" "}
            {strings.plusVat}
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {item.sellingVat !== undefined ? `${item.sellingVat}%` : ""}
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPriceOfItem({
              ...item,
              quantity: 1,
              vat: item.sellingVat,
              price: item.sellingPrice || item.sellingPrice || 0,
            }, countryDetails?.roundingDecimalLength),
            item.sellingCurrency
          )}
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              item.sellingPrice || 0,
              item.sellingCurrency
            )}{" "}
            {strings.plusVat}
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {item.accountingCode}
        </td>
      </>
    );
  }
);

export default InventoryItemRow;
