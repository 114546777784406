/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { strings } from "../../../../common/Strings/Strings";
import { InvoiceItemRequest } from "../../../../models/invoice/InvoiceItemRequest";
import AddInventoryItems from "./Inventory/AddInventoryItems";
import AddFees from "./Fee/AddFees";
import AddTreatments from "./Treatment/AddTreatments";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import NewCustomCost from "./NewCustomCost";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  addToCart(item: InvoiceItemRequest): void;
}

enum InvoiceTabs {
  Inventory = "Inventory",
  Fee = "Fee",
  Treatment = "Treatment",
}

const AddProducts = ({
  clinicId,
  countryDetails,
  addToCart,
}: Props): ReactElement => {
  const [tabKey, setTabKey] = useState<InvoiceTabs>();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false);

  return (
    <>
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
          {strings.addProducts}
        </h2>
      </div>
      <div className="settings-tabs p-4">
        <Tabs
          id="invoiceTabs"
          activeKey={tabKey}
          onSelect={(k: any): void => {
            setTabKey(k);
          }}
        >
          <Tab eventKey={InvoiceTabs.Inventory} title={strings.inventory}>
            <AddInventoryItems
              addCustomCost={() => {
                setIsAddNewModalOpen(true);
              }}
              addToCart={addToCart}
              clinicId={clinicId}
              countryDetails={countryDetails}
            />
          </Tab>
          <Tab eventKey={InvoiceTabs.Fee} title={strings.fees}>
            <AddFees
              addCustomCost={() => {
                setIsAddNewModalOpen(true);
              }}
              addToCart={addToCart}
              countryDetails={countryDetails}
            />
          </Tab>
          <Tab eventKey={InvoiceTabs.Treatment} title={strings.treatments}>
            <AddTreatments
              addCustomCost={() => {
                setIsAddNewModalOpen(true);
              }}
              addToCart={addToCart}
              countryDetails={countryDetails}
            />
          </Tab>
        </Tabs>
      </div>
      <Modal
        handleClose={() => {
          setIsAddNewModalOpen(false);
        }}
        show={isAddNewModalOpen}
      >
        <Modal.Header title={strings.addNew} />
        <Modal.Body>
          <NewCustomCost
            countryDetails={countryDetails}
            submit={(form: InvoiceItemRequest) => {
              addToCart(form);
              setIsAddNewModalOpen(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProducts;

AddProducts.defaultProps = {
  clinicId: undefined,
};
