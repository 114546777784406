/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import AlertBox from "../../../../../../components/AlertBox";
import { strings } from "../../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import { UserPetResponse } from "../../../../../../models/pet/UserPetResponse";
import { PublicPet } from "../../../../../../models/pet/PublicPet";
import CloseButton from "../../../../../../components/CloseButton";
import RadioButtonGroup from "../../../../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import CombinedSelect from "../../../../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { PetDetailsResponse } from "../../../../../../models/pet/PetDetailsResponse";
import { ClinicPetToUserPetRequest } from "../../../../../../models/pet/ClinicPetToUserPetRequest";
import logger from "../../../../../../util/logger";
import PetOwnerApi from "../../../../../../api/PetOwnerApi";
import Modal from "../../../../../../components/Modal/Modal";
import Button from "../../../../../../components/Button";
import PetProfilePicture from "../../../../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  close(): void;
  isOpen: boolean;
  myPets?: UserPetResponse[];
  pet?: PublicPet;
  petOwnerId?: string;
  setSuccess(value: boolean): void;
}

interface AcceptForm {
  petType: "new" | "existing";
  existingPet?: PetDetailsResponse[] | null;
}

const AcceptOwnershipModal = ({
  close,
  isOpen,
  myPets = [],
  pet,
  petOwnerId,
  setSuccess,
}: Props): ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const { control, handleSubmit } = useForm<AcceptForm>({
    defaultValues: { petType: "new" },
  });

  const submitAccept = async (values: AcceptForm) => {
    if (pet && petOwnerId) {
      setSubmitting(true);
      const requestBody: ClinicPetToUserPetRequest[] = [
        {
          clinicPetId: pet.id,
          userPetId: values.existingPet ? values.existingPet[0].id : undefined,
        },
      ];
      try {
        await PetOwnerApi.assignPetOwnerToUser(petOwnerId, requestBody);
        setSuccess(true);
      } catch (e) {
        setError(await getGeneralError(e));
        logger.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.acceptOwnership} />
      <Modal.Body>
        <div className="flex items-center">
          <div className="w-[80px] h-[80px]">
            <PetProfilePicture species={pet?.species} />
          </div>
          <b className="pl-5">{pet?.name}</b>
        </div>
        <form>
          <RadioButtonGroup
            control={control}
            name="petType"
            options={[
              { value: "new", label: strings.createNewPet },
              {
                value: "existing",
                label: (
                  <CombinedSelect
                    allowNew={false}
                    className="mt-5"
                    control={control}
                    label={strings.updateExistingPet}
                    labelKey="name"
                    name="existingPet"
                    options={myPets
                      .filter(
                        ({ userPetDetails }: UserPetResponse) =>
                          userPetDetails.species === pet?.species
                      )
                      .map(
                        ({ userPetDetails }: UserPetResponse) => userPetDetails
                      )}
                  />
                ),
              },
            ]}
          />
        </form>
        <AlertBox className="mt-5" message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          loading={submitting}
          onClick={handleSubmit(submitAccept)}
        >
          {strings.acceptOwnership}
        </Button>
        <CloseButton disabled={submitting} onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptOwnershipModal;
