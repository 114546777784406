/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { findChildByName } from "../../util/HtmlUtils";
import { PagerState } from "../../hooks/hooks";
import { PageResponse } from "../../models/PageResponse";
import PageableTableHead from "./PageableTableHead";
import LoaderInline from "../LoaderInline";
import EmptyListText from "../EmptyListText";
import PageableTableBody from "./PageableTableBody";
import AlertBox from "../AlertBox";
import PageSizeSelect from "../PageSizeSelect";
import Paging from "../Paging";

interface Props {
  children: ReactElement | ReactElement[];
  pager: PagerState;
  content?: PageResponse<any>;
  isLoading: boolean;
  error?: string | null;
}

type PageableTableChildrenTypes = {
  Head: React.FC;
  Body: React.FC;
};

type PageableTableComponent = React.FunctionComponent<Props> &
  PageableTableChildrenTypes;

const PageableTable: PageableTableComponent = ({
  children,
  pager,
  content,
  isLoading,
  error,
}: Props) => {
  const childrenArray = React.Children.toArray(children);

  const head = findChildByName(childrenArray, "PageableTableHead") || null;
  const body = findChildByName(childrenArray, "PageableTableBody") || null;

  return (
    <>
      <AlertBox message={error} className="mb-3" />
      <div className="tw-table-container">
        <table className="tw-table">
          <thead className="tw-thead">{head}</thead>
          <tbody hidden={!isLoading}>
            <tr>
              <td colSpan={1000} className="py-4">
                <LoaderInline />
              </td>
            </tr>
          </tbody>
          <tbody className="tw-tbody" hidden={isLoading}>
            <tr hidden={!content || content.elements.length > 0}>
              <td
                className="px-4 py-4 text-sm whitespace-nowrap"
                colSpan={1000}
              >
                <EmptyListText />
              </td>
            </tr>
            {body}
          </tbody>
        </table>
      </div>
      <div className="flex items-center">
        <PageSizeSelect
          pageSize={pager.pageSize}
          setPageSize={pager.setPageSize}
          totalPages={content?.totalPages || 0}
          totalResults={content?.totalElements || 0}
        />
        <div className="ml-auto">
          <Paging
            currentPage={pager.pageNumber}
            totalPages={content?.totalPages || 0}
            selectPage={(newPage: number) => {
              pager.setPageNumber(newPage);
            }}
          />
        </div>
      </div>
    </>
  );
};

PageableTable.Head = PageableTableHead;
PageableTable.Body = PageableTableBody;
export default PageableTable;
