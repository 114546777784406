/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import AlertBox from "../../components/AlertBox";
import { Loader } from "../../components/Loader";
import { PageProps } from "../../models/PageProps";
import { InventoryCategoryResponse } from "../../models/inventory/category/InventoryCategoryResponse";
import InventoryList from "./InventoryList";
import CreateCategoryModal from "./Modals/CreateCategoryModal";
import { getGeneralError } from "../../util/helperFunctions";
import InventoryCategoryApi from "../../api/InventoryCategoryApi";
import Button from "../../components/Button";
import LoaderInline from "../../components/LoaderInline";
import { useClinic } from "../../contexts/ClinicContext";

const Inventory: React.FC<PageProps> = (props: PageProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [newCategoryModal, setNewCategoryModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [categories, setCategories] = useState<InventoryCategoryResponse[]>([]);
  const { setPageLoading } = props;

  const { clinic } = useClinic();

  useEffect(() => {
    setPageLoading(!clinic);
  }, [clinic, setPageLoading]);

  useEffect(() => {
    const getInventoryCategories = async () => {
      if (!clinic?.id) return;
      try {
        setIsLoaded(false);
        const response = await InventoryCategoryApi.getAllCategories(clinic.id);

        response.data.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));

        response.data.forEach((cat) => {
          cat.items.sort((item1, item2) =>
            item1.name.localeCompare(item2.name)
          );
        });

        setCategories(
          response.data.map((i) => {
            i.name = strings[i.name] || i.name;
            return i;
          })
        );
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setIsLoaded(true);
      }
    };

    void getInventoryCategories();
  }, [clinic, reload]);

  const openNewCategoryModal = () => {
    setNewCategoryModal(true);
  };
  const closeNewCategoryModal = () => {
    setNewCategoryModal(false);
  };

  const triggerReload = () => {
    setReload((prev) => !prev);
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.inventory}
              </h1>
              <div className="flex space-x-4">
                <div>
                  <Link to={`/inventory/create-item`}>
                    <Button>{strings.newItem}</Button>
                  </Link>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      openNewCategoryModal();
                    }}
                  >
                    {strings.createCategory}
                  </Button>
                </div>
              </div>
            </div>
            <AlertBox message={error} />
            {isLoaded ? (
              <InventoryList
                categories={categories}
                clinicId={clinic?.id}
                updateList={reload}
                updateCategories={triggerReload}
              />
            ) : (
              <LoaderInline />
            )}
          </div>
        </div>
        <CreateCategoryModal
          clinicId={clinic?.id}
          isOpen={newCategoryModal}
          close={closeNewCategoryModal}
          reloadCategories={triggerReload}
        />
      </section>
    </main>
  );
};

export default Loader(Inventory);
