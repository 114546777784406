/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { BillingSystemConfigResponse } from "../../../models/management/BillingSystemConfigResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";

interface Props {
  countryCode: string;
  billingConfig?: BillingSystemConfigResponse;
}

const FortnoxIntegration: React.FC<Props> = (props: Props) => {
  const { countryCode, billingConfig } = props;

  return (
    <>
      <div className="card mb-4">
        <div className="d-flex block-label ">
          <div className="p-3">{strings.fortnoxInvoiceSystem}</div>
        </div>
        <div className="m-3">
          {billingConfig?.fortnoxIntegrationComplete ? (
            <AlertBox
              type={AlertType.SUCCESS}
              message={strings.integrated}
              closeAble={false}
            />
          ) : (
            <AlertBox
              type={AlertType.ERROR}
              message={strings.authenticationNeeded}
              closeAble={false}
            />
          )}
        </div>
        <div>
          <div className="hr-on-top d-flex p-3">
            <Link
              to={`/management/countries/${countryCode}/fortnox-auth`}
              className="btn btn-primary ml-auto"
            >
              {strings.authenticate}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FortnoxIntegration;
