/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { strings } from "../../common/Strings/Strings";
import Button from "../../components/Button";
import {
  clinicPlaceholderColored,
  doctorPlaceholder,
  dogMainPlaceholder,
  userRegistrationPlaceholder,
} from "../../assets/AssetConfig";
import StripeIntegration from "./Topics/StripeIntegration";
import { SupportTopics } from "./Topics";

const Support: React.FC = () => {
  const { user } = useUser();

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.helpCenter}
            </h1>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.guidesForGettingStarted}
              </h2>
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 flex flex-col justify-between tw-card text-center p-4 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <img
                      src={userRegistrationPlaceholder}
                      className="w-40"
                      alt="user-registration"
                    />
                    <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                      {strings.userRegistration}
                    </h2>
                  </div>
                  <div> {strings.userRegistrationContent}</div>
                  <Link to="/registration">{strings.signUpHere}</Link>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 flex flex-col justify-between tw-card text-center p-4 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <img
                      src={clinicPlaceholderColored}
                      className="w-40"
                      alt="clinic-registration"
                    />
                    <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                      {strings.clinicRegistration}
                    </h2>
                  </div>
                  <div>
                    {strings.formatString(
                      strings.registerFirst,
                      <Link className="tw-link-tercier" to="/registration">
                        {strings.signUpHere.toLowerCase()}
                      </Link>
                    )}
                    <br />
                    {strings.clinicRegistrationContent}
                  </div>
                  <Link to="/clinics/create">{strings.createClinic}</Link>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 flex flex-col justify-between tw-card text-center p-4 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <img
                      src={dogMainPlaceholder}
                      className="w-40"
                      alt="pet-owner-icon"
                    />
                    <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                      {strings.becomePetOwner}
                    </h2>
                  </div>
                  <div>
                    {strings.formatString(
                      strings.registerFirst,
                      <Link className="tw-link-tercier" to="/registration">
                        {strings.signUpHere.toLowerCase()}
                      </Link>
                    )}
                    <br />
                    {strings.becomePetOwnerContent}
                  </div>
                  <Link to="/add-new-pet">{strings.addPet}</Link>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-43 flex flex-col justify-between tw-card text-center p-4 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <img
                      src={doctorPlaceholder}
                      className="w-40"
                      alt="Doctor"
                    />

                    <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                      {strings.becomeClinicEmployee}
                    </h2>
                  </div>
                  <div>
                    {strings.formatString(
                      strings.registerFirst,
                      <Link className="tw-link-tercier" to="/registration">
                        {strings.signUpHere.toLowerCase()}
                      </Link>
                    )}
                    <br />
                    {strings.becomeClinicEmployeeContent}
                  </div>
                  <Link to="/globalvet/clinics">{strings.clinics}</Link>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="md:flex"><h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.topSupportTopics}
              </h2>
              <div className="md:ml-auto">
                <Link to={`/help/topics/${SupportTopics.all}`}>{strings.seeAllSupportTopics}</Link>
              </div>
              </div>
              <div className="grid grid-cols-12 gap-6">
                <StripeIntegration isPreview/>
              </div>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.needToContactUs}
              </h2>
              <div className="grid grid-cols-12 gap-6">
                <div className="space-y-3 tw-card p-4 space-y-4 col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 ">
                  <div>
                    {strings.formatString(
                      strings.feelFreeToCotactUs,
                      <Link
                        className="tw-link-tercier"
                        to="mailto:support@globalvet.com"
                      >
                        support@globalvet.com
                      </Link>
                    )}
                  </div>
                  <div className="flex items-center justify-center">
                    <span className="w-full border border-gray-200 dark:border-gray-700" />
                    <span className="px-4 text-gray-500 dark:text-gray-400">
                      {strings.OR}
                    </span>
                    <span className="w-full border border-gray-200 dark:border-gray-700" />
                  </div>
                  <div>
                    <Link
                      to={
                        user.userId ? "/support/tickets" : "/support/new-ticket"
                      }
                    >
                      <Button>{strings.contactSupport}</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Support;
