/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import UserManagementApi from "../../../api/UserManagementApi";
import { useDebouncedState, usePager } from "../../../hooks/hooks";
import { PageProps } from "../../../models/PageProps";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageResponse } from "../../../models/PageResponse";
import logger from "../../../util/logger";
import SearchInput from "../../../components/InputFieldsSimple/SearchInput";
import { UserManagementItemResponse } from "../../../models/management/UserManagementItemResponse";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { Link } from "react-router-dom";
import moment from "moment/moment";

function UsersPage(props: PageProps): ReactElement {
  const { setPageLoading } = props;

  const [users, setUsers] =
    useState<PageResponse<UserManagementItemResponse>>();

  const pager = usePager(50);
  const searchQuery = useDebouncedState("", 400);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async () => {
    setLoading(true);

    try {
      const response = await UserManagementApi.findUsers({
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
        query: searchQuery.value,
      });
      setUsers(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
      logger.error(err);
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    void getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pager.pageNumber, pager.pageSize, searchQuery.value]);

  return (
    <div className="px-4 lg:px-6 py-6">
      <div className="w-80 mb-3">
        <SearchInput
          clear={() => {
            searchQuery.setValue("");
            pager.setPageNumber(0);
          }}
          enableCreateButton={false}
          loading={loading}
          onChange={(value) => {
            searchQuery.setValue(value);
            pager.setPageNumber(0);
          }}
          placeholder={`${strings.search}...`}
          label={strings.search}
          value={searchQuery.value}
        />
      </div>
      <PageableTable
        pager={pager}
        isLoading={loading}
        content={users}
        error={error}
      >
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.name}</th>
            <th className="text-left tw-th">{strings.email}</th>
            <th className="text-left tw-th">{strings.phone}</th>
            <th className="text-left tw-th">{strings.lastActivity}</th>
            <th className="text-left tw-th"></th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {users &&
            users.elements.map((user, index: number) => (
              <tr key={index}>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <Link to={`/management/users/${user.userId}`}>
                    {user.fullName || user.email?.value}
                  </Link>
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  {user.email?.value}
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  {user.phone?.value}
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div hidden={!user.lastActivity}>
                    {moment(user.lastActivity).format(
                      dateAndTime.momentDateTimeFormat
                    )}
                  </div>
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div className="d-flex justify-content-end">
                    <span
                      hidden={!user.ownedClinics.length}
                      className="material-icons md-18 ml-3 mr-1"
                    >
                      apartment
                    </span>
                    <div hidden={!user.ownedClinics.length}>
                      {user.ownedClinics.length}
                    </div>
                    <span
                      hidden={!user.employments.length}
                      className="material-icons md-18 ml-3 mr-1"
                    >
                      medical_services
                    </span>
                    <div hidden={!user.employments.length}>
                      {user.employments.length}
                    </div>
                    <span
                      hidden={!user.pets.length}
                      className="material-icons md-18 ml-3 mr-1"
                    >
                      pets
                    </span>
                    <div hidden={!user.pets.length}>{user.pets.length}</div>
                  </div>
                </td>
              </tr>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
}

export default SiteManagerPage(UsersPage, strings.USERS);
