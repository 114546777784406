/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import CloseButton from "../../components/CloseButton";
import { downloadPaymentSummaryOfClinic } from "../../util/FileUtils";
import { SearchInvoices } from "../../models/apiparams/SearchInvoices";
import { InvoiceStatisticsResponse } from "../../models/invoice/InvoiceStatisticsResponse";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import LoaderInline from "../../components/LoaderInline";
import ClinicInvoiceApi from "../../api/ClinicInvoiceApi";
import AlertBox from "../../components/AlertBox";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import NumberFormatter from "../../util/NumberFormatter";
import InvoiceDateFilters, { DateTemplate } from "./InvoiceDateFilters";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";

interface Props {
  clinic?: ClinicResponse;
  close(): void;
  countryDetails?: CountryDetailsResponse;
  isOpen: boolean;
}

const IncomeSummaryModal = ({
  clinic,
  countryDetails,
  isOpen,
  close,
}: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reloadStatistics, setReloadStatistics] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<InvoiceStatisticsResponse>();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const filterFormMethods = useForm<SearchInvoices>({
    mode: "onChange",
    defaultValues: {
      endDate: new Date(),
      startDate: new Date(),
      dateTemplate: DateTemplate.today,
    },
  });
  const { getValues: getStatisticsFilterFormValues } = filterFormMethods;

  useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        let response: AxiosResponse<InvoiceStatisticsResponse>;
        const request = getStatisticsFilterFormValues();

        if (clinic) {
          response = await ClinicInvoiceApi.getPaymentSummary(
            clinic.id,
            request
          );
        } else {
          return;
        }

        setError(null);
        setStatistics(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      void getStatistics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadStatistics, isOpen]);

  const getPaymentSummary = async () => {
    setPdfLoading(true);

    try {
      if (clinic) {
        await downloadPaymentSummaryOfClinic(
          clinic.id,
          moment(getStatisticsFilterFormValues("startDate")).format(
            "YYYY-MM-DD"
          ),
          moment(getStatisticsFilterFormValues("endDate")).format("YYYY-MM-DD")
        );
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.incomeSummary} />
      <Modal.Body>
        <FormProvider {...filterFormMethods}>
          <InvoiceDateFilters
            reloadInvoiceList={() => {
              setReloadStatistics(!reloadStatistics);
            }}
          />
        </FormProvider>
        <AlertBox className="my-3" message={error} />
        {loading ? (
          <LoaderInline className="my-3" />
        ) : (
          <div className="card mt-3">
            <table className="table">
              <tbody>
                <tr>
                  <td className="no-border-on-top">{strings.cashIncome}</td>
                  <td className="no-border-on-top text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.cash,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td>{strings.cardIncome}</td>
                  <td className="text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.card,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td>{strings.transferIncome}</td>
                  <td className="text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.transfer,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td>{strings.directDebitIncome}</td>
                  <td className="text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.directDebit,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td>{strings.onlineIncome}</td>
                  <td className="text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.online,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td>{strings.insuranceIncome}</td>
                  <td className="text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.insurance,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="bold">{strings.totalIncome}</td>
                  <td className="text-right bold">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.total,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={close} />
        <Button
          className="modal-main-button"
          loading={pdfLoading}
          onClick={getPaymentSummary}
        >
          <i className="fas fa-file-pdf mr-2" />
          {strings.download}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IncomeSummaryModal;

IncomeSummaryModal.defaultProps = {
  clinic: undefined,
  countryDetails: undefined,
};
