/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import SelectCountry from "../../../components/ReactHookFormFields/Specific/SelectCountry";

const TestInvoiceBillingForm = (): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <div className="card flex-fill">
        <div className="block-label px-3 py-2">{strings.billingInfo}</div>
        <div className="p-3">
          <div className="d-flex">
            <div className="pr-3">
              <Field
                register={register}
                name="name"
                error={errors.name}
                label={strings.name}
                required
              />
            </div>
            <div className="pr-3">
              <Field
                name="taxNumber"
                error={errors.taxNumber}
                register={register}
                label={strings.taxNumber}
              />
            </div>
            <Field
              name="email"
              error={errors.email}
              register={register}
              label={strings.email}
              required
            />
          </div>
          <div className="d-flex">
            <div className="pr-3 flex-fill">
              <Field
                name="street"
                error={errors.street}
                register={register}
                label={strings.address}
              />
            </div>
            <div>
              <Field
                name="houseDetails"
                error={errors.houseDetails}
                register={register}
                label={strings.addressLine2}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="pr-3">
              <Field
                name="city"
                error={errors.city}
                register={register}
                label={strings.city}
              />
            </div>
            <div className="pr-3">
              <Field
                name="zip"
                error={errors.zip}
                register={register}
                label={strings.zip}
              />
            </div>
            <SelectCountry
              control={control}
              name="country"
              setValue={setValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestInvoiceBillingForm;
