/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { PageProps } from "../../../models/PageProps";
import { strings } from "../../../common/Strings/Strings";
import { CustomerForm } from "../../Accounting/CreateInvoice/PaymentAndCustomerInfo";
import CostCalculator from "../../../util/CostCalculator";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";
import { Loader } from "../../../components/Loader";
import ErrorModal from "../../Accounting/CreateInvoice/ErrorModal";
import CountryApi from "../../../api/CountryApi";
import TestInvoiceApi from "../../../api/TestInvoiceApi";
import { TestInvoiceResponse } from "../../../models/invoice/TestInvoiceResponse";
import { TestInvoiceRequest } from "../../../models/invoice/TestInvoiceRequest";
import NewCustomCost from "../../Accounting/CreateInvoice/Products/NewCustomCost";
import TestInvoiceBillingForm from "./TestInvoiceBillingForm";
import AddedItems from "../../Accounting/CreateInvoice/AddedItems";
import TestInvoiceSuccessModal from "./TestInvoiceSuccessModal";
import InvoiceCartUtils, { CartItem } from "../../../util/InvoiceCartUtils";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

const CreateTestInvoice = ({ setPageLoading }: PageProps) => {
  const { countryCode } = useParams<"countryCode">();

  const [invoiceResponse, setInvoiceResponse] = useState<TestInvoiceResponse>();

  const [error, setError] = useState<string | null>(null);
  const [sum, setSum] = useState<number>(0);
  const [cart, dispatch] = useReducer(InvoiceCartUtils.reducer, []);
  const [selectedCountry, setCountry] = useState<CountryDetailsResponse>();

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const methods = useForm<CustomerForm>({});

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    const loadCountry = async (code: string) => {
      try {
        const resp = await CountryApi.getCountryDetails(code);
        setCountry(resp.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    if(countryCode){
      void loadCountry(countryCode);
    }
    
  }, [countryCode]);

  useEffect(() => {
    const costItems = cart.map(item => ({
      price: item.price,
      quantity: item.quantity,
      vat: item.vat,
    }));

    setSum(CostCalculator.getTotalGrossPriceOfInvoiceItems(costItems, selectedCountry?.roundingDecimalLength));
  }, [cart, selectedCountry]);

  const addToCart = (item: CartItem) => {
    dispatch({
      type: "add",
      itemToAdd: { ...item, localId: item.localId || generateUUId() },
    });
  };

  const changeQuantity = (item: CartItem) => {
    dispatch({ type: "changeQuantity", itemToChange: item });
  };

  const changePrice = (item: CartItem) => {
    dispatch({ type: "changePrice", itemToChange: item });
  };

  const removeItem = (item: CartItem) => {
    dispatch({ type: "remove", itemToRemove: item });
  };

  const sendInvoice = async (request: TestInvoiceRequest) => {
    if(countryCode){
      setPageLoading(true);
      try {
        const response = await TestInvoiceApi.createInvoice(countryCode, request);
        setInvoiceResponse(response.data);
        setSuccessModal(true);
      } catch (err) {
        setError(await getGeneralError(err));
        setErrorModal(true);
      } finally {
        setPageLoading(false);
      }
    }
  };

  const submit = ({
    country,
    houseDetails,
    name,
    email,
    city,
    street,
    taxNumber,
    zip,
  }: CustomerForm) => {
    const filteredCart = [...cart.filter((item: CartItem) => !item.disabled)];

    const request: TestInvoiceRequest = {
      name,
      taxNumber,
      email,
      street,
      houseDetails,
      zip,
      city,
      countryCode: country?.length === 1 ? country[0].countryCode : undefined,
      items: filteredCart,
    };

    void sendInvoice(request);
  };

  return (
    <>
      <div className="fix-container">
        <div className="background-rectangle">
          <div className="container">
            <div className="justify-content-center d-flex flex-wrap align-items-center">
              <Link
                to="/management/settings"
                className="admin-title text-sky-500"
              >
                {strings.settings}
              </Link>
              <i className="fas fa-chevron-right mx-3" />
              <Link
                to={`/management/countries/${countryCode}/payment-and-billing`}
                className="admin-title text-sky-500"
              >
                {selectedCountry?.countryName}
              </Link>
              <i className="fas fa-chevron-right mx-3" />
              <span className="admin-title">{strings.testInvoice}</span>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-8">
                <div>
                  <FormProvider {...methods}>
                    <form>
                      <TestInvoiceBillingForm />
                    </form>
                  </FormProvider>
                </div>
                <div className="card mt-3">
                  <div className="block-label px-3 py-2">
                    {strings.addProducts}
                  </div>
                  <div className="settings-tabs">
                    <NewCustomCost
                      submit={addToCart}
                      countryDetails={selectedCountry}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <AddedItems
                  removeItem={removeItem}
                  changeQuantity={changeQuantity}
                  sum={sum}
                  cart={cart}
                  changePrice={changePrice}
                  currency={selectedCountry?.currency}
                  roundingDecimalLength={selectedCountry?.roundingDecimalLength}
                />
                <div className="card mt-3 p-3">
                  <AlertBox
                    message={strings.testInvoiceWarning}
                    type={AlertType.WARNING}
                    closeAble={false}
                  />
                  <div className="mt-3">
                    <Button
                      onClick={handleSubmit(submit)}
                      className="w-100"
                      disabled={!isDirty || cart.length === 0}
                    >
                      {strings.finish}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5" />
        </div>
      </div>
      {
        countryCode &&
        <TestInvoiceSuccessModal
          close={() => {
            setSuccessModal(false);
          }}
          isOpen={successModal}
          countryCode={countryCode}
          invoice={invoiceResponse}
        />    
      }
      <ErrorModal
        isOpen={errorModal}
        close={() => {
          setErrorModal(false);
        }}
        error={error}
      />
    </>
  );
};

export default Loader(CreateTestInvoice);
