/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useRef, useState } from "react";
import moment from "moment";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { PaymentMethod } from "../../../models/costaccounting/PaymentMethod";
import NumberFormatter from "../../../util/NumberFormatter";
import { CostEntryResponse } from "../../../models/costaccounting/CostEntryResponse";
import { Tooltip } from "../../../components/Tooltip";
import { PaymentType } from "../../../models/costaccounting/PaymentType";
import { CostAccountingStatus } from "../../../models/costaccounting/CostAccountingStatus";
import AddPayment from "./AddPayment";
import Tag, { TagType } from "../../../components/Tag";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import Button from "../../../components/Button";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { ArrowUnturnLeft } from "../../../common/Icons/ArrowUnturnLeft";
import EmptyListText from "../../../components/EmptyListText";
import AlertBox from "../../../components/AlertBox";

interface Props {
  error: string | null;
  invoice: InvoiceResponse;
  reload(): void;
  syncPayment: (entry: CostEntryResponse) => void;
  withdrawPayment: (entry: CostEntryResponse) => void;
}

const PaymentHistory = ({
  error,
  invoice,
  reload,
  withdrawPayment,
  syncPayment,
}: Props): ReactElement => {
  const refPaymentIcon = useRef(null);
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] =
    useState<boolean>(false);

  const getPaymentBookingState = (entry: CostEntryResponse) => {
    let icon;

    switch (entry.valid) {
      case false:
        icon = (
          <Tooltip
            content={entry.validationError || strings.failedToSendToFortnox}
            placement="top"
          >
            <ExclamationMark className="text-red-500" />
          </Tooltip>
        );
        break;
      case true:
        icon = (
          <Tooltip content={strings.sentToFortnox} placement="right">
            <CheckCircle className="text-green-600" />
          </Tooltip>
        );
        break;
      default:
        icon = undefined;
        break;
    }

    return icon;
  };

  const getPaymentType = (
    paymentType: PaymentType
  ): ReactElement | undefined => {
    switch (paymentType) {
      case PaymentType.CORRECTED:
        return (
          <Tag
            text={getStringFromEnumsOrReturn(paymentType)}
            type={TagType.secondary}
          />
        );
      case PaymentType.IMMEDIATE:
        return (
          <Tag
            text={getStringFromEnumsOrReturn(paymentType)}
            type={TagType.success}
          />
        );
      case PaymentType.ALREADY_PAID:
        return (
          <Tag
            text={getStringFromEnumsOrReturn(paymentType)}
            type={TagType.success}
          />
        );
      case PaymentType.FULL:
        return (
          <Tag
            text={getStringFromEnumsOrReturn(paymentType)}
            type={TagType.success}
          />
        );
      case PaymentType.PARTIAL:
        return (
          <Tag
            text={getStringFromEnumsOrReturn(paymentType)}
            type={TagType.primary}
          />
        );
      default:
        return <></>;
    }
  };

  const isEntryWithdrawable = (entry: CostEntryResponse): boolean => {
    if (invoice?.costAccounting?.editable !== true) {
      return false;
    }
    if (invoice?.costAccounting?.status === CostAccountingStatus.WITHDRAWN) {
      return false;
    }
    return (
      !entry.withdrawn &&
      (entry.paymentType === PaymentType.FULL ||
        entry.paymentType === PaymentType.PARTIAL)
    );
  };

  const isEntrySyncable = (entry: CostEntryResponse): boolean => {
    if (invoice?.costAccounting?.editable !== true) {
      return false;
    }
    return entry.valid === false;
  };

  return (
    <>
      <div className="w-full md:tw-card space-y-6 md:p-8 my-6 space-y-6">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.paymentHistory}
        </h2>
        <AlertBox message={error} />
        <div
          className="mt-3"
          hidden={
            invoice?.costAccounting?.status !== "UNRESOLVED" ||
            !invoice.costAccounting.editable
          }
        >
          <Button
            minW
            onClick={() => {
              setIsAddPaymentModalOpen(true);
            }}
          >
            {strings.markAsPaid}
          </Button>
        </div>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.date}</th>
                <th className="tw-th">{strings.payerName}</th>
                <th className="tw-th">{strings.paymentType}</th>
                <th className="tw-th">{strings.paymentMethod}</th>
                <th className="tw-th">Fortnox</th>
                <th className="tw-th text-right">{strings.paid}</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {invoice?.costAccounting?.historyOfCostAccounting?.length ===
              0 ? (
                <tr>
                  <td
                    className="px-4 py-4 text-sm whitespace-nowrap"
                    colSpan={7}
                  >
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <>
                  {invoice?.costAccounting?.historyOfCostAccounting?.map(
                    (entry, idx) => (
                      <tr key={`${entry.entryDate}-${idx}`}>
                        <td className="px-4 py-2 text-sm whitespace-nowrap">
                          {moment(entry.entryDate).format("LLL")}
                        </td>
                        <td className="px-4 py-2 text-sm whitespace-nowrap">
                          {entry.payerName}
                          <div className="small">
                            {entry.payer ? strings[entry.payer] : ""}
                          </div>
                          <div
                            hidden={
                              entry.paymentMethod?.type !== PaymentMethod.NONE
                            }
                          >
                            {entry.paymentMethod?.name}
                          </div>
                        </td>
                        <td className="px-4 py-2 text-sm whitespace-nowrap">
                          {getPaymentType(entry.paymentType)}
                        </td>
                        <td className="px-4 py-2 text-sm whitespace-nowrap">
                          <div
                            hidden={
                              entry.paymentMethod?.type === PaymentMethod.NONE
                            }
                          >
                            {entry.paymentMethod?.name}
                          </div>
                        </td>
                        <td
                          className="px-4 py-2 text-sm whitespace-nowrap"
                          ref={refPaymentIcon}
                        >
                          <div className=" flex items-center space-x-2">
                            {getPaymentBookingState(entry)}
                            <Button
                              fullWidth={false}
                              onClick={() => {
                                syncPayment(entry);
                              }}
                              hidden={!isEntrySyncable(entry)}
                              small
                              variant="primary"
                            >
                              {strings.sendToFortnox}
                            </Button>
                          </div>
                        </td>
                        <td className="text-right px-4 py-2 text-sm whitespace-nowrap">
                          {NumberFormatter.formatPrice(
                            entry.amount,
                            invoice?.costAccounting?.currency
                          )}
                        </td>
                        <td className="text-right px-4 py-2 text-sm whitespace-nowrap">
                          <Tooltip
                            content={strings.withdrawPayment}
                            placement="top"
                          >
                            <Button
                              className="tw-link"
                              onClick={() => {
                                withdrawPayment(entry);
                              }}
                              hidden={!isEntryWithdrawable(entry)}
                              variant="icon"
                            >
                              <ArrowUnturnLeft className="h-5 w-5" />
                            </Button>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
              <tr>
                <td colSpan={4} />
                <td className="px-4 py-2 text-sm whitespace-nowrap">
                  <div>{strings.paid}</div>
                  <div className="bold">{strings.remainingCost}</div>
                </td>
                <td className="px-4 py-2 text-sm whitespace-nowrap text-right">
                  <div>
                    {NumberFormatter.formatPrice(
                      (invoice?.costAccounting?.totalCost || 0) -
                        (invoice?.costAccounting?.remainingCost || 0),
                      invoice?.costAccounting?.currency
                    )}
                  </div>
                  <div className="font-bold">
                    {NumberFormatter.formatPrice(
                      invoice?.costAccounting?.remainingCost || 0,
                      invoice?.costAccounting?.currency
                    )}
                  </div>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <AddPayment
          close={() => {
            setIsAddPaymentModalOpen(false);
          }}
          invoice={invoice}
          isOpen={isAddPaymentModalOpen}
          reload={() => {
            setIsAddPaymentModalOpen(false);
            reload();
          }}
        />
      </div>
    </>
  );
};

export default PaymentHistory;
