/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { strings } from "../../common/Strings/Strings";
import WeekController from "./Week/WeekController";
import {
  calendarSelectedDayKey,
  calendarSelectedViewKey,
} from "../../util/LocalStorageVariables";
import CalendarDayNewReservationModal from "./NewReservation/CalendarDayNewReservationModal";
import CalendarWeekNewShiftModal from "./Week/CalendarWeekNewShiftModal";
import YearSelectModal from "./Modals/YearSelectModal";
import { useUser } from "../../contexts/UserContext";
import SimpleSelect from "../../components/InputFieldsSimple/SimpleSelect";
import SimpleCheckBox from "../../components/InputFieldsSimple/SimpleCheckBox";
import DayDataController from "./Day/NewView/DayDataController";
import {
  defaultCalendarEndTime,
  defaultCalendarStartTime,
  momentDateFormat,
  momentTimeFormat,
  navigationPeriodInDays,
} from "./calendarConfig";
import MonthCalendarNavigation from "./Day/MonthCalendarNavigation";
import { TodayShift } from "../../models/calendar/TodayShift";
import { useClinic } from "../../contexts/ClinicContext";
import { ChevronRight } from "../../common/Icons/ChevronRight";
import { ChevronLeft } from "../../common/Icons/ChevronLeft";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import ClickOutsideDetector from "../../components/ClickOutsideDetector";

export enum ViewTypes {
  DAY = "DAY",
  DOCTOR_DAY = "DOCTOR_DAY",
  WEEK = "WEEK",
  YEAR = "YEAR",
  LISTVIEW = "LISTVIEW",
  SHIFTLISTVIEW = "SHIFTLISTVIEW",
}

const MainCalendar: React.FC = () => {
  const { clinic } = useClinic();
  const { user } = useUser();

  const [selectedDay, setSelectedDay] = useState(
    sessionStorage.getItem(calendarSelectedDayKey) ||
      moment().format(momentDateFormat)
  );
  const [weekEnd, setWeekEnd] = useState<boolean>(false);
  /* interval and viewType are not the same */
  const [interval, setInterval] = useState<ViewTypes>();
  const [viewType, setViewType] = useState<ViewTypes>();
  const [modalYearSelectIsOpen, setModalYearSelectIsOpen] =
    useState<boolean>(false);
  const [calendarStartTime, setCalendarStartTime] = useState<moment.Moment>(
    moment(defaultCalendarStartTime, momentTimeFormat)
  );
  const [calendarEndTime, setCalendarEndTime] = useState<moment.Moment>(
    moment(defaultCalendarEndTime, momentTimeFormat)
  );
  const [reload, setReload] = useState<boolean>(false);

  const [realFirstDay, setRealFirstDay] = useState<string>(moment().format());
  const [realLastDay, setRealLastDay] = useState<moment.Moment>(moment());
  const [modalNewReservationIsOpen, setModalNewReservationIsOpen] =
    useState<boolean>(false);
  const [modalNewShiftIsOpen, setModalNewShiftIsOpen] =
    useState<boolean>(false);
  const [selectedTimeAndDate, setSelectedTimeAndDate] = useState<Date>(
    new Date()
  );
  const [selectedCollaborator, setSelectedCollaborator] =
    useState<TodayShift>();
  const [isEmergency, setIsEmergency] = useState(false);
  const now = moment();
  const [refreshReservationInProgress, setRefreshReservationInProgress] =
    useState<boolean>(false);
  const [menuDropOpen, setMenuDropOpen] = useState<boolean>(false);

  const openNewReservationModal = () => {
    setSelectedTimeAndDate(new Date());
    setModalNewReservationIsOpen(true);
  };

  const closeNewReservationModal = () => {
    if (isEmergency) {
      setIsEmergency(false);
    }
    setModalNewReservationIsOpen(false);
  };

  const openNewShiftModal = () => {
    setModalNewShiftIsOpen(true);
  };

  const closeNewShiftModal = () => {
    setModalNewShiftIsOpen(false);
  };

  const next = (where: string, days: number): void => {
    let nextDate = moment(new Date()).format(momentDateFormat);

    if (where === "left") {
      nextDate = moment(selectedDay)
        .weekday(0)
        .subtract(days, "d")
        .format(momentDateFormat);
    }

    if (where === "right") {
      nextDate = moment(selectedDay)
        .weekday(0)
        .add(days, "d")
        .format(momentDateFormat);
    }
    setSelectedDay(nextDate);
  };

  useEffect(() => {
    /* First week is the week of today */
    if(!moment(selectedDay).isBetween(realFirstDay, realLastDay, "days", "(]")){
      const firstDay = moment(selectedDay).weekday(0);
      setRealFirstDay(firstDay.format());
      setRealLastDay(firstDay.add(navigationPeriodInDays - 1, "days"));
    } 
  }, [selectedDay]);

  useEffect(() => {
    if (clinic?.calendarDayStartTime) {
      setCalendarStartTime(moment(clinic.calendarDayStartTime, "hh:mm"));
    }
    if (clinic?.calendarDayEndTime) {
      setCalendarEndTime(moment(clinic.calendarDayEndTime, "hh:mm"));
    }

    const isUserVet =
      clinic?.vets?.find((i) => i.userId === user?.userId) !== undefined;
    const defaultViewType = isUserVet ? ViewTypes.DOCTOR_DAY : ViewTypes.DAY;
    const savedViewType = sessionStorage.getItem(
      calendarSelectedViewKey
    ) as ViewTypes;
    setViewType(savedViewType || defaultViewType);
    setInterval(savedViewType || defaultViewType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  useEffect(() => {
    sessionStorage.setItem(calendarSelectedDayKey, selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    if (viewType) {
      sessionStorage.setItem(calendarSelectedViewKey, viewType);
    }
  }, [viewType]);

  const onChange = (view: string) => {
    switch (view) {
      case ViewTypes.DAY:
        setInterval(ViewTypes.DAY);
        setViewType(ViewTypes.DAY);
        break;
      case ViewTypes.DOCTOR_DAY:
        setInterval(ViewTypes.DOCTOR_DAY);
        setViewType(ViewTypes.DOCTOR_DAY);
        break;
      case ViewTypes.WEEK:
        setInterval(ViewTypes.WEEK);
        setViewType(ViewTypes.WEEK);
        break;
      case ViewTypes.SHIFTLISTVIEW:
        setInterval(ViewTypes.SHIFTLISTVIEW);
        setViewType(ViewTypes.SHIFTLISTVIEW);
        break;
      case ViewTypes.YEAR:
        setInterval(ViewTypes.YEAR);
        setModalYearSelectIsOpen(true);
        break;
      default:
        setInterval(ViewTypes.LISTVIEW);
        setViewType(ViewTypes.LISTVIEW);
        break;
    }
  };

  return (
    <main className="main-signed-in-appointments">
      <section>
        <div className="relative z-10 border-b w-full flex flex-col space-y-6 bg-white dark:bg-gray-800 dark:border-b dark:border-gray-700 px-4 lg:px-6 pt-4 lg:pt-6 lg:pb-4 lg:pb-4">
          <div className="flex flex-wrap lg:flex-nowrap lg:grid lg:grid-cols-3 justify-between items-center">
            <div className="flex items-center lg:space-x-4 justify-start">
              <div className="hidden lg:flex">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedDay(moment(new Date()).format(momentDateFormat));
                    setSelectedDay(
                      moment(new Date()).format(momentDateFormat)
                    );
                  }}
                >
                  <span className="tw-link-tercier">{strings.today}</span>
                </button>
              </div>
              <div className="flex lg:hidden space-x-4">
                <button
                  type="button"
                  className="tw-icon tw-link"
                  onClick={() => {
                    setSelectedDay((prev) =>
                      moment(prev).subtract(1, "day").format(momentDateFormat)
                    );
                  }}
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  className="tw-icon tw-link"
                  onClick={() => {
                    setSelectedDay((prev) =>
                      moment(prev).add(1, "day").format(momentDateFormat)
                    );
                  }}
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
              <div className="hidden lg:flex space-x-4">
                <button
                  type="button"
                  className="tw-icon tw-link"
                  onClick={() => {
                    next(
                      "left",
                      moment(selectedDay).diff(
                        moment(selectedDay).subtract(
                          navigationPeriodInDays,
                          "days"
                        ),
                        "days"
                      )
                    );
                  }}
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  className="tw-icon tw-link"
                  onClick={() => {
                    next(
                      "right",
                      0 -
                        moment(selectedDay).diff(
                          moment(selectedDay).add(
                            navigationPeriodInDays,
                            "days"
                          ),
                          "days"
                        )
                    );
                  }}
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
              <div className="hidden lg:flex space-x-2">
                <span className="text-lg font-normal text-zinc-800 dark:text-gray-300">
                  {moment(realFirstDay).format("LL")}
                </span>
                <span>-</span>
                <span className="text-lg font-normal text-zinc-800 dark:text-gray-300">
                  {moment(realLastDay).format("LL")}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-start lg:justify-center">
              <h1 className="hidden lg:flex text-sm font-medium leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {moment(selectedDay).format("LL")}
                {` (${moment(selectedDay).week()}. ${strings.week})`}
              </h1>
              <h1 className="lg:hidden text-xl font-medium leading-tight text-zinc-800 dark:text-white">
                {moment(selectedDay).format("L")}
              </h1>
            </div>
            <div className="flex items-center space-x-4 justify-end">
              <div className="hidden lg:flex">
                <SimpleCheckBox
                  label={strings.weekend}
                  name="weekend"
                  onChange={(checked: boolean) => {
                    setWeekEnd(checked);
                  }}
                />
              </div>
              <div className="flex">
                <div>
                  <SimpleSelect
                    name="view"
                    isChooseOption={false}
                    labelOff
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onChange(e.target.value);
                    }}
                    options={[
                      {
                        title: `${strings.Day} (${strings.reservations})\xa0\xa0\xa0\xa0\xa0\xa0`,
                        value: ViewTypes.DAY,
                      },
                      {
                        title: `${strings.listView} (${strings.reservations})\xa0\xa0\xa0\xa0\xa0\xa0`,
                        value: ViewTypes.LISTVIEW,
                      },
                      {
                        title: `${strings.doctorView} (${strings.medicalRecords})\xa0\xa0\xa0\xa0\xa0\xa0`,
                        value: ViewTypes.DOCTOR_DAY,
                      },
                      {
                        title: `${strings.Week} (${strings.shifts})`,
                        value: ViewTypes.WEEK,
                      },
                      {
                        title: `${strings.listView} (${strings.shifts})`,
                        value: ViewTypes.SHIFTLISTVIEW,
                      },
                      {
                        title: strings.Year,
                        value: ViewTypes.YEAR,
                      },
                    ]}
                    value={interval}
                  />
                </div>
              </div>
              <div>
                <ClickOutsideDetector
                  listen
                  onClickOutside={() => {
                    setMenuDropOpen(false);
                  }}
                >
                  <button
                    type="button"
                    className="tw-btn-circle-primary rounded"
                    onClick={() => {
                      setMenuDropOpen((prev) => !prev);
                    }}
                  >
                    <PlusIcon />
                    <span className="sr-only">Plus</span>
                  </button>
                  <div
                    hidden={!menuDropOpen}
                    className="z-30 shadow-md rounded-lg w-46 font-normal bg-white divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 absolute top-0 right-0 m-0"
                    style={{ transform: "translate(-24px, 75px)" }}
                  >
                    <ul className="py-2 text-md text-gray-700 dark:text-gray-400">
                      <li>
                        <button
                          className="tw-link flex justify-between items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 transition-all duration-300 w-full"
                          onClick={() => {
                            openNewReservationModal();
                            setIsEmergency(true);
                          }}
                          type="button"
                        >
                          {strings.emergencyAppointment}
                        </button>
                      </li>
                      <li>
                        <button
                          className="tw-link flex justify-between items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 transition-all duration-300 w-full"
                          onClick={openNewReservationModal}
                          type="button"
                        >
                          {strings.newReservation}
                        </button>
                      </li>
                      <li>
                        <button
                          className="tw-link flex justify-between items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 transition-all duration-300 w-full"
                          onClick={openNewShiftModal}
                          type="button"
                        >
                          {strings.newShift}
                        </button>
                      </li>
                    </ul>
                  </div>
                </ClickOutsideDetector>
              </div>
            </div>
          </div>
          <div id="navigation-second-row">
            <MonthCalendarNavigation
              localSelectedDay={selectedDay}
              realFirstDay={realFirstDay}
              realLastDay={realLastDay}
              setLocalSelectedDay={(newDate: string) => {
                setSelectedDay(newDate);
              }}
              viewType={viewType}
            />
          </div>
        </div>
      </section>
      <section>
        <div>
          {viewType === ViewTypes.WEEK ||
          viewType === ViewTypes.SHIFTLISTVIEW ? (
            <WeekController
              selectedDay={selectedDay}
              calendarStartTime={calendarStartTime}
              calendarEndTime={calendarEndTime}
              weekEnd={weekEnd}
              triggerReload={reload}
              viewType={viewType || ViewTypes.DAY}
            />
          ) : (
            <DayDataController
              selectedDay={selectedDay}
              calendarStartTime={calendarStartTime}
              calendarEndTime={calendarEndTime}
              handleNewReservationModal={(
                open: boolean,
                dateAndTime?: { time: string; date: string },
                selectedShift?: TodayShift
              ) => {
                setModalNewReservationIsOpen(open);
                if (dateAndTime) {
                  setSelectedTimeAndDate(
                    moment(`${dateAndTime.date} ${dateAndTime.time}`).toDate()
                  );
                }
                if (selectedShift) {
                  setSelectedCollaborator(selectedShift);
                }
              }}
              refreshReservationInProgress={refreshReservationInProgress}
              triggerReload={reload}
              viewType={viewType || ViewTypes.DAY}
            />
          )}
          <YearSelectModal
            close={() => {
              setModalYearSelectIsOpen(false);
              setInterval(viewType);
            }}
            open={modalYearSelectIsOpen}
            selectedDay={selectedDay}
            setSelectedDay={(newDate: string) => {
              setSelectedDay(newDate);
              setInterval(viewType);

              if (moment(newDate).isAfter(realLastDay)) {
                next(
                  "right",
                  0 - moment(selectedDay).diff(moment(newDate), "days")
                );
              } else if (moment(newDate).isBefore(realFirstDay)) {
                next(
                  "left",
                  moment(selectedDay).diff(moment(newDate), "days")
                );
              }
            }}
          />
          <CalendarDayNewReservationModal
            close={closeNewReservationModal}
            dateAndTime={selectedTimeAndDate}
            isEmergency={isEmergency}
            open={modalNewReservationIsOpen}
            refreshReservationInProgress={() => {
              setRefreshReservationInProgress(!refreshReservationInProgress);
            }}
            reload={() => {
              setReload(!reload);
            }}
            selectedShift={selectedCollaborator}
          />
          <CalendarWeekNewShiftModal
            open={modalNewShiftIsOpen}
            closeNewModal={closeNewShiftModal}
            selectedTimeAndDate={{
              date: now.format(momentDateFormat),
              time: now.format(momentTimeFormat),
            }}
            reload={() => {
              setReload(!reload);
            }}
          />
        </div>
      </section>
    </main>
  );
};

export default MainCalendar;
