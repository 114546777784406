/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import {
  countMediaTracksForVideo,
  displayMediaDebug,
  getMediaStreamProperties,
  isVideoLive,
  MediaFeatures,
  VideoLoadState,
} from "./Utils";
import { Speaker } from "../../common/Icons/Speaker";
import { combineClassNames } from "../../util/HtmlUtils";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import logger from "../../util/logger";
import HoverChecker from "../../util/HoverChecker";
import { useFullscreen } from "../../hooks/useFullscreen";
import { useVoiceDetector } from "./useVoiceDetector";

interface Props {
  callControls: ReactElement;
  camEnabled: boolean;
  joinRoom(): void;
  mediaFeatures: MediaFeatures;
  micEnabled: boolean;
  updateVideoRef: () => void;
  toggleCam: (setTo?: boolean) => void;
  toggleMic: (setTo?: boolean) => void;
  userId: string;
  userName: string;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
}

const TRANSPARENT_BORDER = true;

/*
 * Video component for the local user.
 */
export const LocalVideo: React.FC<Props> = ({
  callControls,
  camEnabled,
  joinRoom,
  mediaFeatures,
  micEnabled,
  updateVideoRef,
  toggleCam,
  toggleMic,
  userId,
  userName,
  videoRef,
}: Props): ReactElement => {
  const [joinedRoom, setJoinedRoom] = useState<boolean>(false);

  const micMissing =
    !mediaFeatures.hasMicrophonePermission || !mediaFeatures.hasMicrophone;
  const camMissing =
    !mediaFeatures.hasWebcamPermission || !mediaFeatures.hasWebcam;

  const [videoLoadState, setVideoLoadState] = useState<VideoLoadState>(
    "NOT_STARTED_LOADING"
  );
  const isVoiceActive = useVoiceDetector(videoRef, micEnabled, micMissing);

  const videoIsLive = isVideoLive(videoRef);
  const showVideo =
    !camMissing &&
    camEnabled &&
    videoLoadState === "LOADING_SUCCESSFUL" &&
    videoIsLive;

  const [bgColor, setBgColor] = useState<string>("gray");
  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  useEffect(() => {
    const setupVideoAndJoinRoom = async () => {
      // Assign the stream ref to the user video (from the preview video)
      updateVideoRef();

      // Initialize camera and microphone state
      toggleCam(camEnabled);
      toggleMic(micEnabled);

      // Join the videochat room
      joinRoom();
      setJoinedRoom(true);
    };

    // Guard condition to prevent double join event
    if (!joinedRoom) {
      void setupVideoAndJoinRoom();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, toggleCam, toggleMic]);

  const handleCalculateAverageColor = (color: string) => {
    setBgColor(color);
  };

  console.log(
    getMediaStreamProperties(videoRef.current?.srcObject as MediaStream)
  );

  return (
    <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
      {(_isHovered, isRecentlyHovered) => (
        <div
          className="relative w-full h-full flex justify-center items-center rounded-2xl"
          ref={containerRef}
        >
          {/* User video */}
          <video
            className={combineClassNames(
              "w-full h-full rounded-2xl object-cover",
              micEnabled && isVoiceActive
                ? "outline outline-4 -outline-offset-4 outline-blue-600 dark:outline-blue-400"
                : "",
              isFullscreen ? "fullscreen-video" : ""
            )}
            autoPlay
            controls={false}
            hidden={!showVideo}
            id="user-video"
            muted
            onLoadStart={() => setVideoLoadState("STARTED_LOADING")}
            onCanPlay={() => setVideoLoadState("LOADING_SUCCESSFUL")}
            playsInline
            ref={videoRef}
          />
          {/* User video placeholder: Profile picture and loader */}
          {!showVideo && (
            <div
              className={combineClassNames(
                "flex justify-center items-center w-full h-full border-4 rounded-xl",
                micEnabled && isVoiceActive
                  ? "border-blue-600 dark:border-blue-400"
                  : TRANSPARENT_BORDER
                  ? "border-transparent"
                  : "border-gray-400 dark:border-gray-700"
              )}
              style={{
                backgroundColor:
                  camMissing || videoLoadState === "LOADING_SUCCESSFUL"
                    ? bgColor
                    : "gray",
              }}
            >
              {!camMissing &&
              videoLoadState === "STARTED_LOADING" &&
              !videoIsLive ? (
                <LoaderInline size={SpinnerSize.VideoChat} />
              ) : (
                <div className="absolute w-28 h-28">
                  <UserProfilePicture
                    onCalculateAverageColor={handleCalculateAverageColor}
                    userId={userId}
                  />
                </div>
              )}
            </div>
          )}
          {/* User information */}
          <p className="text-white absolute top-5 left-5 bg-gray-500 bg-opacity-40 rounded-full p-2">
            {userName}
          </p>
          {debugMode &&
            displayMediaDebug({
              camEnabled,
              camMissing,
              micEnabled,
              micMissing,
              videoLoadState,
              videoIsLive,
              streamId:
                (videoRef.current?.srcObject as MediaStream)?.id || "No stream",
              audioTracks: countMediaTracksForVideo(videoRef, "audio"),
              videoTracks: countMediaTracksForVideo(videoRef, "video"),
              liveAudioTracks: countMediaTracksForVideo(
                videoRef,
                "audio",
                true
              ),
              liveVideoTracks: countMediaTracksForVideo(
                videoRef,
                "video",
                true
              ),
            })}
          {!micEnabled && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <MicrophoneOff variant="outline" />
            </div>
          )}
          {micEnabled && isVoiceActive && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <Speaker />
            </div>
          )}
          <div
            className="absolute bottom-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            onClick={toggleFullscreen}
            role="button"
            tabIndex={0}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </div>
          {isFullscreen && (
            <div
              className="absolute bottom-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              {callControls}
            </div>
          )}
        </div>
      )}
    </HoverChecker>
  );
};
