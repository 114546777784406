/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useEffect, useState } from "react";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../common/Strings/Strings";
import { TreatmentResponse } from "../../models/treatment/TreatmentResponse";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import NumberFormatter from "../../util/NumberFormatter";
import TreatmentCostCalculator from "../../util/TreatmentCostCalculator";
import TreatmentDetails from "./TreatmentDetails";
import Button from "../../components/Button";
import Collapse from "../../components/Collapse";
import { ChevronDown } from "../../common/Icons/ChevronDown";
import { ChevronUp } from "../../common/Icons/ChevronUp";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  onRemove?: (treatmentId: string) => void;
  treatments: TreatmentResponse[];
  canEdit: boolean;
}

export default function TreatmentList({
  clinicId,
  treatments,
  countryDetails,
  onRemove,
  canEdit,
}: Props): ReactElement {
  const [isCollapseOpenList, setIsCollapseOpenList] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const collapseList: { [key: string]: boolean } = {};
    treatments.forEach((tr) => {
      collapseList[tr.id] = false;
    });
    setIsCollapseOpenList(collapseList);
  }, [treatments]);

  return (
    <div className="tw-table-container">
      <table className="tw-table">
        <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
          <tr>
            <th />
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.treatmentName}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.basePrice}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.vat}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.accountingCode}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.usedItems}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.fees}
            </th>
            <th className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.sum}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
          {treatments?.map((treatment, index) => (
            <Fragment key={index}>
              <tr
                key={treatment.id}
                onClick={() =>
                  setIsCollapseOpenList({
                    ...isCollapseOpenList,
                    [treatment.id]: !isCollapseOpenList[treatment.id],
                  })
                }
              >
                <td className="pl-3 py-2 text-sm font-medium whitespace-nowrap text-left">
                  <Button
                    onClick={() =>
                      setIsCollapseOpenList({
                        ...isCollapseOpenList,
                        [treatment.id]: !isCollapseOpenList[treatment.id],
                      })
                    }
                    variant="basic"
                  >
                    {isCollapseOpenList[treatment.id] ? (
                      <ChevronUp className="h-5 w-5" />
                    ) : (
                      <ChevronDown className="h-5 w-5" />
                    )}
                  </Button>
                </td>
                <td className="px-2 py-2 text-sm">
                  <div className="font-bold">{treatment.name}</div>
                  <div className="font-medium">
                    {`${
                      treatment.species.length > 0
                        ? `(${getStringFromEnumsOrReturn(treatment.species)})`
                        : ""
                    }`}
                  </div>
                </td>
                <td className="px-2 py-2 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossPriceOfWorkCost(treatment, countryDetails?.roundingDecimalLength),
                    treatment.currency
                  )}
                  <div className="text-xs text-gray-500">
                    {NumberFormatter.formatPrice(
                      TreatmentCostCalculator.getNetPriceOfWorkCost(treatment),
                      treatment.currency
                    )}{" "}
                    {strings.plusVat}
                  </div>
                </td>
                <td className="px-2 py-2 text-sm font-medium whitespace-nowrap">
                  {treatment.vat} {treatment.vat ? "%" : ""}
                </td>
                <td className="px-2 py-2 text-sm font-medium whitespace-nowrap">
                  {treatment.accountingCode}
                </td>
                <td className="px-2 py-2 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossPriceOfItems(treatment, countryDetails?.roundingDecimalLength),
                    treatment.currency
                  )}
                  <div className="text-xs text-gray-500">
                    {NumberFormatter.formatPrice(
                      TreatmentCostCalculator.getNetPriceOfItems(treatment),
                      treatment.currency
                    )}{" "}
                    {strings.plusVat}
                  </div>
                </td>
                <td className="px-2 py-2 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossPriceOfFees(treatment, countryDetails?.roundingDecimalLength),
                    treatment.currency
                  )}
                  <div className="text-xs text-gray-500">
                    {NumberFormatter.formatPrice(
                      TreatmentCostCalculator.getNetPriceOfFees(treatment),
                      treatment.currency
                    )}{" "}
                    {strings.plusVat}
                  </div>
                </td>
                <td className="pl-2 pr-4 py-2 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossTotalPrice(treatment, countryDetails?.roundingDecimalLength),
                    treatment.currency
                  )}
                  <div className="text-xs text-gray-500">
                    {NumberFormatter.formatPrice(
                      TreatmentCostCalculator.getNetTotalPrice(treatment),
                      treatment.currency
                    )}
                    {} {strings.plusVat}
                  </div>
                </td>
              </tr>
              <tr className="border-0" key={treatment.id + 1000}>
                <td className="p-0" />
                <td className="pl-2 pr-4" colSpan={7}>
                  <div>
                    <Collapse in={isCollapseOpenList[treatment.id]}>
                      <div className="mb-3">
                        <TreatmentDetails
                          clinicId={clinicId}
                          treatment={treatment}
                          onRemove={() => {
                            setIsCollapseOpenList({
                              ...isCollapseOpenList,
                              [treatment.id]: !isCollapseOpenList[treatment.id],
                            });
                            if (onRemove) {
                              onRemove(treatment.id);
                            }
                          }}
                          countryDetails={countryDetails}
                          canEdit={canEdit}
                        />
                      </div>
                    </Collapse>
                  </div>
                </td>
                <td className="p-0" />
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TreatmentList.defaultProps = {
  clinicId: undefined,
  countryDetails: undefined,
  onRemove: undefined,
};
