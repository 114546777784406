/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AccountingCodeResponse } from "../../../../models/accounting/AccountingCodeResponse";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { strings } from "../../../../common/Strings/Strings";
import { getVatOptions } from "../../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import { InvoiceItemRequest } from "../../../../models/invoice/InvoiceItemRequest";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import PriceInputs from "../../../../components/ReactHookFormFields/Specific/PriceInputs";
import CombinedSelect from "../../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../../components/Button";

export interface NewCostForm {
  name: string;
  vat: number;
  price: number;
  accountingCode: AccountingCodeResponse[];
  quantity: number;
}

interface Props {
  countryDetails?: CountryDetailsResponse;
  submit(form: InvoiceItemRequest): void;
}

const NewCustomCost = ({ submit, countryDetails }: Props): ReactElement => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<NewCostForm>({
    defaultValues: {
      accountingCode: [],
      name: "",
      price: 0,
      quantity: 1,
      vat: 0,
    },
  });
  const vat = watch("vat");

  const onSubmit = (form: NewCostForm) => {
    submit({
      ...form,
      accountingCode: form.accountingCode && form.accountingCode[0]?.code,
    });
    reset();
  };

  useEffect(() => {
    if(countryDetails){
      setValue("vat", countryDetails.vatRates[0]);
    }
  }, [countryDetails]);

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.name}
        name="name"
        register={register}
        label={strings.name}
        required
      />
      <CombinedSelect
        allowNew={false}
        control={control}
        name="accountingCode"
        options={countryDetails?.accountingCodes || []}
        labelKey="name"
        label={strings.accountingCode}
        optional
      />
      <Select
        error={errors.vat}
        isChooseOption={false}
        name="vat"
        options={getVatOptions(countryDetails?.vatRates)}
        register={register}
        fieldOptions={{ valueAsNumber: true }}
        label={strings.vatAsTax}
        required
        width="80px"
      />
      <PriceInputs
        control={control}
        name="price"
        label={strings.price}
        currency={countryDetails?.currency}
        vat={vat}
        required
        roundingDecimalLength={countryDetails?.roundingDecimalLength}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.quantity}
        name="quantity"
        register={register}
        label={strings.quantity}
        type="number"
        required
        step="any"
        width="80px"
      />
      <Button type="submit">{strings.add}</Button>
    </form>
  );
};

export default NewCustomCost;
