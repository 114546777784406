/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import qs from "query-string";
import { InvoiceStatus } from "../invoice/InvoiceStatus";
import { DateTemplate } from "../../pages/Accounting/InvoiceDateFilters";
import { CostAccountingStatus } from "../costaccounting/CostAccountingStatus";
import { SearchCategory } from "../../pages/Accounting/InvoiceFilters";
import { localDateFormat } from "../../util/helperFunctions";

export interface SearchInvoices {
  dateOf?: "payment" | "issue" | string;
  dateTemplate: DateTemplate;
  customer?: string | null;
  endDate?: Date | null;
  invoiceNumber?: string | null;
  invoiceStatus?: Array<InvoiceStatus>;
  paymentStatus?: Array<CostAccountingStatus>;
  pageNumber?: number;
  pageSize?: number;
  searchCategory: SearchCategory;
  startDate?: Date | null;
  totalCost?: number | null;
}

export const generateInvoiceSearchURL = (
  baseURL: string,
  {
    customer,
    dateOf,
    endDate,
    invoiceNumber,
    invoiceStatus,
    paymentStatus,
    pageSize,
    pageNumber,
    searchCategory,
    startDate,
    totalCost,
  }: SearchInvoices
): string => {
  let url = baseURL;

  if (!!customer && customer.length > 1 && searchCategory === "customer") {
    url += `customer=${customer}&`;
  }
  if (dateOf !== null) {
    url += `dateOf=${dateOf}&`;
  }
  if (endDate !== null) {
    url += `endDate=${moment(endDate).endOf("day").format(localDateFormat())}&`;
  }
  if (!!invoiceNumber && searchCategory === "invoiceNumber") {
    url += `invoiceNumber=${invoiceNumber}&`;
  }
  if (!!invoiceStatus) {
    url += `${qs.stringify(
      { invoiceStatus: [invoiceStatus] },
      { arrayFormat: "comma" }
    )}&`;
  }
  if (paymentStatus && !invoiceStatus?.includes(InvoiceStatus.IN_PROGRESS)) {
    url += `${qs.stringify(
      { paymentStatus: paymentStatus },
      { arrayFormat: "comma" }
    )}&`;
  }
  if (pageSize) {
    url += `pageSize=${pageSize}&`;
  }
  if (pageNumber) {
    url += `pageNumber=${pageNumber}&`;
  }
  if (startDate !== null) {
    url += `startDate=${moment(startDate)
      .startOf("day")
      .format(localDateFormat())}&`;
  }
  if (!!totalCost && searchCategory === "totalCost") {
    url += `totalCost=${totalCost}&`;
  }

  return url.slice(0, -1);
};
