/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { InventoryItemResponse } from "../../../models/inventory/item/InventoryItemResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import { TreatmentItemResponse } from "../../../models/treatment/TreatmentItemResponse";
import { TreatmentItemRequest } from "../../../models/treatment/TreatmentItemRequest";
import LoaderInline from "../../../components/LoaderInline";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import TreatmentApi from "../../../api/TreatmentApi";
import { UpdateTreatmentRequest } from "../../../models/treatment/UpdateTreatmentRequest";
import SearchInput from "../../../components/InputFieldsSimple/SearchInput";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Button from "../../../components/Button";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import Paging from "../../../components/Paging";
import { useDebouncedState } from "../../../hooks/hooks";
import EmptyListText from "../../../components/EmptyListText";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";

interface Props {
  clinicId?: string;
  isOpen: boolean;
  close(): void;
  item?: InventoryItemResponse;
}

const AddTreatmentItem: React.FC<{
  addItem: (quantity: number) => void;
  disabled: boolean;
  item?: InventoryItemResponse;
}> = ({ addItem, disabled, item }) => {
  const [quantity, setQuantity] = useState<number>(1);
  return (
    <div className="ml-auto pr-2 flex space-x-2 items-end">
      <SimpleInput
        className="mb-0"
        name="quantityForItem"
        labelOff
        onChange={(v) => {
          setQuantity(v ? Number(v) : 0);
        }}
        placeholder={strings.quantity}
        suffix={quantityUnits[item?.acquisitionUnit || ""]}
        value={quantity}
        width="80px"
      />
      <div>
        <Button
          className="h-full px-2"
          variant="primary"
          disabled={disabled}
          onClick={() => {
            void addItem(quantity);
          }}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};

const CopyItemToTreatmentModal: React.FC<Props> = ({
  clinicId,
  item,
  isOpen,
  close,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [treatments, setTreatments] = useState<TreatmentResponse[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const inputFilter = useDebouncedState<string>("", 600);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const getTreatments = async () => {
      if (clinicId) {
        try {
          const response = await TreatmentApi.getTreatmentsOfClinic(clinicId, {
            name: inputFilter.value,
            pageNumber,
            pageSize: 5,
          });
          setTreatments(response.data.elements);
          setTotalPages(response.data.totalPages);
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setLoaded(true);
        }
      }
    };

    setLoaded(false);
    void getTreatments();
  }, [clinicId, pageNumber, inputFilter.value]);

  useEffect(() => {
    setPageNumber(0);
  }, [inputFilter.value]);

  const addItem = async (treatment: TreatmentResponse, quantity: number) => {
    if (!item) {
      return;
    }
    setSuccess(false);
    setError(null);
    setButtonDisabled(true);

    const putItems: TreatmentItemRequest[] = [];

    treatment.usedItems.forEach((treatmentItem) => {
      putItems.push({
        itemId: treatmentItem.item.id,
        quantity: treatmentItem.quantity,
      });
    });

    putItems.push({
      itemId: item.id,
      quantity,
    });

    const request: UpdateTreatmentRequest = {
      ...treatment,
      items: putItems,
      fees: undefined,
    };

    if (clinicId) {
      try {
        await TreatmentApi.updateTreatment(clinicId, treatment.id, request);
        setSuccess(true);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setButtonDisabled(false);
      }
    }
  };

  const listTreatmentItems = (
    treatmentItems: TreatmentItemResponse[]
  ): string[] => {
    const sentence: string[] = [];

    treatmentItems.map((i: TreatmentItemResponse) => {
      sentence.push(i.item.name);
      sentence.push(", ");
      return sentence;
    });

    sentence.pop();

    return sentence;
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
    close();
  };

  return (
    <Modal handleClose={handleClose} show={isOpen}>
      <Modal.Header title={`${strings.addToTreatment} (${item?.name})`} />
      <Modal.Body>
        <div className="relative w-full">
          <label>{strings.treatments}</label>
          <div className="absolute right-0 top-0">
            <Link to={`/treatments`} className="tw-link text-sm">
              {strings.createNewTreatment}
            </Link>
          </div>
          <div className="relative w-full">
            <SearchInput
              autoComplete={AutoCompleteOptions.off}
              enableCreateButton={false}
              loading={false}
              onChange={(e) => inputFilter.setValue(e)}
              placeholder={strings.searchWithDots}
            />
          </div>
        </div>
        <AlertBox message={error} />
        <AlertBox
          hidden={!success}
          message={strings.addTreatmentSuccess}
          type={AlertType.SUCCESS}
        />
        {!loaded ? (
          <div className="tw-card p-3">
            <LoaderInline className="my-3" />
          </div>
        ) : (
          <>
            {treatments.length === 0 ? (
              <div className="tw-card p-3">
                <EmptyListText />
              </div>
            ) : (
              <div className="divide-y">
                {treatments.map((treatment) => (
                  <div className="flex items-center py-3" key={treatment.id}>
                    <div>
                      <p className="font-bold">{treatment.name}</p>
                      <div className="text-sm">
                        {listTreatmentItems(treatment.usedItems)}
                      </div>
                    </div>
                    <AddTreatmentItem
                      addItem={(q) => {
                        void addItem(treatment, q);
                      }}
                      disabled={buttonDisabled}
                      item={item}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <Paging
          currentPage={pageNumber}
          totalPages={totalPages}
          selectPage={setPageNumber}
        />
      </Modal.Body>
      <Modal.Footer extraClassName="justify-end">
        <CloseButton asMain onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default CopyItemToTreatmentModal;
