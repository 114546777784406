/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import UserProfilePictureSettings from "../../../../../components/Pictures/User/UserProfilePictureSettings";
import GeneralUserInfoForm, {
  GeneralUserForm,
} from "../../../../PetOwner/ClinicPetOwner/CreatePetOwner/GeneralUserInfoForm";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { strings } from "../../../../../common/Strings/Strings";
import { useUser } from "../../../../../contexts/UserContext";
import { ApiResponseMessage } from "../../../../../models/ApiResponseMessage";
import { UpdateUserRequest } from "../../../../../models/user/UpdateUserRequest";
import UserApi from "../../../../../api/UserApi";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { Card } from "../../../../../components/Cards/Card";
import Button from "../../../../../components/Button";
import UserDeletionModal from "./UserDeletionModal";
import { doLogout } from "../../../../../util/axiosSetup";
import { useClinic } from "../../../../../contexts/ClinicContext";

interface UpdateProgress {
  data: boolean;
  password: boolean;
  locale: boolean;
  notification: boolean;
}

const BasicInfoTab: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { clearClinic } = useClinic();

  const [inProgress, setInProgress] = useState<UpdateProgress>({
    data: false,
    password: false,
    locale: false,
    notification: false,
  });

  const [updateResponse, setUpdateResponse] =
    useState<ApiResponseMessage | null>(null);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const logout = () => {
    void doLogout(true);
    clearClinic();
    navigate("/login");
  };

  const petOwnerFormMethods = useForm<GeneralUserForm>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = petOwnerFormMethods;

  useEffect(() => {
    const fillUserForm = () => {
      if (user) {
        const {
          dateOfBirth,
          firstName,
          lastName,
          middleName,
          personalId,
          prefix,
        } = user.details;
        reset({
          ownerDateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
          firstName,
          lastName,
          middleName,
          personalId,
          prefix,
        });
      }
    };

    fillUserForm();
  }, [reset, user]);

  const patchProfile = async ({
    firstName,
    lastName,
    middleName,
    ownerDateOfBirth,
    personalId,
    prefix,
  }: GeneralUserForm) => {
    setUpdateResponse(null);

    setInProgress({ ...inProgress, data: true });

    const request: UpdateUserRequest = {
      dateOfBirth: ownerDateOfBirth ? moment(ownerDateOfBirth).format("YYYY-MM-DD") : undefined,
      firstName,
      lastName,
      locale: user.locale,
      middleName: middleName?.length === 0 ? undefined : middleName,
      personalId,
      prefix: prefix?.length === 0 ? undefined : prefix,
      receiveSMSNotifications: user.receiveSMSNotifications,
    };

    try {
      const response = await UserApi.updateUser(request);
      setUser(response.data);
      setUpdateResponse({
        message: strings.savedSuccessfully,
        type: AlertType.SUCCESS,
      });
    } catch (e) {
      setUpdateResponse({
        message: await getGeneralError(e),
        type: AlertType.ERROR,
      });
    } finally {
      setInProgress({ ...inProgress, data: false });
    }
  };

  return (
    <>
      <Card size="lg" type="simple" title={strings.basicInfo}>
        <UserProfilePictureSettings />
        <FormProvider {...petOwnerFormMethods}>
          <form className="space-y-4" onSubmit={handleSubmit(patchProfile)}>
            <GeneralUserInfoForm
              primaryAddressCountryCode={user?.primaryAddress?.countryCode}
            />
            <AlertBox
              message={updateResponse?.message}
              type={updateResponse?.type}
            />

            <Button
              disabled={!isDirty}
              loading={inProgress.data}
              type="submit"
            >
              {strings.save}
            </Button>
          </form>
        </FormProvider>
        <Button
          className="text-red-500"
          variant="link"
          type="button"
          onClick={() => setDeleteModalOpen(true)}
        >
          {strings.deleteProfile}
        </Button>
      </Card>
      <UserDeletionModal
        open={isDeleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        logout={logout}
      />
    </>
  );
};

export default BasicInfoTab;
