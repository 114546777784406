/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { useForm } from "react-hook-form";
import { DataConnection, MediaConnection, Peer } from "peerjs";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  CallInfo,
  callUser,
  checkMediaFeatures,
  copyAddressToClipboard,
  createMediaFeaturesTable,
  createPeer,
  formatMediaConfig,
  getMicrophoneErrorString,
  getSectionHeight,
  getWebcamErrorString,
  isVideoLive,
  LocalConfig,
  MediaConfig,
  MediaErrors,
  MediaFeatures,
  playSoundEffect,
  recalculateLayout,
  setupUserVideo,
  SoundEffect,
  stopMediaTracksForVideo,
  timeToString,
  toggleMediaTrack,
  usersToNameArray,
  VideoChatUserConnection,
  VideoLayout,
} from "./Utils";
import { RemoteVideo } from "./RemoteVideo";
import Button from "../../components/Button";
import { strings } from "../../common/Strings/Strings";
import { VideoCamera } from "../../common/Icons/VideoCamera";
import { VideoCameraOff } from "../../common/Icons/VideoCameraOff";
import logger from "../../util/logger";
import { useUser } from "../../contexts/UserContext";
import { Card } from "../../components/Cards/Card";
import { callEndIcon, videoConsultationIcon } from "../../assets/AssetConfig";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Switch from "../../components/ReactHookFormFields/General/Switch";
import { Microphone } from "../../common/Icons/Microphone";
import { LocalVideoPreview } from "./LocalVideoPreview";
import { LocalVideo } from "./LocalVideo";
import Params from "../../common/Params";
import VideoConsultationApi from "../../api/VideoConsultationApi";
import { VideoChatUser } from "../../models/videoConsultation/RoomUsersResponse";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import HorizontalLine from "../../components/HorizontalLine";
import LoaderInline from "../../components/LoaderInline";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import Tooltip from "../../components/Tooltip";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import CallControls from "./CallControls";
import PermissionRequestModal from "./PermissionRequestModal";
import { getGeneralError } from "../../util/helperFunctions";
import { repeatElement } from "../../util/HtmlUtils";
import { TestVideo } from "./TestVideo";
import MediaErrorModal from "./MediaErrorModal";

interface VideoConsultationPreparationForm {
  camEnabled: boolean;
  micEnabled: boolean;
}

// Debug flags (set these to true if needed during development)
const SIMULATE_MISSING_CAMERA = false; // If the camera detection can't be directly removed from the browser
const SIMULATE_MISSING_MICROPHONE = false; // If the microphone detection can't be directly removed from the browser

const VideoConsultation: React.FC = () => {
  const { user: localUser } = useUser();

  const [debugMode] = useLocalStorage<boolean>("debugMode");
  const [testVideoCount, setTestVideoCount] = useState<number>(0);

  // Call state
  const { roomId } = useParams<{ roomId: string }>();

  const startTime = useRef<number>();

  // Peer for local user
  const peerRef = useRef<Peer>();

  // Remote user connections
  const [remoteUsers, setRemoteUsers] = useState<VideoChatUserConnection[]>([]);
  const remoteUsersRef = useRef<VideoChatUserConnection[]>([]);

  const [usersInRoom, setUsersInRoom] = useState<VideoChatUser[]>([]);
  const [usersFetched, setUsersFetched] = useState<boolean>(false);

  const socketRef = useRef<Socket>();
  const streamRef = useRef<MediaStream | null>(null);

  // Ref for either the user view preview (before call started) or the user video (after call started)
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // UI state
  const [inviteMessageVisible, setInviteMessageVisible] = useState(false);

  const [callStarted, setCallStarted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callTime, setCallTime] = useState(0);

  const timerRef: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const [error, setError] = useState<string | null>(null);

  // Media devices
  const [micEnabled, setMicEnabled] = useLocalStorage<boolean>("micEnabled");
  const micEnabledRef = useRef<boolean>(true);

  const [camEnabled, setCamEnabled] = useLocalStorage<boolean>("camEnabled");
  const camEnabledRef = useRef<boolean>(true);

  const [mediaFeatures, setMediaFeatures] = useState<MediaFeatures>({
    hasMicrophone: false,
    hasMicrophonePermission: false,
    hasWebcam: false,
    hasWebcamPermission: false,
  });

  const [mediaErrors, setMediaErrors] = useState<MediaErrors>({
    audio: false,
    video: false,
  });

  const [showMediaErrorModal, setShowMediaErrorModal] =
    useState<boolean>(false);

  const [micMissing, setMicMissing] = useLocalStorage<boolean>("micMissing");
  const [camMissing, setCamMissing] = useLocalStorage<boolean>("camMissing");

  useEffect(() => {
    setMicMissing(
      !mediaFeatures.hasMicrophone || !mediaFeatures.hasMicrophonePermission
    );
    setCamMissing(
      !mediaFeatures.hasWebcam || !mediaFeatures.hasWebcamPermission
    );
  }, [mediaFeatures, setCamMissing, setMicMissing]);

  const [allowCallStart, setAllowCallStart] = useState<boolean>(false);
  const [showPermissionRequestModal, setShowPermissionRequestModal] =
    useState<boolean>(false);

  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);

  const [layout, setLayout] = useState<VideoLayout>({
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
  });

  const { control } = useForm<VideoConsultationPreparationForm>({
    mode: "onChange",
  });

  const getLocalUserConfig = useCallback((): VideoChatUser => {
    if (!socketRef.current?.id) {
      throw new Error("[📹VideoChat] Socket is not properly initialized!");
    }

    if (!peerRef.current?.id) {
      throw new Error("[📹VideoChat] Peer is not properly initialized!");
    }

    return {
      id: localUser.userId,
      name: localUser.details.fullName,
      peerId: peerRef.current?.id,
      profilePictureId: localUser.profilePicture,
      sessionId: socketRef.current.id,
    };
  }, [localUser.details.fullName, localUser.profilePicture, localUser.userId]);

  const updateLayout = useCallback(() => {
    // The number of videos equals to the number of remotePeer videos plus the local user video
    const layout = recalculateLayout(
      containerRef,
      remoteUsers.length + (debugMode ? testVideoCount : 0) + 1
    );
    setLayout(layout);
    if (debugMode) {
      logger.info("[📹VideoChat] Layout updated!");
    }
  }, [debugMode, remoteUsers.length, testVideoCount]);

  useEffect(() => {
    updateLayout();
  }, [updateLayout]);

  useEffect(() => {
    const handleResize = () => updateLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateLayout]);

  const addOrUpdateRemoteUser = (conn: VideoChatUserConnection) => {
    // Check if there is already an existing connection with the same id
    const existingConn = remoteUsers.find((u) => u.id === conn.id);

    // If no existing connection is found, add the new connection to the list
    if (!existingConn) {
      remoteUsersRef.current.push(conn);
      remoteUsers.push(conn);
    } else {
      // Update the existing connection with new data, media, and user information if available
      if (conn.dataConnection) {
        existingConn.dataConnection = conn.dataConnection;
      }
      if (conn.user) {
        existingConn.user = conn.user;
      }
      if (conn.mediaConfig) {
        existingConn.mediaConfig = conn.mediaConfig;
      }
    }
  };

  const removeRemoteUser = (id: string) => {
    // Find the index of the user in the remoteUsers array
    const userIndex = remoteUsers.findIndex((u) => u.id === id);

    // If the user is found
    if (userIndex !== -1) {
      // Remove the user from the list and the ref list
      remoteUsers.splice(userIndex, 1);
      remoteUsersRef.current.splice(userIndex, 1);
    }
  };

  const joinRoom = useCallback(() => {
    if (!socketRef.current) {
      throw new Error(
        "[📹VideoChat] No session ID found: The socket is not connected!"
      );
    }

    // Join a video chat room
    socketRef.current.emit("joinRoom", roomId, getLocalUserConfig());

    logger.info(
      `[📹VideoChat] Initiating join request to video consultation room with ID: ${roomId}.`
    );
  }, [getLocalUserConfig, roomId]);

  const disconnectFromRoom = (closeSocket = false) => {
    if (!socketRef.current) {
      throw new Error("[📹VideoChat] Disconnect error: Socket is missing!");
    }

    if (!timerRef) {
      throw new Error("[📹VideoChat] Disconnect error: Timer is missing!");
    }

    // Stop all the media tracks
    stopMediaTracksForVideo(videoRef);

    // Remove all users
    remoteUsersRef.current = [];
    setRemoteUsers([]);

    // Clear the timer
    clearInterval(timerRef.current);

    // Close the socket manually if required
    if (closeSocket) {
      socketRef.current.close();
    }

    // End the call and play the leave sound effect
    setCallEnded(true);
    playSoundEffect(SoundEffect.LEAVE_ROOM);

    logger.info("[📹VideoChat] Disconnected from room.");
  };

  const toggleCam = useCallback(
    async (setTo?: boolean, ignoreMedia = true) => {
      // Toggle the camera locally
      if (
        !ignoreMedia &&
        mediaFeatures.hasWebcam &&
        mediaFeatures.hasWebcamPermission
      ) {
        if (camEnabledRef.current) {
          toggleMediaTrack(videoRef, "video", setTo);
        } else {
          toggleMediaTrack(videoRef, "video", setTo);
          // const stream = await setupUserVideo(videoRef, mediaFeatures, "all");
          // if (stream !== null) {
          //   streamRef.current = stream;
          // }
        }
      }

      // Set the ref and the related state
      camEnabledRef.current =
        setTo !== undefined ? setTo : !camEnabledRef.current;
      setCamEnabled(camEnabledRef.current);

      const dataToSend = JSON.stringify({
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
      });

      // Send the toggle camera event to the remote peers
      remoteUsersRef.current.forEach((user: VideoChatUserConnection) => {
        // The microphone and camera states will be set up via ref when the connection is established
        // It's not necessary send these using the message buffer (which is implemented by default in peerJS) for these
        if (user.dataConnection?.open) {
          user.dataConnection.send(dataToSend);
        }

        /*
        if (user.mediaConnection?.open && streamRef.current) {
          const videoTracks = getMediaTracks("video", streamRef.current);
          user.mediaConnection.peerConnection
            .getSenders()[0]
            .replaceTrack(videoTracks[0]);

          /*
          user.mediaConnection.peerConnection
            .getSenders()
            .forEach((sender: RTCRtpSender) => {
              if (sender.track?.kind === "video") {
                sender.replaceTrack(videoTracks[0]);
              }
            });

           */
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaFeatures.hasWebcam, mediaFeatures.hasWebcamPermission]
  );

  const toggleMic = useCallback(
    (setTo?: boolean, ignoreMedia = true) => {
      if (
        !ignoreMedia &&
        mediaFeatures.hasMicrophone &&
        mediaFeatures.hasMicrophonePermission
      ) {
        // Toggle the microphone locally
        toggleMediaTrack(videoRef, "audio", setTo);
      }

      // Set the ref and the related state
      micEnabledRef.current =
        setTo !== undefined ? setTo : !micEnabledRef.current;
      setMicEnabled(micEnabledRef.current);

      const dataToSend = JSON.stringify({
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
      });

      // Send the toggle camera event to the remote peers
      remoteUsersRef.current.forEach((user: VideoChatUserConnection) => {
        // The microphone and camera states will be set up via ref when the connection is established
        // It's not necessary send these using the message buffer (which is implemented by default in peerJS) for these
        if (user.dataConnection?.open) {
          user.dataConnection.send(dataToSend);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaFeatures.hasMicrophone, mediaFeatures.hasMicrophonePermission]
  );

  const analyzeMedia = useCallback(
    async (ignoreMedia = false): Promise<MediaFeatures> => {
      // Check the media features (mic and webcam + permissions)
      const mediaFeatures = await checkMediaFeatures({
        simulateMissingCamera: SIMULATE_MISSING_CAMERA,
        simulateMissingMicrophone: SIMULATE_MISSING_MICROPHONE,
      });
      setMediaFeatures(mediaFeatures);

      return mediaFeatures;
    },
    []
  );

  // Toggle the media states
  useEffect(() => {
    toggleCam(
      mediaFeatures.hasWebcam &&
        mediaFeatures.hasWebcamPermission &&
        !mediaErrors.video
    );
    toggleMic(
      mediaFeatures.hasMicrophone &&
        mediaFeatures.hasWebcamPermission &&
        !mediaErrors.audio
    );
  }, [
    mediaErrors.audio,
    mediaErrors.video,
    mediaFeatures.hasMicrophone,
    mediaFeatures.hasWebcam,
    mediaFeatures.hasWebcamPermission,
    toggleCam,
    toggleMic,
  ]);

  const setMediaError = (media: "audio" | "video") => {
    if (media === "audio") {
      setMediaErrors((prevState) => ({ ...prevState, audio: true }));
      toggleMic(false);
    } else {
      setMediaErrors((prevState) => ({ ...prevState, video: true }));
      toggleCam(false);
    }
  };

  // Set up the user preview video
  useEffect(() => {
    const setupPreviewVideo = async () => {
      try {
        // Analyze the available media features
        const mediaFeatures = await analyzeMedia(true);

        const stream = await setupUserVideo(
          videoRef,
          mediaFeatures,
          "all",
          setMediaError
        );

        // Analyze the available media features again to update the state
        await analyzeMedia(true);

        setAllowCallStart(true);
        setShowPermissionRequestModal(false);

        if (stream !== null) {
          streamRef.current = stream;
        }

        logger.info("[📹VideoChat] Successfully set up preview video!");
      } catch (error) {
        logger.error("[📹VideoChat] Error setting up preview video:", error);
      }
    };

    void setupPreviewVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Creates a peer object for the local user
  const initPeerConnection = () => {
    peerRef.current = createPeer();

    peerRef.current.on("open", (id) => {
      // Start the call and play a sound effect
      setCallStarted(true);
      playSoundEffect(SoundEffect.JOIN_ROOM);
      console.log(
        `[📹VideoChat] Successfully connected to the signal server with ID '${id}'.`
      );
    });
  };

  // Sets up the answer... TODO: Outsource to Util
  const setupPeerCallbacks = () => {
    const peer = peerRef.current;

    if (!peer) {
      throw new Error("[📹VideoChat] Peer is missing!");
    }

    // Answer the call and add/update the user
    peer.on("call", (call: MediaConnection) => {
      call.answer(streamRef.current ?? undefined);
      addOrUpdateRemoteUser({
        id: call.peer,
        mediaConnection: call,
      });
    });

    // Send the local user's state to the caller user after successful data connection
    peer.on("connection", (connection: DataConnection) => {
      // Create media config
      const mediaConfig: MediaConfig = {
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
        camMissing:
          !mediaFeatures.hasWebcam || !mediaFeatures.hasWebcamPermission,
        micMissing:
          !mediaFeatures.hasMicrophone ||
          !mediaFeatures.hasMicrophonePermission,
      };

      // Create the callee info
      const calleeInfo: CallInfo = {
        mediaConfig,
        user: getLocalUserConfig(),
      };

      // Send the local user's state to the caller user on successful data connection
      connection.on("open", () => {
        connection.send(JSON.stringify(calleeInfo));
      });

      // Process the remote user's state
      // The first time the callee (local user) receives data is instantly after a successful connection
      // Only handle the data once, the RemoteVideo component will handle subsequent data event (e.g. mic/cam toggle)
      connection.once("data", (data: any) => {
        const callInfo: CallInfo = JSON.parse(data.toString());

        addOrUpdateRemoteUser({
          id: connection.peer,
          dataConnection: connection,
          user: callInfo.user,
          mediaConfig: callInfo.mediaConfig,
        });

        logger.info(`[📹VideoChat] ${callInfo.user.name} has joined the room.`);
      });
    });
  };

  const initSignalServerConnection = async () => {
    // Connect to the signal server
    socketRef.current = io(Params.videoChatSignalServiceBaseURL, {
      path: `${Params.videoChatPrefix}/socket.io`, // The handshake path includes the service prefix
    });

    // Listen for the 'connect' built-in event to ensure the connection is established
    socketRef.current.on("connect", () => {
      if (!socketRef.current || !socketRef.current.id) {
        throw new Error("[📹VideoChat] Socket is not properly initialized!");
      }

      // Initiate the peer connection to the PeerJS server
      initPeerConnection();

      // Set up the callback for answering the call
      setupPeerCallbacks();

      logger.info(
        `[📹VideoChat] Client created with ID: ${socketRef.current.id}.`
      );
    });

    // Listen for errors
    socketRef.current.on("error", (error) => {
      logger.error("[📹VideoChat] Socket.IO connection error:", error);
    });

    // Listen for the 'otherUsersInRoom' event
    // This event is sent by the signal server when the local user joins a room (with possibly other users already in it)
    socketRef.current.on("otherUsersInRoom", (otherUsers: VideoChatUser[]) => {
      if (!socketRef.current || !socketRef.current.id) {
        throw new Error(
          "[📹VideoChat] No session ID found: The socket is not connected!"
        );
      }

      // Create media config for peer config
      const mediaConfig: MediaConfig = {
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
        camMissing:
          !mediaFeatures.hasWebcam || !mediaFeatures.hasWebcamPermission,
        micMissing:
          !mediaFeatures.hasMicrophone ||
          !mediaFeatures.hasMicrophonePermission,
      };

      // Create local user config
      const localUserConfig: LocalConfig = {
        mediaConfig,
        peerRef,
        streamRef,
        user: getLocalUserConfig(),
      };

      logger.info(
        `[📹VideoChat] Local media config: ${formatMediaConfig(mediaConfig)}.`
      );

      // Call the users already in the room and call them
      otherUsers.forEach((otherUser: VideoChatUser) => {
        callUser(otherUser, localUserConfig, addOrUpdateRemoteUser);
      });

      const userCount = otherUsers.length;
      if (userCount === 0) {
        logger.info(`[📹VideoChat] Joined empty room.`);
      } else if (userCount === 1) {
        logger.info(`[📹VideoChat] Joined room to user ${otherUsers[0].name}.`);
      } else {
        logger.info(
          `[📹VideoChat] Joined room with users ${usersToNameArray(
            otherUsers
          )}.`
        );
      }
    });

    // Add startTimer callback
    socketRef.current.on("startTimer", (time: number) => {
      startTime.current = time;
      timerRef.current = setInterval(() => {
        if (startTime.current) {
          setCallTime(new Date().getTime() - startTime.current);
        }
      }, 1000);
    });

    // Add userLeft callback
    socketRef.current.on("userLeft", (id: string) => {
      const userToRemove = remoteUsersRef.current.find(
        (u: VideoChatUserConnection) => u.user?.sessionId === id
      );

      const userName = userToRemove?.user?.name;

      if (userToRemove) {
        // Close connections
        userToRemove.dataConnection?.close();
        userToRemove.mediaConnection?.close();

        // Remove the user
        removeRemoteUser(userToRemove.id);
      }

      playSoundEffect(SoundEffect.LEAVE_ROOM);

      logger.info(`[📹VideoChat] ${userName} has left the room.`);
    });
  };

  const handleJoinButtonClick = async () => {
    if (allowCallStart) {
      try {
        await initSignalServerConnection();
      } catch (e) {
        logger.error(e);
        setError(await getGeneralError(e));
      }
    } else {
      setShowPermissionRequestModal(true);
    }
  };

  useEffect(
    () =>
      // Cleanup function to disconnect from the room when the component unmounts
      () => {
        if (callStarted) {
          disconnectFromRoom(true);
        }
      },
    [callStarted]
  );

  const getIcon = (callEnded: boolean) => (
    <div className="flex items-center justify-center">
      <div className="relative">
        <img
          src={videoConsultationIcon}
          className="w-28"
          alt={strings.videoConsultation}
        />
        {callEnded && (
          <div className="absolute bottom-2 -right-6">
            <img src={callEndIcon} className="w-12" alt="Allow notifications" />
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const getUsersInRoom = async () => {
      if (!roomId) return;

      try {
        const response = await VideoConsultationApi.getUsersInRoom(roomId);
        setUsersInRoom(response.data);
        setUsersFetched(true);
      } catch (e) {
        logger.error(e);
      }
    };

    // Poll every second
    const fetchUsersInterval = setInterval(getUsersInRoom, 1000);

    // Stop polling on call start
    if (callStarted) {
      clearInterval(fetchUsersInterval);
    }

    // Stop polling on component unmount
    return () => clearInterval(fetchUsersInterval);
  }, [callStarted, roomId]);

  // In debug mode run some debug functions
  useEffect(() => {
    if (!debugMode) {
      return undefined;
    }

    const intervalId = setInterval(() => {
      // countActiveVideos();
      // countEnabledVideos();
      // listMediaDevices();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [debugMode]);

  // Control the number of test videos in debug mode
  const handleKeyDownDebug: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!debugMode) {
      return;
    }

    // Add new test video
    if (e.key === "a") {
      setTestVideoCount((prevCount) => prevCount + 1);
    }

    // Delete a test video
    if (e.key === "d" && testVideoCount !== 0) {
      setTestVideoCount((prevCount) => prevCount - 1);
    }
  };

  const callControls = (
    <CallControls
      camEnabled={camEnabled}
      disconnectFromRoom={disconnectFromRoom}
      mediaFeatures={mediaFeatures}
      micEnabled={micEnabled}
      microphoneMissing={micMissing}
      toggleCam={() => {
        toggleCam(undefined, false);
      }}
      toggleMic={() => {
        toggleMic(undefined, false);
      }}
      webcamMissing={camMissing}
    />
  );

  const containerHeight = "calc(100vh - 3.5rem * 3 - 48px - 1.5rem * 3)";

  return (
    <>
      <main
        className="main-default"
        onKeyDown={(e) => handleKeyDownDebug(e)}
        tabIndex={-1}
      >
        <section
          className="p-2 relative"
          style={{
            height: callStarted && !callEnded ? getSectionHeight() : "",
          }}
        >
          {!callEnded && (
            <>
              {!callStarted && (
                <div className="flex justify-center">
                  <Card
                    title={strings.videoConsultation}
                    type="simple"
                    marginClass="mt-0 md:mt-20"
                  >
                    {getIcon(false)}

                    {debugMode &&
                      createMediaFeaturesTable(mediaFeatures, mediaErrors)}

                    <div className="space-y-3">
                      <div className="flex">
                        {micEnabled ? (
                          <Microphone className="mr-3" variant="outline" />
                        ) : (
                          <MicrophoneOff className="mr-3" variant="outline" />
                        )}
                        <div>{strings.enableMicrophone}</div>
                        {micMissing && (
                          <Tooltip
                            content={getMicrophoneErrorString(mediaFeatures)}
                            placement="top"
                          >
                            <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                          </Tooltip>
                        )}
                        {mediaErrors.audio && (
                          <Tooltip
                            content={strings.mediaErrorTooltip}
                            placement="top"
                          >
                            <div
                              onClick={() => setShowMediaErrorModal(true)}
                              role="button"
                              tabIndex={-1}
                            >
                              <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                            </div>
                          </Tooltip>
                        )}
                        <div className="ml-auto">
                          <Switch
                            control={control}
                            name="micEnabled"
                            onChange={(_checked: boolean) => {
                              toggleMic();
                            }}
                            readOnly={micMissing || mediaErrors.audio}
                            value={micEnabled}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        {camEnabled ? (
                          <VideoCamera className="mr-3" variant="outline" />
                        ) : (
                          <VideoCameraOff className="mr-3" variant="outline" />
                        )}
                        <div>{strings.enableCamera}</div>
                        {camMissing && (
                          <Tooltip
                            content={getWebcamErrorString(mediaFeatures)}
                            placement="top"
                          >
                            <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                          </Tooltip>
                        )}
                        {mediaErrors.video && (
                          <Tooltip
                            content={strings.mediaErrorTooltip}
                            placement="top"
                          >
                            <div
                              onClick={() => setShowMediaErrorModal(true)}
                              role="button"
                              tabIndex={-1}
                            >
                              <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                            </div>
                          </Tooltip>
                        )}
                        <div className="ml-auto">
                          <Switch
                            control={control}
                            name="camEnabled"
                            onChange={(_checked: boolean) => {
                              toggleCam();
                            }}
                            readOnly={camMissing || mediaErrors.video}
                            value={camEnabled}
                          />
                        </div>
                      </div>
                      {!callStarted && (
                        <LocalVideoPreview
                          show={
                            !camMissing &&
                            camEnabled &&
                            !mediaErrors.video &&
                            isVideoLive(videoRef)
                          }
                          videoRef={videoRef}
                        />
                      )}
                    </div>

                    <AlertBox message={error} />

                    <Button onClick={handleJoinButtonClick} variant="primary">
                      {strings.joinConsultation}
                    </Button>

                    <HorizontalLine />

                    {!usersFetched && <LoaderInline />}
                    <div className="space-y-3" hidden={!usersFetched}>
                      {usersInRoom.length === 0 ? (
                        <div>{strings.waitingForOtherParticipants}</div>
                      ) : (
                        <>
                          <p>{strings.usersInTheRoom}</p>
                          {usersInRoom.map((u) => (
                            <div
                              className="flex flex-row items-center"
                              key={u.id}
                            >
                              <div className="w-10 h-10 mr-3">
                                <UserProfilePicture userId={u.id} />
                              </div>
                              <p>{u.name}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <AlertBox
                      hidden={!inviteMessageVisible}
                      message={strings.meetingLinkCopiedToClipboard}
                      type={AlertType.INFO}
                    />

                    <Button
                      fullWidth
                      onClick={() => {
                        copyAddressToClipboard();
                        setInviteMessageVisible(true);
                      }}
                      variant="secondary"
                    >
                      {strings.inviteToMeeting}
                    </Button>
                  </Card>
                </div>
              )}
              {callStarted && (
                <h1 className="text-xl text-center font-semibold leading-tight lg:text-2xl dark:text-white mb-3">
                  {`${strings.globalVetVideoConsultation} | ${timeToString(
                    callTime
                  )}`}
                </h1>
              )}
              {callStarted && (
                <div
                  className="flex flex-wrap justify-center items-center content-center w-full"
                  id="videos-container"
                  style={{ height: containerHeight }}
                  ref={containerRef}
                >
                  {remoteUsers.map((remoteUser: VideoChatUserConnection) => (
                    <div
                      className="flex justify-center items-center p-1 transition-all"
                      key={remoteUser.id}
                      style={{
                        width: layout.width || "auto",
                        height: layout.height || "100%",
                      }}
                    >
                      {/* Videos for remote peers */}
                      <RemoteVideo
                        callControls={callControls}
                        remoteUser={remoteUser}
                      />
                    </div>
                  ))}
                  <div
                    className="flex justify-center items-center p-1 transition-all"
                    key="local-user"
                    style={{
                      width: layout.width || "auto",
                      height: layout.height || "100%",
                    }}
                  >
                    {/* Video for local user */}
                    <LocalVideo
                      callControls={callControls}
                      camEnabled={camEnabled}
                      joinRoom={joinRoom}
                      mediaFeatures={mediaFeatures}
                      micEnabled={micEnabled}
                      toggleCam={toggleCam}
                      toggleMic={toggleMic}
                      updateVideoRef={() => {
                        if (videoRef.current) {
                          videoRef.current.srcObject = streamRef.current;
                          updateLayout();
                        }
                      }}
                      userId={localUser.userId}
                      userName={localUser.details.fullName}
                      videoRef={videoRef}
                    />
                  </div>
                  {/* Test videos for layout testing */}
                  {debugMode &&
                    repeatElement(
                      <div
                        className="flex justify-center items-center p-1 transition-all"
                        style={{
                          width: layout.width || "auto",
                          height: layout.height || "100%",
                        }}
                      >
                        {/* Video for local user */}
                        <TestVideo callControls={callControls} />
                      </div>,
                      testVideoCount
                    )}
                </div>
              )}
              {callStarted && callControls}
            </>
          )}

          {callEnded && (
            <div className="flex justify-center">
              <Card
                title={strings.videoConsultation}
                type="simple"
                marginClass="mt-0 md:mt-20"
              >
                {getIcon(true)}
                <p>
                  {strings.formatString(
                    strings.meetingEnded,
                    timeToString(callTime)
                  )}
                </p>
                <Button
                  onClick={() => window.location.reload()}
                  variant="primary"
                >
                  {strings.goBackToWaitingRoom}
                </Button>
              </Card>
            </div>
          )}
        </section>
      </main>
      <PermissionRequestModal
        show={showPermissionRequestModal}
        onHide={() => setShowPermissionRequestModal(false)}
      />
      <MediaErrorModal
        show={showMediaErrorModal}
        onHide={() => setShowMediaErrorModal(false)}
      />
    </>
  );
};

export default VideoConsultation;
