/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import DatePickerInput from "../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";

interface Props {
  reloadInvoiceList(): void;
}

export enum DateTemplate {
  today = "today",
  yesterday = "yesterday",
  thisWeek = "thisWeek",
  thisMonth = "thisMonth",
  pastWeek = "pastWeek",
  pastMonth = "pastMonth",
  thisYear = "thisYear",
  pastYear = "pastYear",
  custom = "custom",
}

const InvoiceDateFilters = (props: Props): ReactElement => {
  const { reloadInvoiceList } = props;
  const [isDatesReadOnly, setDatesReadOnly] = useState<boolean>(true);
  const { control, register, setValue } = useFormContext();

  const onDateTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const dateTemplate = e.target.value as DateTemplate;
    switch (dateTemplate) {
      case DateTemplate.today:
        setValue("startDate", new Date());
        setValue("endDate", new Date());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.yesterday:
        setValue("startDate", moment().add(-1, "day").startOf("day").toDate());
        setValue("endDate", moment().add(-1, "day").endOf("day").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.thisWeek:
        setValue("startDate", moment().startOf("week").toDate());
        setValue("endDate", moment().endOf("week").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.pastWeek:
        setValue(
          "startDate",
          moment().add(-1, "week").startOf("week").toDate()
        );
        setValue("endDate", moment().add(-1, "week").endOf("week").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.thisMonth:
        setValue("startDate", moment().startOf("month").toDate());
        setValue("endDate", moment().endOf("month").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.pastMonth:
        setValue(
          "startDate",
          moment().add(-1, "month").startOf("month").toDate()
        );
        setValue("endDate", moment().add(-1, "month").endOf("month").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.thisYear:
        setValue("startDate", moment().startOf("year").toDate());
        setValue("endDate", moment().endOf("year").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      case DateTemplate.pastYear:
        setValue(
          "startDate",
          moment().add(-1, "year").startOf("year").toDate()
        );
        setValue("endDate", moment().add(-1, "year").endOf("year").toDate());
        setDatesReadOnly(true);
        reloadInvoiceList();
        return;
      default:
        setDatesReadOnly(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Select
        isChooseOption={false}
        label={strings.date}
        name="dateTemplate"
        onChange={onDateTemplateChange}
        options={[
          { title: strings.today, value: DateTemplate.today },
          { title: strings.yesterday, value: DateTemplate.yesterday },
          { title: strings.currentWeek, value: DateTemplate.thisWeek },
          { title: strings.pastWeek, value: DateTemplate.pastWeek },
          { title: strings.currentMonth, value: DateTemplate.thisMonth },
          { title: strings.pastMonth, value: DateTemplate.pastMonth },
          { title: strings.currentYear, value: DateTemplate.thisYear },
          { title: strings.pastYear, value: DateTemplate.pastYear },
          { title: `${strings.custom}...`, value: DateTemplate.custom },
        ]}
        register={register}
      />
      <DatePickerInput
        control={control}
        label={strings.from}
        name="startDate"
        onChange={() => {
          reloadInvoiceList();
        }}
        readOnly={isDatesReadOnly}
      />
      <DatePickerInput
        control={control}
        label={strings.to}
        name="endDate"
        onChange={() => {
          reloadInvoiceList();
        }}
        readOnly={isDatesReadOnly}
      />
    </div>
  );
};

export default InvoiceDateFilters;
