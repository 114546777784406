/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState, useEffect } from "react";
import { reservationStates, strings } from "../../../common/Strings/Strings";
import PendingInvoiceListRow from "./PendingInvoiceListRow";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { InvoicePaymentStatuses } from "../InvoiceFilters";
import { useForm } from "react-hook-form";
import { DateTemplate } from "../InvoiceDateFilters";
import { SearchInvoices } from "../../../models/apiparams/SearchInvoices";
import { CostAccountingStatus } from "../../../models/costaccounting/CostAccountingStatus";
import { InvoiceStatus } from "../../../models/invoice/InvoiceStatus";
import { AxiosResponse } from "axios";
import { PageResponse } from "../../../models/PageResponse";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import ClinicInvoiceApi from "../../../api/ClinicInvoiceApi";
import Paging from "../../../components/Paging";
import PageSizeSelect from "../../../components/PageSizeSelect";
import EmptyListText from "../../../components/EmptyListText";
import moment from "moment";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";

interface Props {
  clinic?: ClinicResponse;
}

const PendingInvoiceList: React.FC<Props> = ({ clinic }: Props) => {
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [reloadInvoices, setReloadInvoices] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);

  const triggerReloadInvoices = () => {
    setReloadInvoices((prevState) => !prevState);
  };

  const filterFormMethods = useForm<SearchInvoices & InvoicePaymentStatuses>({
    mode: "onChange",
    defaultValues: {
      dateOf: "issue",
      dateTemplate: DateTemplate.thisYear,
      endDate: moment().toDate(),
      pageNumber: 0,
      pageSize: 50,
      paymentStatus: [CostAccountingStatus.UNRESOLVED],
      searchCategory: "customer",
      startDate: moment().dayOfYear(1).toDate(),
      unresolved: true,
    },
  });
  const {
    getValues: getInvoiceFilterFormValues,
    setValue: setInvoiceFilterFormValue,
  } = filterFormMethods;

  useEffect(() => {
    let isMounted = true;
    const getInvoices = async () => {
      setLoading(true);
      try {
        let response: AxiosResponse<PageResponse<InvoiceResponse>>;
        const request = getInvoiceFilterFormValues();
        const paymentStatus: CostAccountingStatus[] = [];

        if (request.resolved) {
          paymentStatus.push(CostAccountingStatus.RESOLVED);
        }
        if (request.unresolved) {
          paymentStatus.push(CostAccountingStatus.UNRESOLVED);
        }
        if (request.withdrawn) {
          paymentStatus.push(CostAccountingStatus.WITHDRAWN);
        }

        if (clinic?.id) {
          setLoading(true);
          response = await ClinicInvoiceApi.getInvoices(clinic.id, {
            ...request,
            invoiceStatus: [InvoiceStatus.IN_PROGRESS],
            paymentStatus,
          });
        } else {
          return;
        }
        if (isMounted) {
          setError(null);
          setInvoices(response.data.elements);
          setTotalPages(response.data.totalPages);
          setTotalResults(response.data.totalElements);
        }
      } catch (err) {
        if (isMounted) {
          setError(await getGeneralError(err));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    void getInvoices();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, reloadInvoices]);

  return (
    <div className="space-y-6">
      <div className="flex items-end">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {reservationStates.READY_FOR_PAYMENT}
        </h2>
        <div className="ml-auto">
          {invoices.length > 4 && !isMore ? (
            <div className="flex">
              <button
                className="tw-link ml-auto"
                onClick={() => {
                  setIsMore(true);
                }}
              >
                {strings.showMore}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <AlertBox message={error} />
      {loading ? <LoaderInline /> : <></>}
      {invoices.length === 0 ? (
        <div className="tw-card p-4">
          <EmptyListText />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-6">
          {invoices
            .slice(0, isMore ? undefined : 4)
            .map((invoice: InvoiceResponse) => (
              <PendingInvoiceListRow
                invoice={invoice}
                setError={(err: string) => {
                  if (setError) {
                    setError(err);
                  }
                }}
                reload={triggerReloadInvoices}
              />
            ))}
        </div>
      )}
      <div className="flex items-center">
        {invoices.length === 0 || !isMore ? (
          <></>
        ) : (
          <>
            <PageSizeSelect
              pageSize={getInvoiceFilterFormValues("pageSize") || 50}
              setPageSize={(newPageSize: number) => {
                setInvoiceFilterFormValue("pageSize", newPageSize);
                triggerReloadInvoices();
              }}
              totalPages={totalPages}
              totalResults={totalResults}
            />
            <div className="ml-auto flex items-center">
              <Paging
                totalPages={totalPages}
                currentPage={getInvoiceFilterFormValues("pageNumber") || 0}
                selectPage={(newPageNumber: number) => {
                  setInvoiceFilterFormValue("pageNumber", newPageNumber);
                  triggerReloadInvoices();
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PendingInvoiceList;
