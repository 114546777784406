/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import NumberFormatter from "./NumberFormatter";

export interface InvoiceItem {
  price?: number;
  quantity: number;
  vat?: number;
}

export default class CostCalculator {
  static getNetPriceOfItem(item: InvoiceItem): number {
    return NumberFormatter.round(
      NumberFormatter.round(item.price ?? 0) * Number(item.quantity)
    );
  }

  static getGrossPriceOfItem(item: InvoiceItem, roundingDecimalLength?: number): number {
    const netPrice = this.getNetPriceOfItem(item);
    return NumberFormatter.round(
      netPrice * ((100 + Number(item.vat ?? 0)) / 100), roundingDecimalLength
    );
  }

  static getTotalGrossPriceOfInvoiceItems(items: InvoiceItem[], roundingDecimalLength?: number): number {
    let result = 0;

    items.forEach((item: InvoiceItem) => {
      result = result + (item.price ? this.getGrossPriceOfItem(item, roundingDecimalLength) : 0);
    });

    return result;
  }

  static getTotalNetPriceOfInvoiceItems(items: InvoiceItem[], roundingDecimalLength?: number): number {
    let result = 0;

    items.forEach((item: InvoiceItem) => {
      result = result + (item.price ? this.getNetPriceOfItem(item) : 0);
    });

    return result;
  }

  static getNetPrice = (gross: number, vat?: number): number =>
    NumberFormatter.round(
      NumberFormatter.round(gross) / ((100 + Number(vat ?? 0)) / 100)
    );

  static getGrossPrice = (net: number, vat?: number, roundingDecimalLength?: number): number =>
    NumberFormatter.round(
      NumberFormatter.round(net) * ((100 + Number(vat ?? 0)) / 100), roundingDecimalLength
    );
}
