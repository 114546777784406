/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import Select from "../../../../../components/ReactHookFormFields/General/Select/Select";
import InventoryCategoryApi from "../../../../../api/InventoryCategoryApi";
import logger from "../../../../../util/logger";
import { generateOptions } from "../../../../../util/helperFunctions";
import CheckBox from "../../../../../components/ReactHookFormFields/General/CheckBox";
import InventoryItemApi from "../../../../../api/InventoryItemApi";
import { useDebouncedState } from "../../../../../hooks/hooks";
import { PageResponse } from "../../../../../models/PageResponse";
import Paging from "../../../../../components/Paging";
import LoaderInline from "../../../../../components/LoaderInline";
import { InvoiceItemRequest } from "../../../../../models/invoice/InvoiceItemRequest";
import PageSizeSelect from "../../../../../components/PageSizeSelect";
import { InventoryCategoryResponse } from "../../../../../models/inventory/category/InventoryCategoryResponse";
import { InventoryItemResponse } from "../../../../../models/inventory/item/InventoryItemResponse";
import ResultRow from "./ResultRow";
import EmptyListText from "../../../../../components/EmptyListText";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";

interface Props {
  addCustomCost(): void;
  addToCart(item: InvoiceItemRequest): void;
  countryDetails?: CountryDetailsResponse;
  clinicId?: string;
}

interface SearchParams {
  query?: string;
  categoryId?: string;
  isInStock?: boolean;
}

const AddInventoryItems = ({
  addCustomCost,
  addToCart,
  clinicId,
  countryDetails
}: Props): ReactElement => {
  const { register, getValues } = useForm<SearchParams>();
  const query = register("query");

  const [categories, setCategories] = useState<InventoryCategoryResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchItemResults, setItemResults] =
    useState<PageResponse<InventoryItemResponse>>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const productSearchParams = useDebouncedState<SearchParams>({}, 600);

  useEffect(() => {
    const search = async (
      queryText?: string,
      categoryId?: string,
      pageNumber?: number,
      pageSizeValue?: number,
      isInStock?: boolean
    ) => {
      setLoading(true);

      if (clinicId) {
        try {
          const response = await InventoryItemApi.searchItemsOfClinic(
            clinicId,
            {
              query: queryText,
              categoryId,
              pageNumber,
              pageSize: pageSizeValue,
              stockFilter: isInStock ? "IN_STOCK" : undefined,
            }
          );
          setItemResults(response.data);
        } catch (err) {
          logger.error(err);
        } finally {
          setLoading(false);
        }
      }
    };
    void search(
      productSearchParams.value.query,
      productSearchParams.value.categoryId,
      page,
      pageSize,
      productSearchParams.value.isInStock
    );
  }, [productSearchParams.value, page, pageSize, clinicId]);

  const searchChange = () => {
    const q = getValues("query");
    const categoryId = getValues("categoryId");
    const isInStock = getValues("isInStock");

    productSearchParams.setValue({ query: q, categoryId, isInStock });
    setPage(0);
  };

  useEffect(() => {
    const getCategories = async () => {
      if (clinicId) {
        try {
          const response = await InventoryCategoryApi.getAllCategories(
            clinicId
          );
          setCategories(response.data || []);
        } catch (e) {
          logger.error(e);
        }
      }
    };

    void getCategories();
  }, [clinicId]);

  return (
    <>
      <div className="py-4">
        <form>
          <div className="w-full flex flex-wrap md:flex-nowrap space-x-4">
            <div className="w-64">
              <div className="flex justify-between">
                <label htmlFor="search">{strings.search}</label>
                <div
                  aria-hidden
                  role="button"
                  className="text-sky-500"
                  onClick={() => {
                    addCustomCost();
                  }}
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  <b>{strings.addNew}</b>
                </div>
              </div>
              <input
                autoComplete={AutoCompleteOptions.off}
                className="tw-input"
                name={query.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  void query.onChange(e);
                  searchChange();
                }}
                placeholder={strings.search}
                ref={query.ref}
                type="text"
              />
            </div>
            <div>
              <Select
                className="mb-0"
                name="categoryId"
                register={register}
                isChooseOption={false}
                firstOption={{ title: strings.all, value: "" }}
                options={generateOptions(categories, "name", "id")}
                label={strings.category}
                onChange={searchChange}
              />
            </div>
            <CheckBox
              className="mt-8"
              name="isInStock"
              register={register}
              label={strings.stock}
              onChange={searchChange}
            />
          </div>
        </form>
      </div>
      <div>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.item}</th>
                <th className="tw-th">
                  {strings.price} ({strings.net})
                </th>
                <th className="tw-th">{strings.vat}</th>
                <th className="tw-th">
                  {strings.price} ({strings.gross})
                </th>
                <th className="tw-th">{strings.quantity}</th>
                <th />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {loading ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <LoaderInline />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading && searchItemResults?.elements?.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading &&
                searchItemResults?.elements
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((result: InventoryItemResponse, index: number) => (
                    <ResultRow
                      result={result}
                      index={index}
                      addToCart={addToCart}
                      key={result.id}
                      roundingDecimalLength={countryDetails?.roundingDecimalLength}
                    />
                  ))}
            </tbody>
          </table>
        </div>
        <div className="flex py-3 flex-wrap">
          <div className="flex items-center">
            {loading ? (
              <></>
            ) : (
              <PageSizeSelect
                pageSize={pageSize}
                setPageSize={(newPageSize: number) => {
                  setPageSize(newPageSize);
                }}
                totalPages={searchItemResults?.totalPages || 1}
                totalResults={searchItemResults?.totalElements || 1}
              />
            )}
          </div>
          <div className="ml-auto">
            {loading ? (
              <></>
            ) : (
              <Paging
                currentPage={page}
                totalPages={searchItemResults?.totalPages || 1}
                selectPage={(newPageNumber: number) => {
                  setPage(newPageNumber);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventoryItems;

AddInventoryItems.defaultProps = {
  clinicId: undefined,
};
