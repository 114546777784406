/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import { UsedInventoryItemResponse } from "../../../models/medical/UsedInventoryItemResponse";
import { ExtraCost } from "../../../models/medical/ExtraCost";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import Prices from "../../../components/InputFieldsSimple/Prices";
import CostCalculator from "../../../util/CostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

interface Props {
  accessScope: ReservationAccessScope;
  canEdit: boolean;
  countryDetails?: CountryDetailsResponse;
  deleteCurrent?: () => void;
  item: UsedInventoryItemResponse | ExtraCost;
  readonly?: boolean;
  priceChange: (value: number) => void;
  quantityChange: (value: number) => void;
}

const ItemsRow: React.FC<Props> = ({
  accessScope,
  canEdit,
  countryDetails,
  deleteCurrent,
  item,
  priceChange,
  quantityChange,
  readonly,
}: Props) => {
  const acquisitionUnit: string = ("unit" in item && item?.unit) || "PIECES";

  return (
    <div>
      <div className="flex items-center">
        <div className="font-bold mr-3">{item.name}</div>
        <div className="flex items-center ml-auto space-x-3">
          <div>
            <div hidden={accessScope === ReservationAccessScope.PEEK}>
              {readonly ? (
                <div className="text-right whitespace-nowrap">
                  {item.quantity || 0} x{" "}
                </div>
              ) : (
                <SimpleInput
                  autoComplete={AutoCompleteOptions.off}
                  className="mb-0"
                  label={strings.quantity}
                  name="quantity"
                  suffix={quantityUnits[acquisitionUnit]}
                  onChange={(e) => quantityChange(parseFloat(e))}
                  placeholder=""
                  readOnly={!canEdit}
                  value={item.quantity || 0}
                  width="90px"
                />
              )}
            </div>
          </div>
          <div>
            <div hidden={accessScope === ReservationAccessScope.PEEK}>
              {readonly ? (
                <div className="whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    CostCalculator.getGrossPrice(item.price || 0, item.vat, countryDetails?.roundingDecimalLength),
                    countryDetails?.currency
                  )}
                </div>
              ) : (
                <Prices
                  currency={countryDetails?.currency}
                  label={strings.price}
                  onChange={(p) => priceChange(p || 0)}
                  readOnly={!canEdit}
                  roundingDecimalLength={countryDetails?.roundingDecimalLength}
                  value={item.price || 0}
                  vat={item.vat}
                  width="110px"
                />
              )}
            </div>
          </div>
          {canEdit && !readonly ? (
            <div>
              <Button
                variant="basic"
                onClick={() => {
                  if (deleteCurrent) {
                    deleteCurrent();
                  }
                }}
              >
                <XMark className="w-5 h-5" />
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex items-center text-gray-500">
        <div>
          {strings.subtotal} ({strings.gross})
        </div>
        <div className="ml-auto">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPrice(
              item.quantity * (item.price || 0),
              item.vat,
              countryDetails?.roundingDecimalLength
            ),
            countryDetails?.currency
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsRow;
