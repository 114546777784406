/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import CreditCardDetails from "./CreditCardDetails/CreditCardDetails";
import { strings } from "../../../../common/Strings/Strings";
import GiroAccounts from "./GiroAccounts/GiroAccounts";
import StripeIntegration from "./Stripe/StripeIntegration";
import FortnoxIntegration from "./Fortnox/FortnoxIntegration";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import { ClinicFeature } from "../../../../models/clinic/ClinicFeature";
import { isFeatureAvailable } from "../../../../util/helperFunctions";

interface Props {
  reqClinic: ClinicResponse;
  resClinic: (newClinic: ClinicResponse) => void;
}

const BankAndBillingTab: React.FC<Props> = ({
  reqClinic,
  resClinic,
}: Props) => {
  const [clinic, setClinic] = useState<ClinicResponse>(reqClinic);

  useEffect(() => {
    resClinic(clinic);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  return (
    <div className="w-full md:tw-card md:max-w-2xl space-y-6 md:p-8">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.bankAndBilling}
        </h2>
      </div>
      {isFeatureAvailable(clinic, ClinicFeature.CREDIT_CARD) && (
        <CreditCardDetails clinic={clinic} setClinic={setClinic} />
      )}
      {isFeatureAvailable(clinic, ClinicFeature.GIRO_ACCOUNTS) && (
        <GiroAccounts clinic={clinic} setClinic={setClinic} />
      )}
      {isFeatureAvailable(clinic, ClinicFeature.STRIPE) && (
        <StripeIntegration clinic={clinic} setClinic={setClinic} />
      )}
      {isFeatureAvailable(clinic, ClinicFeature.FORTNOX) && (
        <FortnoxIntegration clinic={clinic} setClinic={setClinic} />
      )}
      {isFeatureAvailable(clinic, ClinicFeature.PAYMENT_METHODS) && (
        <PaymentMethods clinic={clinic} />
      )}
    </div>
  );
};

export default BankAndBillingTab;
