/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { ServiceFeeResponse } from "../../../../../models/servicefee/ServiceFeeResponse";
import NumberFormatter from "../../../../../util/NumberFormatter";
import CostCalculator from "../../../../../util/CostCalculator";
import SimpleInput from "../../../../../components/InputFieldsSimple/SimpleInput";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import LoaderInline from "../../../../../components/LoaderInline";
import { CartItem } from "../../../../../util/InvoiceCartUtils";
import { PlusIcon } from "../../../../../common/Icons/PlusIcon";
import Button from "../../../../../components/Button";

export type Fee = Omit<ServiceFeeResponse, "id"> & {
  id?: string;
};

interface Props {
  result: Fee;
  index: number;
  addToCart(item: CartItem): void;
  roundingDecimalLength?: number;
}

const FeeResultRow = ({ result, index, addToCart, roundingDecimalLength }: Props): ReactElement => {
  const [quantity, setQuantity] = useState<number>(1);
  const [adding, setAdding] = useState<boolean>(false);

  const displayAdd = () => {
    setAdding(true);
    setTimeout(() => setAdding(false), 500);
  };

  const add = ({ name, price, vat, accountingCode, id }: Fee) => {
    const newItem: CartItem = {
      name,
      price: price || 1,
      quantity,
      vat: vat || 0,
      accountingCode,
      localId: id?.toString(),
    };

    addToCart(newItem);
  };

  return (
    <tr key={index}>
      <td className="px-4 py-2 text-sm font-medium">
        <b>{result.name}</b>
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(result.price || 0, result.currency)}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {result.vat} %
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(
          CostCalculator.getGrossPriceOfItem({
            ...result,
            quantity: 1,
            vat: result.vat,
            price: result.price || 0,
          }, roundingDecimalLength),
          result.currency
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
        <SimpleInput
          autoComplete={AutoCompleteOptions.off}
          name="quantity"
          type="number"
          value={quantity}
          onChange={(value: string) => {
            setQuantity(value.length > 0 ? Number(value) : 1);
          }}
          width="80px"
          className="m-0"
        />
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        <div className="flex justify-end">
          {adding ? (
            <LoaderInline />
          ) : (
            <Button
              className="p-1"
              fullWidth={false}
              onClick={() => {
                add(result);
                displayAdd();
              }}
            >
              <PlusIcon />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default FeeResultRow;
