/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import { TestInvoiceResponse } from "../../../models/invoice/TestInvoiceResponse";
import TestInvoiceApi from "../../../api/TestInvoiceApi";
import { Tooltip } from "../../../components/Tooltip";
import NumberFormatter from "../../../util/NumberFormatter";
import { downloadTestInvoicePdf } from "../../../util/FileUtils";
import TestInvoicePaymentModal from "../TestInvoice/TestInvoicePaymentModal";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
  onLoadingChange: (isLoading: boolean) => void;
  onError: (error: string | null) => void;
}

const TestInvoices: React.FC<Props> = (props: Props) => {
  const { isActiveTab, countryCode, onLoadingChange, onError } = props;

  const [invoices, setInvoices] = useState<TestInvoiceResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<TestInvoiceResponse>();

  const getTestInvoices = async () => {
    onLoadingChange(true);
    try {
      const resp = await TestInvoiceApi.getInvoices(countryCode);
      setInvoices(resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getTestInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, countryCode]);

  const deleteInvoice = async (invoiceId: string) => {
    onLoadingChange(true);
    try {
      await TestInvoiceApi.deleteInvoice(countryCode, invoiceId);
      await getTestInvoices();
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  const openPaymentModal = (invoice: TestInvoiceResponse) => {
    setSelectedInvoice(invoice);
    setPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setPaymentModalOpen(false);
    setSelectedInvoice(undefined);
  };

  const getInvoiceState = (invoice: TestInvoiceResponse) => {
    let icon;

    switch (invoice.valid) {
      case false:
        icon = (
          <Tooltip
            content={invoice.validationError || strings.failedToSendToFortnox}
            placement="right"
          >
            <i className="fas fa-exclamation-circle text-red-500" />
          </Tooltip>
        );
        break;
      case true:
        icon = (
          <Tooltip content={strings.sentToFortnox} placement="right">
            <i className="fas fa-check-circle color-green" />
          </Tooltip>
        );
        break;
      default:
        icon = undefined;
        break;
    }

    return icon;
  };

  return (
    <>
      <AlertBox message={error} className="mb-3" />
      <div className="card mb-4">
        <div className="d-flex block-label border-bottom-1">
          <div className="p-3">{strings.testInvoices}</div>
        </div>
        <div hidden={invoices.length <= 0}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="no-border-on-top" style={{ maxWidth: "32px" }} />
                <th className="no-border-on-top">
                  {strings.invoiceCreationDate}
                </th>
                <th className="no-border-on-top text-right">
                  {strings.totalCost}
                </th>
                <th className="no-border-on-top" />
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr>
                  <td className="align-middle text-center">
                    {getInvoiceState(invoice)}
                  </td>
                  <td className="align-middle">
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={async () => {
                        onLoadingChange(true);
                        setError(null);
                        try {
                          await downloadTestInvoicePdf(countryCode, invoice);
                          if (!invoice.valid) {
                            await getTestInvoices();
                          }
                        } catch (err) {
                          setError(await getGeneralError(err));
                        } finally {
                          onLoadingChange(false);
                        }
                      }}
                    >
                      {moment(invoice.creationDate).format(
                        dateAndTime.momentDateTimeFormat
                      )}
                    </Button>
                  </td>
                  <td className="align-middle text-right">
                    {NumberFormatter.formatPrice(
                      invoice.totalCost,
                      invoice.currency
                    )}
                  </td>
                  <td className="text-right">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => openPaymentModal(invoice)}
                    >
                      {strings.pay}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => deleteInvoice(invoice.id)}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div hidden={invoices.length > 0} className="my-5 text-center">
          <i>{strings.noTestInvoiceCreatedYet}</i>
        </div>
        <div>
          <div className="hr-on-top d-flex p-3">
            <Link
              to={`/management/countries/${countryCode}/test-invoice`}
              className="btn btn-primary ml-auto"
            >
              {strings.createTestInvoice}
            </Link>
          </div>
        </div>
      </div>
      {selectedInvoice && (
        <TestInvoicePaymentModal
          isOpen={isPaymentModalOpen}
          close={closePaymentModal}
          countryCode={countryCode}
          invoice={selectedInvoice}
        />
      )}
    </>
  );
};

export default TestInvoices;
