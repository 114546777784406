/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const userAnalyticsEventTypeHu = {
  ADDRESS_ADDED: "Cím hozzáadva",
  ADDRESS_DELETED: "Cím törölve",
  EMAIL_ADDED: "Email hozzáadva",
  EMAIL_CONFIRMATION_RESENT: "Email megerősítés újraküldve",
  EMAIL_DELETED: "Email törölve",
  LOGIN_SUCCEEDED: "Sikeres bejelentkezés",
  PASSWORD_CHANGED: "Jelszó módosítva",
  PHONE_ADDED: "Telefon hozzáadva",
  PHONE_CONFIRMATION_RESENT: "Telefon megerősítés újraküldve",
  PHONE_CONFIRMED: "Telefon megerősítve",
  PHONE_DELETED: "Telefon törölve",
  PHONE_UPDATED: "Telefon módosítva",
  PIN_CODE_CHANGED: "Pin kód módosítva",
  PIN_CODE_CONFIRMED: "Pin kód megerősítve",
  PREFERENCES_ADDED: "Beállítások elmentve",
  PRIMARY_ADDRESS_CHANGED: "Elsődleges cím módosítva",
  PRIMARY_EMAIL_CHANGED: "Elsődleges email módosítva",
  PRIMARY_PHONE_CHANGED: "Elsődleges telefon módosítva",
  SOCIAL_ACCOUNT_LINKED: "Közösségi fiók összekapcsolva",
  USER_DELETED: "Felhasználó törölve",
  USER_REGISTERED: "Felhasználó regisztrálva",
  USER_UPDATED: "Felhasználó módosítva",
  VET_DOCUMENT_ADDED: "Állatorvosi dokumentum hozzáadva",
  VET_DOCUMENT_DELETED: "Állatorvosi dokumentum törölve",
  VET_REGISTRATION_NUMBER_ADDED: "Állatorvosi azonosító hozzáadva",
  VET_REGISTRATION_NUMBER_DELETED: "Állatorvosi azonosító törölve",
  VET_REGISTRATION_NUMBER_UPDATED: "Állatorvosi azonosító módosítva",
};
