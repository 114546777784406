/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState, ReactElement } from "react";
import jwtDecode from "jwt-decode";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link, useParams } from "react-router-dom";
import { PageProps } from "../../models/PageProps";
import { useUser } from "../../contexts/UserContext";
import AlertBox from "../../components/AlertBox";
import { useChosenRole } from "../Main/Onboarding/ChooseRole";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { strings } from "../../common/Strings/Strings";
import FeeSection from "./Fees/FeeSection";
import PolicySection from "./Policy/PolicySection";
import CountryApi from "../../api/CountryApi";
import { getGeneralError } from "../../util/helperFunctions";
import { CountrySettingsResponse } from "../../models/management/CountrySettingsResponse";
import { Loader } from "../../components/Loader";
import PaymentAndBillingSection from "./PaymentAndBilling/PaymentAndBillingSection";
import InsuranceConfigSection from "./InsuranceConfigSection";

export const CountrySettingsPage = (props: PageProps): ReactElement => {
  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];
  const { user } = useUser();
  const { setPageLoading } = props;
  const { countryCode, tab } = useParams<"countryCode" | "tab">();
  const [key, setKey] = useState<string>(tab || "fees");
  const [country, setCountry] = useState<CountrySettingsResponse>();
  const [error, setError] = useState<string | null>(null);

  useChosenRole("manager");

  useEffect(() => {
    const getCountrySettings = async (code: string) => {
      setPageLoading(true);
      try {
        const resp = await CountryApi.getCountrySettingsByCode(code);
        setCountry(resp.data);
        setPageLoading(false);
      } catch (err) {
        setError(await getGeneralError(err));
        setPageLoading(false);
      }
    };

    if (countryCode) {
      void getCountrySettings(countryCode);
    }
  }, [countryCode]);

  const hasAuthority = (authority: string): boolean => {
    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  if (!user.isSiteManager) {
    setPageLoading(false);
    return <AlertBox message="Forbidden" className="m-3" closeAble={false} />;
  }

  return (
    <>
      <main className="main-default">
        <section>
          <div className="fix-container mt-5">
            <div hidden={error == null}>
              <AlertBox message={error} className="my-3" />
            </div>
            <div
              hidden={error !== null}
              className="background-rectangle"
              style={{ minHeight: "90vh" }}
            >
              <div className="justify-content-center">
                <div className="justify-content-center d-flex flex-wrap align-items-center">
                  <Link
                    to="/management/settings"
                    className="admin-title text-sky-500"
                  >
                    {strings.settings}
                  </Link>
                  <i className="fas fa-chevron-right mx-3" />
                  <span className="admin-title">{country?.countryName}</span>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12">
                  <div
                    id="settings-tabs"
                    className="d-flex justify-content-center flex-column settings-tabs"
                  >
                    <Tabs
                      className="justify-content-center"
                      id="managementTabs"
                      activeKey={key}
                      onSelect={(k: any): void => {
                        setKey(k);
                      }}
                    >
                      {hasAuthority("MANAGER_FEES") && (
                        <Tab eventKey="fees" title={strings.fees}>
                          <div className="row my-4">
                            <div className="col-12">
                              {countryCode && (
                                <FeeSection
                                  isActiveTab={key === "fees"}
                                  countryCode={countryCode}
                                />
                              )}
                            </div>
                          </div>
                        </Tab>
                      )}

                      {hasAuthority("MANAGER_CONFIG") && (
                        <Tab
                          eventKey="payment-and-billing"
                          title={strings.paymentAndBilling}
                        >
                          <div className="row my-4">
                            <div className="col-12">
                              {countryCode && (
                                <PaymentAndBillingSection
                                  isActiveTab={key === "payment-and-billing"}
                                  countryCode={countryCode}
                                />
                              )}
                            </div>
                          </div>
                        </Tab>
                      )}

                      {hasAuthority("MANAGER_CONFIG") && country && (
                        <Tab
                          eventKey="insurance"
                          title={strings.insuranceTitle}
                        >
                          <div className="row my-4">
                            <div className="col-12">
                              {countryCode && (
                                <InsuranceConfigSection
                                  isActiveTab={key === "insurance"}
                                  countryCode={countryCode}
                                />
                              )}
                            </div>
                          </div>
                        </Tab>
                      )}

                      {hasAuthority("MANAGER_POLICIES") && country && (
                        <Tab eventKey="policies" title={strings.policies}>
                          <div className="row my-4">
                            <div className="col-12">
                              <PolicySection
                                isActiveTab={key === "policies"}
                                country={country}
                              />
                            </div>
                          </div>
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Loader(CountrySettingsPage);
