/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum ClinicFeature {
  ALL = "ALL",
  ACCOUNTING_CODES = "ACCOUNTING_CODES",
  CALENDAR = "CALENDAR",
  CREDIT_CARD = "CREDIT_CARD",
  EMPLOYEES = "EMPLOYEES",
  FEATURE_SETTINGS = "FEATURE_SETTINGS",
  FORTNOX = "FORTNOX",
  GIRO_ACCOUNTS = "GIRO_ACCOUNTS",
  INVENTORY = "INVENTORY",
  INVOICE = "INVOICE",
  MONTHLY_CHARGES = "MONTHLY_CHARGES",
  PAYMENT_METHODS = "PAYMENT_METHODS",
  PETS = "PETS",
  PET_OWNERS = "PET_OWNERS",
  PET_OWNER_RESERVATION = "PET_OWNER_RESERVATION",
  PHARMACIES = "PHARMACIES",
  PRESCRIPTION = "PRESCRIPTION",
  RESERVATION = "RESERVATION",
  RESERVATION_TYPES = "RESERVATION_TYPES",
  SERVICE_FEES = "SERVICE_FEES",
  SHIFTS = "SHIFTS",
  STATISTICS = "STATISTICS",
  STRIPE = "STRIPE",
  SUBSTITUTION = "SUBSTITUTION",
  TODOS = "TODOS",
  TREATMENTS = "TREATMENTS",
  VIDEO_CONSULTATION = "VIDEO_CONSULTATION",
}
