/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import InvoiceListRow from "./InvoiceListRow";
import { FormProvider, useForm } from "react-hook-form";
import { DateTemplate } from "../InvoiceDateFilters";
import { SearchInvoices } from "../../../models/apiparams/SearchInvoices";
import InvoiceFilters, { InvoicePaymentStatuses } from "../InvoiceFilters";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { CostAccountingStatus } from "../../../models/costaccounting/CostAccountingStatus";
import { InvoiceStatus } from "../../../models/invoice/InvoiceStatus";
import { getGeneralError } from "../../../util/helperFunctions";
import ClinicInvoiceApi from "../../../api/ClinicInvoiceApi";
import { AxiosResponse } from "axios";
import { PageResponse } from "../../../models/PageResponse";
import PageSizeSelect from "../../../components/PageSizeSelect";
import Paging from "../../../components/Paging";
import moment from "moment";
import EmptyListText from "../../../components/EmptyListText";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";

interface Props {
  clinic?: ClinicResponse;
  invoices?: InvoiceResponse[];
  setError?(error: string): void;
}

const InvoiceList: React.FC<Props> = ({
  clinic,
  invoices: mainInvoices,
}: Props) => {
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [reloadInvoices, setReloadInvoices] = useState<boolean>(false);

  const triggerReloadInvoices = () => {
    setReloadInvoices((prevState) => !prevState);
  };

  const filterFormMethods = useForm<SearchInvoices & InvoicePaymentStatuses>({
    mode: "onChange",
    defaultValues: {
      dateOf: "issue",
      dateTemplate: DateTemplate.thisYear,
      endDate: moment().toDate(),
      pageNumber: 0,
      pageSize: 50,
      paymentStatus: [
        CostAccountingStatus.UNRESOLVED,
        CostAccountingStatus.RESOLVED,
      ],
      searchCategory: "customer",
      startDate: moment().dayOfYear(1).toDate(),
      unresolved: true,
      resolved: true,
    },
  });
  const {
    getValues: getInvoiceFilterFormValues,
    setValue: setInvoiceFilterFormValue,
  } = filterFormMethods;

  useEffect(() => {
    let isMounted = true;
    const getInvoices = async () => {
      setLoading(true);
      try {
        let response: AxiosResponse<PageResponse<InvoiceResponse>>;
        const request = getInvoiceFilterFormValues();
        const paymentStatus: CostAccountingStatus[] = [];
        const invoiceStatus: InvoiceStatus[] = [InvoiceStatus.ACTIVE];

        if (request.resolved) {
          paymentStatus.push(CostAccountingStatus.RESOLVED);
        }
        if (request.unresolved) {
          paymentStatus.push(CostAccountingStatus.UNRESOLVED);
        }
        if (request.withdrawn) {
          paymentStatus.push(CostAccountingStatus.WITHDRAWN);
          invoiceStatus.push(InvoiceStatus.WITHDRAWN);
        }

        if (clinic?.id) {
          setLoading(true);
          response = await ClinicInvoiceApi.getInvoices(clinic.id, {
            ...request,
            invoiceStatus,
            paymentStatus,
          });
        } else {
          return;
        }
        if (isMounted) {
          setError(null);
          setInvoices(response.data.elements);
          setTotalPages(response.data.totalPages);
          setTotalResults(response.data.totalElements);
        }
      } catch (err) {
        if (isMounted) {
          setError(await getGeneralError(err));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (!mainInvoices) {
      void getInvoices();
    } else {
      setInvoices(mainInvoices);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, reloadInvoices]);

  return (
    <div className="space-y-6">
      {mainInvoices ? (
        <></>
      ) : (
        <>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.invoices}
          </h2>
          <AlertBox message={error} />
          {loading ? <LoaderInline /> : <></>}
          <FormProvider {...filterFormMethods}>
            <InvoiceFilters
              tabKey={"active"}
              reloadInvoiceList={() => {
                const invoiceStatus =
                  getInvoiceFilterFormValues("invoiceStatus");

                if (invoiceStatus?.includes(InvoiceStatus.IN_PROGRESS)) {
                  setInvoiceFilterFormValue("dateOf", "issue");

                  if (
                    getInvoiceFilterFormValues("searchCategory") ===
                    "invoiceNumber"
                  ) {
                    setInvoiceFilterFormValue("searchCategory", "customer");
                  }
                }
                triggerReloadInvoices();
                setLoading(true);
              }}
            />
          </FormProvider>
        </>
      )}
      <div className="tw-table-container tw-card">
        {invoices.length === 0 ? (
          <div className="p-4">
            <EmptyListText />
          </div>
        ) : (
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.state}</th>
                <th className="tw-th">{strings.customer}</th>
                <th className="tw-th">{strings.pet}</th>
                <th className="tw-th">{strings.invoiceCreationDate}</th>
                <th className="tw-th">{strings.invoiceNumber}</th>
                <th className="text-right tw-th">
                  {strings.totalCost} ({strings.gross})
                </th>
                <th className="tw-th">{strings.type}</th>
                <th className="tw-th">Fortnox</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {invoices.map((invoice: InvoiceResponse) => (
                <InvoiceListRow
                  invoice={invoice}
                  setError={(err: string) => {
                    if (setError) {
                      setError(err);
                    }
                  }}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {mainInvoices ? (
        <></>
      ) : (
        <div className="flex items-center">
          <PageSizeSelect
            pageSize={getInvoiceFilterFormValues("pageSize") || 50}
            setPageSize={(newPageSize: number) => {
              setInvoiceFilterFormValue("pageSize", newPageSize);
              triggerReloadInvoices();
            }}
            totalPages={totalPages}
            totalResults={totalResults}
          />
          <div className="ml-auto flex items-center">
            <Paging
              totalPages={totalPages}
              currentPage={getInvoiceFilterFormValues("pageNumber") || 0}
              selectPage={(newPageNumber: number) => {
                setInvoiceFilterFormValue("pageNumber", newPageNumber);
                triggerReloadInvoices();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceList;
