/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import Field from "../../components/ReactHookFormFields/General/Field";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";
import { TabState } from "./WorkerInvoices";
import { useDebouncedState } from "../../hooks/hooks";
import InvoiceDateFilters from "./InvoiceDateFilters";
import { Option } from "../../models/Option";

interface Props {
  tabKey: TabState;
  reloadInvoiceList(): void;
}

export interface InvoicePaymentStatuses {
  resolved?: boolean;
  unresolved?: boolean;
  withdrawn?: boolean;
}

export type SearchCategory = "invoiceNumber" | "totalCost" | "customer" | any;

const InvoiceFilters = (props: Props): ReactElement => {
  const { tabKey, reloadInvoiceList } = props;
  const listFiltersChanged = useDebouncedState<string>("", 600);
  const [searchCategory, setSearchCategory] = useState<SearchCategory>(
    "customer"
  );
  const [searchCategoryOptions, setSearchCategoryOptions] = useState<Option[]>(
    []
  );
  const { register } = useFormContext();

  useEffect(() => {
    reloadInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFiltersChanged.value]);

  useEffect(() => {
    if (tabKey === "active") {
      setSearchCategoryOptions([
        { title: strings.searchToolTip, value: "customer" },
        { title: strings.invoiceNumber, value: "invoiceNumber" },
        { title: strings.totalCost, value: "totalCost" },
      ]);
    } else {
      setSearchCategoryOptions([
        { title: strings.searchToolTip, value: "customer" },
        { title: strings.totalCost, value: "totalCost" },
      ]);
    }
  }, [tabKey]);

  return (
    <form>
      <div className="flex items-center flex-wrap space-x-4">
        <InvoiceDateFilters reloadInvoiceList={reloadInvoiceList} />
        <div hidden={tabKey !== "active"}>
          <Select
            isChooseOption={false}
            label={strings.filter}
            name="dateOf"
            onChange={() => {
              reloadInvoiceList();
            }}
            options={[
              {
                title: strings.filterByDateOfIssue,
                value: "issue",
              },
              {
                title: strings.filterByDateOfPayment,
                value: "payment",
              },
            ]}
            register={register}
          />
        </div>
        <div style={{ maxWidth: "250px" }}>
          <Select
            isChooseOption={false}
            label={strings.invoiceSearchCategory}
            name="searchCategory"
            onChange={e => {
              setSearchCategory(e.target.value);
              reloadInvoiceList();
            }}
            options={searchCategoryOptions}
            register={register}
          />
        </div>
        <div hidden={searchCategory !== "customer"}>
          <Field
            label={strings.search}
            name="customer"
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              listFiltersChanged.setValue(e.target.value);
            }}
            placeholder={strings.searchToolTip}
          />
        </div>
        <div hidden={searchCategory !== "totalCost"}>
          <Field
            label={strings.search}
            name="totalCost"
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              listFiltersChanged.setValue(e.target.value);
            }}
            placeholder={strings.totalCost}
            type="number"
          />
        </div>
        <div hidden={searchCategory !== "invoiceNumber"}>
          <Field
            label={strings.search}
            name="invoiceNumber"
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              listFiltersChanged.setValue(e.target.value);
            }}
            placeholder={strings.invoiceNumber}
          />
        </div>
      </div>
      <div className="mt-4" hidden={tabKey !== "active"}>
        <div className="flex items-center">
          <div className="mr-3">
            <CheckBox
              defaultChecked
              label={strings.notPaid}
              name="unresolved"
              onChange={() => {
                reloadInvoiceList();
              }}
              register={register}
            />
          </div>
          <div className="mr-3">
            <CheckBox
              label={strings.paid}
              name="resolved"
              onChange={() => {
                reloadInvoiceList();
              }}
              register={register}
            />
          </div>
          <div className="mr-3">
            <CheckBox
              label={strings.withdrawn}
              name="withdrawn"
              onChange={() => {
                reloadInvoiceList();
              }}
              register={register}
            />
          </div>
        </div>
        <Field
          className="d-none"
          name="pageSize"
          register={register}
          type="number"
        />
        <Field
          className="d-none"
          name="pageNumber"
          register={register}
          type="number"
        />
      </div>
    </form>
  );
};

export default InvoiceFilters;
