/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import InvoiceApi from "../../../api/InvoiceApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import FortnoxAuthenticationError from "./FortnoxAuthenticationError";
import Button from "../../../components/Button";

interface Props {
  invoice: InvoiceResponse;
  returnError?(errorMessage: string | null): void;
  returnRefreshedInvoice?(invoice: InvoiceResponse): void;
}

const FortnoxInvoiceState = ({
  invoice,
  returnError,
  returnRefreshedInvoice,
}: Props): ReactElement => {
  const [isInvoiceRefreshing, setInvoiceRefreshing] = useState<boolean>(false);


  const refreshInvoice = async () => {
    setInvoiceRefreshing(true);

    try {
      const response = await InvoiceApi.getInvoice(invoice.id);
      if (returnRefreshedInvoice) {
        returnRefreshedInvoice(response.data);
      }
      setInvoiceRefreshing(false);
    } catch (err) {
      if (returnError) {
        returnError(await getGeneralError(err));
      }
      setInvoiceRefreshing(false);
    }
  };

  if (invoice?.authenticationRequired) {
    return (
      <FortnoxAuthenticationError
        clinicId={invoice.clinicId}
        hidden={invoice?.valid !== false}
      />
    );
  }

  return (
    <AlertBox
      hidden={invoice?.valid !== false}
      closeAble={false}
      message={
        <div className="flex flex-wrap">
          <div>
            <div className="font-bold">{strings.failedToSendToFortnox}</div>
            <div className="mt-1">{invoice?.validationError}</div>
          </div>
          <div className="mt-2 ml-auto">
            {isInvoiceRefreshing ? (
              <LoaderInline />
            ) : (
              <Button
                variant="danger"
                hidden={invoice?.authenticationRequired}
                onClick={refreshInvoice}
              >
                {strings.retry}
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default FortnoxInvoiceState;

FortnoxInvoiceState.defaultProps = {
  returnError: undefined,
  returnRefreshedInvoice: undefined,
};
