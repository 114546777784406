/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useReducer, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import { petSpecies, strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import { PageProps } from "../../../models/PageProps";
import CountryApi from "../../../api/CountryApi";
import { TreatmentItemResponse } from "../../../models/treatment/TreatmentItemResponse";
import BasicDetails from "./BasicDetails";
import { Option } from "../../../models/Option";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";
import { NewTreatmentRequest } from "../../../models/treatment/NewTreatmentRequest";
import TreatmentApi from "../../../api/TreatmentApi";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import { Loader } from "../../../components/Loader";
import AlertBox from "../../../components/AlertBox";
import { UpdateTreatmentRequest } from "../../../models/treatment/UpdateTreatmentRequest";
import TreatmentPrice from "./TreatmentPrice";
import AddItemModal, { treatmentItemReducer } from "./AddItemModal";
import AddFeeModal, { treatmentFeeReducer } from "./AddFeeModal";
import LoaderInline from "../../../components/LoaderInline";
import { SettingsTabs } from "../../../models/clinic/SettingsTabs";
import { ServiceFeeResponse } from "../../../models/servicefee/ServiceFeeResponse";
import ServiceFeeApi from "../../../api/ServiceFeeApi";
import { InventoryItemResponse } from "../../../models/inventory/item/InventoryItemResponse";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { useClinic } from "../../../contexts/ClinicContext";

interface FormData {
  accountingCode: AccountingCodeResponse[];
  advices?: string;
  complications?: string;
  comment?: string;
  description?: string;
  price?: number;
  newName: string;
  species?: Option[];
  suggestedMedicines?: string;
  vat?: number;
}

enum PageState {
  Basic,
  Price,
  Loading,
}

enum PageType {
  create,
  edit,
}

type Params = {
  treatmentId?: string;
};

const CreateTreatmentPage: React.FC<PageProps> = ({ setPageLoading }) => {
  const [pageState, setPageState] = useState<PageState>(PageState.Basic);
  const [pageType, setPageType] = useState<PageType>(PageType.create);
  const [vatValue, setVatValue] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();

  const { treatmentId } = useParams<Params>();

  const [serviceFees, setServiceFees] = useState<ServiceFeeResponse[]>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryItemResponse[]>(
    []
  );

  const [usedItems, dispatch] = useReducer(treatmentItemReducer, []);
  const [usedFees, dispatchFee] = useReducer(treatmentFeeReducer, []);
  const [treatment, setTreatment] = useState<TreatmentResponse>();
  const [addItemModalOpen, setAddItemModalOpen] = useState<boolean>(false);
  const [addFeeModalOpen, setAddFeeModalOpen] = useState<boolean>(false);

  const methods = useForm<FormData>({ mode: "onChange" });
  const navigate = useNavigate();
  const location = useLocation();

  const { clinic } = useClinic();

  const { handleSubmit, reset, setValue, trigger } = methods;

  useEffect(() => {
    const getTreatment = async () => {
      if (treatmentId && clinic?.id) {
        try {
          const response = await TreatmentApi.getTreatmentOfClinic(
            clinic.id,
            treatmentId
          );
          setTreatment(response.data);
        } catch (err) {
          logger.error(err);
          setError(await getGeneralError(err));
        }
      }
    };

    if (!location?.state?.treatment) {
      void getTreatment();
    } else {
      setTreatment(location.state.treatment as TreatmentResponse);
    }
  }, [treatmentId, clinic, location]);

  useEffect(() => {
    setPageType(treatmentId && !!treatment ? PageType.edit : PageType.create);
  }, [treatment, treatmentId]);

  useEffect(() => {
    if (treatmentId && !!treatment && countryDetails) {
      const {
        name,
        usedItems: prevUsedItems,
        accountingCode,
        vat,
        price,
        description,
        advices,
        comment,
        complications,
        fees,
        species,
        suggestedMedicines,
      } = treatment;

      reset({
        newName: name,
        species:
          species && species.map((s) => ({ title: petSpecies[s], value: s })),
        description,
        suggestedMedicines,
        advices,
        complications,
        comment,
        accountingCode: countryDetails.accountingCodes.filter(
          (a: AccountingCodeResponse) => a.code === accountingCode
        ),
        price,
      });

      if (vat) {
        setValue("vat", vat);
        setVatValue(vat);
      }

      dispatch({ type: "fill", itemsToAdd: prevUsedItems });
      dispatchFee({ type: "fill", feesToAdd: fees });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentId, countryDetails, location, treatment]);

  useEffect(() => {
    const getServiceFees = async () => {
      if (clinic?.id) {
        try {
          const response = await ServiceFeeApi.getServiceFeesOfClinic(
            clinic.id,
            100
          );
          setServiceFees(response.data.elements);
        } catch (err) {
          logger.error(err);
          setError(await getGeneralError(err));
        }
      }
    };

    const getInventoryItems = async () => {
      if (clinic?.id) {
        try {
          const response = await InventoryItemApi.searchItemsOfClinic(
            clinic.id,
            {
              pageNumber: 0,
              pageSize: 100,
            }
          );
          setInventoryItems(response.data.elements);
        } catch (err) {
          logger.error(err);
        }
      }
    };

    const getCountryDetails = async () => {
      if (clinic?.id) {
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
        } catch (e) {
          logger.error(e);
          setError(await getGeneralError(e));
        } finally {
          setPageLoading(false);
        }
      }
    };

    void getCountryDetails();
    void getServiceFees();
    void getInventoryItems();
  }, [clinic, setPageLoading]);

  const postTreatment = async (postTr: NewTreatmentRequest) => {
    if (!clinic?.id) {
      return;
    }
    const previousState = pageState;
    setPageState(PageState.Loading);

    try {
      await TreatmentApi.addTreatment(clinic.id, postTr);
      navigate(`/clinic-settings/treatments`, {
        replace: true,
      });
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageState(previousState);
    }
  };

  const putTreatment = async (putTr: UpdateTreatmentRequest) => {
    const loadedTreatmentId = treatment?.id;

    if (!clinic?.id || !loadedTreatmentId) {
      return;
    }
    const previousState = pageState;
    setPageState(PageState.Loading);

    try {
      await TreatmentApi.updateTreatment(clinic.id, loadedTreatmentId, putTr);
      navigate(`/clinic-settings/treatments`, {
        replace: true,
      });
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageState(previousState);
    }
  };

  const generateRequest = (form: FormData): NewTreatmentRequest => ({
    name: form.newName,
    species: form.species?.map((o) => o.value.toString()),
    description: form.description,
    price: form.price,
    advices: form.advices,
    suggestedMedicines: form.suggestedMedicines,
    complications: form.complications,
    comment: form.comment,
    items: usedItems.map((item: TreatmentItemResponse) => ({
      itemId: item.item.id,
      quantity: item.quantity,
    })),
    fees: usedFees.map((fee: TreatmentFeeResponse) => ({
      feeId: fee.fee.id,
      quantity: fee.quantity,
    })),
    vat: form.vat,
    accountingCode:
      form.accountingCode?.length === 1
        ? form.accountingCode[0]?.code
        : undefined,
  });

  const post = (form: FormData) => {
    const treatmentRequest: NewTreatmentRequest = generateRequest(form);
    void postTreatment(treatmentRequest);
  };

  const put = (form: FormData) => {
    const treatmentRequest: NewTreatmentRequest = generateRequest(form);
    void putTreatment(treatmentRequest);
  };

  const onError = () => {
    setPageState(PageState.Basic);
  };

  const buttonStates = () => {
    switch (pageState) {
      case PageState.Loading:
        return <LoaderInline className="ml-auto" />;
      case PageState.Basic:
        return (
          <Button
            className="w-full ml-auto"
            onClick={() => {
              trigger([
                "newName",
                "description",
                "advices",
                "complications",
                "comment",
              ]).then((result: boolean) => {
                if (result) {
                  setPageState(PageState.Price);
                  window.scrollTo(0, 0);
                }
              });
            }}
          >
            {strings.continue}
          </Button>
        );
      default:
        if (pageType === PageType.edit) {
          return (
            <div className="w-full">
              <Button
                onClick={handleSubmit(put, onError)}
                className="w-full mb-2"
              >
                {strings.save}
              </Button>
              <Button
                onClick={handleSubmit(post, onError)}
                variant="link"
                className="w-full"
              >
                {strings.saveAsNew}
              </Button>
            </div>
          );
        }
        return (
          <Button onClick={handleSubmit(post, onError)} className="w-full">
            {strings.save}
          </Button>
        );
    }
  };

  return (
    <>
      <main className="main-signed-in">
        <section>
          <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
            <div className="w-full md:max-w-2xl md:tw-card space-y-6 md:p-8">
              <div className="flex justify-between space-x-4">
                <div>
                  <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                    {pageType === PageType.edit
                      ? strings.editTreatment
                      : strings.addTreatment}
                  </h1>
                  <div className="text-gray-500">{treatment?.name}</div>
                </div>
                <span className="text-gray-500 dark:text-gray-400">
                  {pageState === PageState.Basic ? 1 : 2}/2
                </span>
              </div>
              <AlertBox message={error} />
              <FormProvider {...methods}>
                <form className="space-y-6">
                  {pageState === PageState.Basic ? (
                    <BasicDetails
                      editedTreatment={treatment}
                      openAddFeeModal={() => {
                        setAddFeeModalOpen(true);
                      }}
                      openAddItemModal={() => {
                        setAddItemModalOpen(true);
                      }}
                      usedFees={usedFees}
                      usedItems={usedItems}
                    />
                  ) : (
                    <TreatmentPrice
                      countryDetails={countryDetails}
                      dispatch={dispatch}
                      dispatchFee={dispatchFee}
                      usedFees={usedFees}
                      usedItems={usedItems}
                      vat={vatValue}
                    />
                  )}
                  <div>{buttonStates()}</div>
                  {pageState === PageState.Basic ? (
                    <div>
                      <Link to={`/clinic-settings/${SettingsTabs.treatments}`}>
                        {strings.backToTreatments}
                      </Link>
                    </div>
                  ) : (
                    <Button
                      variant="link"
                      onClick={() => {
                        setPageState(PageState.Basic);
                      }}
                    >
                      {strings.back}
                    </Button>
                  )}
                </form>
              </FormProvider>
            </div>
          </div>
          <AddItemModal
            clinicId={clinic?.id}
            close={() => {
              setAddItemModalOpen(false);
            }}
            countryDetails={countryDetails}
            dispatch={dispatch}
            inventoryItems={inventoryItems}
            usedItems={usedItems}
            show={addItemModalOpen}
          />
          <AddFeeModal
            clinicId={clinic?.id}
            close={() => {
              setAddFeeModalOpen(false);
            }}
            countryDetails={countryDetails}
            dispatchFee={dispatchFee}
            usedFees={usedFees}
            serviceFees={serviceFees}
            show={addFeeModalOpen}
          />
        </section>
      </main>
    </>
  );
};

export default Loader(CreateTreatmentPage);
