/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const clinicAnalyticsEventTypeEn = {
  ACCOUNTING_CODES_SYNCED: "Accounting codes synced",
  ACCOUNTING_CODE_ADDED: "Accounting code added",
  ACCOUNTING_CODE_DELETED: "Accounting code deleted",
  ACCOUNTING_CODE_UPDATED: "Accounting code updated",
  ADDRESS_ADDED: "Address added",
  ADDRESS_DELETED: "Address deleted",
  CHARGE_PAYED: "Charge payed",
  CLINIC_DELETED: "Clinic deleted",
  CLINIC_DELETION_STARTED: "Clinic deletion started",
  CLINIC_FEES_UPDATED: "Clinic fees updated",
  CLINIC_REGISTERED: "Clinic registered",
  CLINIC_UPDATED: "Clinic updated",
  CREDIT_CARD_UPDATED: "Credit card updated",
  EMAIL_ADDED: "Email added",
  EMAIL_CONFIRMATION_RESENT: "Email confirmation resent",
  EMAIL_CONFIRMED: "Email confirmed",
  EMAIL_DELETED: "Email deleted",
  EMPLOYEE_ADDED: "Employee added",
  EMPLOYEE_DELETED: "Employee deleted",
  EMPLOYMENT_REQUEST_ADDED: "Employment request added",
  EMPLOYMENT_REQUEST_DELETED: "Employment request deleted",
  EMPLOYMENT_REQUEST_REJECTED: "Employment request rejected",
  FORTNOX_DISABLED: "Fortnox disabled",
  FORTNOX_ENABLED: "Fortnox enabled",
  FREE_TEXT_MEDICAL_RECORD_ADDED: "Free text medical record added",
  FREE_TEXT_MEDICAL_RECORD_DELETED: "Free text medical record deleted",
  GIRO_ACCOUNTS_UPDATED: "Giro accounts updated",
  INVENTORY_CATEGORY_ADDED: "Inventory category added",
  INVENTORY_CATEGORY_DELETED: "Inventory category deleted",
  INVENTORY_CATEGORY_UPDATED: "Inventory category updated",
  INVENTORY_ITEMS_USED: "Inventory items used",
  INVENTORY_ITEM_ADDED: "Inventory item added",
  INVENTORY_ITEM_DELETED: "Inventory item deleted",
  INVENTORY_ITEM_UPDATED: "Inventory item updated",
  INVENTORY_UPDATED: "Inventory updated",
  INVOICE_ADDED: "Invoice added",
  INVOICE_BILLED: "Invoice billed",
  INVOICE_CANCELLED: "Invoice cancelled",
  INVOICE_DELETED: "Invoice deleted",
  INVOICE_PAYED: "Invoice payed",
  LOGO_ADDED: "Logo added",
  LOGO_DELETED: "Logo deleted",
  MEDICAL_RECORD_CREATED: "Medical record created",
  MEDICAL_RECORD_MAIL_SENT: "Medical record mail sent",
  MEDICAL_RECORD_SIGNED: "Medical record signed",
  PAYMENT_ADDED: "Payment added",
  PAYMENT_METHOD_ADDED: "Payment method added",
  PAYMENT_METHOD_DELETED: "Payment method deleted",
  PAYMENT_METHOD_UPDATED: "Payment method updated",
  PAYMENT_REMINDER_SENT: "Payment reminder sent",
  PAYMENT_SYNCED: "Payment synced",
  PAYMENT_WITHDRAWN: "Payment withdrawn",
  PET_ADDED: "Pet added",
  PET_OWNER_ADDED: "Pet owner added",
  PET_OWNER_UPDATED: "Pet owner updated",
  PET_TRANSFERRED: "Pet transferred",
  PET_TRANSFER_STARTED: "Pet transfer started",
  PET_UPDATED: "Pet updated",
  PHARMACY_ADDED: "Pharmacy added",
  PHARMACY_DELETED: "Pharmacy deleted",
  PHONE_ADDED: "Phone added",
  PHONE_CONFIRMATION_RESENT: "Phone confirmation resent",
  PHONE_CONFIRMED: "Phone confirmed",
  PHONE_DELETED: "Phone deleted",
  PRESCRIPTION_CREATED: "Prescription created",
  PRIMARY_ADDRESS_CHANGED: "Primary address changed",
  PRIMARY_EMAIL_CHANGED: "Primary email changed",
  PRIMARY_PHONE_CHANGED: "Primary phone changed",
  RESERVATION_ADDED: "Reservation added",
  RESERVATION_DELETED: "Reservation deleted",
  RESERVATION_TYPE_ADDED: "Reservation type added",
  RESERVATION_TYPE_DELETED: "Reservation type deleted",
  RESERVATION_TYPE_UPDATED: "Reservation type updated",
  SERVICE_FEE_ADDED: "Service fee added",
  SERVICE_FEE_DELETED: "Service fee deleted",
  SERVICE_FEE_UPDATED: "Service fee updated",
  SHIFT_ADDED: "Shift added",
  SHIFT_DELETED: "Shift deleted",
  SHIFT_UPDATED: "Shift updated",
  SMS_SENT: "SMS sent",
  STRIPE_DISABLED: "Stripe disabled",
  STRIPE_ENABLED: "Stripe enabled",
  SUBSTITUTION_ADDED: "Substitution added",
  TODO_ADDED: "Todo added",
  TODO_DELETED: "Todo deleted",
  TODO_UPDATED: "Todo updated",
  TREATMENT_ADDED: "Treatment added",
  TREATMENT_DELETED: "Treatment deleted",
  TREATMENT_UPDATED: "Treatment updated",
};
