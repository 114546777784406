/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import { getGeneralError } from "../../../util/helperFunctions";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { AnalyticsEventResponse } from "../../../models/analytics/AnalyticsEventResponse";
import { usePager } from "../../../hooks/hooks";
import AnalyticsApi from "../../../api/AnalyticsApi";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { ChevronUp } from "../../../common/Icons/ChevronUp";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import moment from "moment";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";

interface Props {
  clinicId?: string;
  userId?: string;
  setPageLoading?: (isLoading: boolean) => void;
}

const AnalyticsEventsTable: React.FC<Props> = (props: Props) => {
  const { clinicId, userId, setPageLoading } = props;

  const [events, setEvents] = useState<PageResponse<AnalyticsEventResponse>>();
  const [isCollapseOpenList, setIsCollapseOpenList] = useState<{
    [key: string]: boolean;
  }>({});

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(50);

  const getEvents = async () => {
    setLoading(true);

    try {
      const response = await AnalyticsApi.getEvents({
        clinicId: clinicId,
        userId: userId,
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
      });
      setEvents(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      if (setPageLoading) setPageLoading(false);
    }
  };

  useEffect(() => {
    void getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId, pager.pageNumber, pager.pageSize]);

  return (
    <div>
      <PageableTable
        pager={pager}
        isLoading={loading}
        content={events}
        error={error}
      >
        <PageableTableHead>
          <tr>
            <th className="tw-th" />
            <th className="tw-th">{strings.date}</th>
            <th className="text-left tw-th">{strings.analyticsEventType}</th>
            <th className="text-left tw-th" hidden={clinicId !== undefined}>
              {strings.clinic}
            </th>
            <th className="text-left tw-th" hidden={userId !== undefined}>
              {strings.user}
            </th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {events &&
            events.elements.map((item, index: number) => (
              <Fragment key={index}>
                <tr
                  onClick={() => {
                    setIsCollapseOpenList({
                      ...isCollapseOpenList,
                      [item.id]:
                        !isCollapseOpenList[item.id] &&
                        !!item.params &&
                        Object.keys(item.params).length > 0,
                    });
                  }}
                >
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex">
                      <Button
                        variant="basic"
                        hidden={!item.params || !Object.keys(item.params)}
                      >
                        {isCollapseOpenList[item.id] ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </Button>
                    </div>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {moment(item.dateTime).format(
                      dateAndTime.momentDateTimeFormat
                    )}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <strong>{getStringFromEnumsOrReturn(item.type)}</strong>
                  </td>
                  <td
                    className="px-4 py-4 text-sm whitespace-nowrap"
                    hidden={clinicId !== undefined}
                  >
                    <Link to={`/management/clinics/${item.clinicId}`}>
                      {item.clinicName || item.clinicId}
                    </Link>
                  </td>
                  <td
                    className="px-4 py-4 text-sm whitespace-nowrap"
                    hidden={userId !== undefined}
                  >
                    <Link to={`/management/users/${item.userId}`}>
                      {item.userName || item.userId}
                    </Link>
                  </td>
                </tr>
                <tr
                  className="border-t-0"
                  hidden={!isCollapseOpenList[item.id]}
                >
                  <td />
                  <td
                    colSpan={4}
                    className="px-4 pb-4 text-sm whitespace-nowrap"
                  >
                    {item.params &&
                      Object.keys(item.params).map((key) => (
                        <div>
                          <strong>{key}: </strong>
                          {item.params && item.params[key].toString()}
                        </div>
                      ))}
                  </td>
                  <td />
                </tr>
              </Fragment>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default AnalyticsEventsTable;
