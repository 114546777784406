/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { TreatmentResponse } from "../../models/treatment/TreatmentResponse";
import NumberFormatter from "../../util/NumberFormatter";
import FeeSection from "./CreateAndEditTreatment/FeeSection";
import TreatmentCostCalculator from "../../util/TreatmentCostCalculator";
import InventoryItemSection from "./CreateAndEditTreatment/InventoryItemSection";
import BeforeDeleteModal from "../../components/Modals/BeforeDeleteModal";
import CostCalculator from "../../util/CostCalculator";
import TreatmentApi from "../../api/TreatmentApi";
import { getGeneralError } from "../../util/helperFunctions";
import { Trash } from "../../common/Icons/Trash";
import Button from "../../components/Button";
import { Pencil } from "../../common/Icons/Pencil";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  onRemove?: () => void;
  treatment: TreatmentResponse;
  canEdit: boolean;
}

const TreatmentDetails = (props: Props): ReactElement => {
  const { clinicId, countryDetails, treatment, onRemove, canEdit } = props;
  const [areYouSureModal, setAreYouSureModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>();

  const openBeforeDeleteModal = () => {
    setAreYouSureModal(true);
  };

  const deleteTreatment = async () => {
    if (!clinicId || !treatment || !onRemove) {
      return;
    }
    setIsDeleting(true);
    try {
      await TreatmentApi.removeTreatment(clinicId, treatment.id);
      setAreYouSureModal(false);
      onRemove();
    } catch (err) {
      setDeleteError(await getGeneralError(err));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div className="divide-y dark:divide-gray-700">
        {treatment.description ? (
          <div className="py-3 text-sm">
            <div className="font-bold break-all">{strings.description}</div>
            <div>{treatment.description}</div>
          </div>
        ) : (
          <></>
        )}
        {treatment.suggestedMedicines ? (
          <div className="py-3 text-sm">
            <div className="font-bold break-all">
              {strings.suggestedMedicines}
            </div>
            <div>{treatment.suggestedMedicines}</div>
          </div>
        ) : (
          <></>
        )}
        {treatment.advices ? (
          <div className="py-3 text-sm">
            <div className="font-bold break-all">{strings.advices}</div>
            <div>{treatment.advices}</div>
          </div>
        ) : (
          <></>
        )}
        {treatment.complications ? (
          <div className="py-3 text-sm">
            <div className="font-bold break-all">{strings.complications}</div>
            <div>{treatment.complications}</div>
          </div>
        ) : (
          <></>
        )}
        {treatment.comment ? (
          <div className="py-3 text-sm">
            <div className="font-bold break-all">{strings.comment}</div>
            <div>{treatment.comment}</div>
          </div>
        ) : (
          <></>
        )}
        <div className="py-3 text-sm font-bold">
          <div className="flex">
            <div className="">
              {strings.basePrice} ({strings.gross})
            </div>
            <div className="ml-auto">
              {NumberFormatter.formatPrice(
                CostCalculator.getGrossPriceOfItem({
                  price: treatment.price || 0,
                  vat: treatment.vat || 0,
                  quantity: 1,
                }, countryDetails?.roundingDecimalLength),
                countryDetails?.currency
              )}
            </div>
          </div>
        </div>
        <div className="py-3 text-sm font-bold">
          <div className="flex">
            <div className="">
              {strings.priceOfUsedItems} ({strings.gross})
            </div>
            <div className="ml-auto">
              {NumberFormatter.formatPrice(
                TreatmentCostCalculator.getGrossPriceOfItems2(
                  treatment.usedItems, countryDetails?.roundingDecimalLength
                ),
                countryDetails?.currency
              )}
            </div>
          </div>
          <InventoryItemSection countryDetails={countryDetails} usedItems={treatment.usedItems} />
        </div>
        <div className="py-3 text-sm font-bold">
          <div className="flex">
            <div>
              {strings.priceOfAppliedFees} ({strings.gross})
            </div>
            <div className="ml-auto">
              {NumberFormatter.formatPrice(
                TreatmentCostCalculator.getGrossPriceOfFees2(treatment.fees, countryDetails?.roundingDecimalLength),
                countryDetails?.currency
              )}
            </div>
          </div>
          <FeeSection countryDetails={countryDetails} fees={treatment.fees} />
        </div>
        <div className="py-3 text-sm font-bold">
          <div className="flex">
            <div>
              {strings.totalAmount} ({strings.gross})
            </div>
            <div className="ml-auto">
              {NumberFormatter.formatPrice(
                CostCalculator.getGrossPriceOfItem({
                  price: treatment.price || 0,
                  vat: treatment.vat || 0,
                  quantity: 1,
                }, countryDetails?.roundingDecimalLength) +
                  TreatmentCostCalculator.getGrossPriceOfItems2(
                    treatment.usedItems, countryDetails?.roundingDecimalLength
                  ) +
                  TreatmentCostCalculator.getGrossPriceOfFees2(treatment.fees, countryDetails?.roundingDecimalLength),
                  countryDetails?.currency
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <Button
          className="ml-auto mr-2"
          hidden={!canEdit}
          variant="icon"
          type="button"
          onClick={openBeforeDeleteModal}
        >
          <Trash />
        </Button>
        <Link
          to={`/treatments/${treatment.id}`}
          state={treatment}
          hidden={!canEdit}
        >
          <Button aria-label={strings.edit} className="tw-link" variant="icon">
            <span className="sr-only">{strings.edit}</span>
            <Pencil />
          </Button>
        </Link>
      </div>
      <BeforeDeleteModal
        open={areYouSureModal}
        returnYes={() => {
          void deleteTreatment();
        }}
        onHide={() => {
          setAreYouSureModal(false);
        }}
        title={strings.deleteTreatment}
        content={deleteError || strings.areYouSure}
        loading={isDeleting}
      />
    </>
  );
};

export default TreatmentDetails;

TreatmentDetails.defaultProps = {
  clinicId: undefined,
  currency: undefined,
  onRemove: undefined,
};
