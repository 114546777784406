/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { getDefaultVat, getGeneralError } from "../../../util/helperFunctions";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { NewInventoryItemRequest } from "../../../models/inventory/item/NewInventoryItemRequest";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import Button from "../../../components/Button";
import CopyItemToTreatmentModal from "../Modals/CopyItemToTreatmentModal";
import { InventoryItemResponse } from "../../../models/inventory/item/InventoryItemResponse";
import InventoryItemForm, {
  CreateInventoryItemForm,
} from "./InventoryItemForm";
import CountryApi from "../../../api/CountryApi";
import LoaderInline from "../../../components/LoaderInline";
import VaraItemModal from "../Modals/VaraItemModal";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { useClinic } from "../../../contexts/ClinicContext";

const defaultFormValues = (countryDetails?: CountryDetailsResponse) => ({
  categoryId: undefined,
  name: undefined,
  barcode: undefined,
  varaNplPackId: undefined,
  varaNvn: undefined,
  acquisitionPrice: undefined,
  acquisitionVat: getDefaultVat(countryDetails?.vatRates),
  sellingPrice: undefined,
  sellingVat: getDefaultVat(countryDetails?.vatRates),
  acquisitionUnit: InventoryItemUnit.PIECES,
  accountingCode: [],
  applicableToSpecificSpecies: false,
  species: [],
  lowLevel: 0,
  stock: undefined,
});

const InventoryItemPage: React.FC = () => {
  const [editItem, setEditItem] = useState<InventoryItemResponse>();
  const [loadingEditItem, setLoadingEditItem] = useState<boolean>(false);

  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [loadingCountryDetails, setLoadingCountryDetails] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const [addToTreatmentModal, setAddToTreatmentModal] =
    useState<boolean>(false);
  const [selectedTreatmentItem, setSelectedTreatmentItem] =
    useState<InventoryItemResponse>();

  const [useVara, setUseVara] = useState<boolean>(false);
  const [selectedVaraPackage, setSelectedVaraPackage] = useState<VaraItem>();

  const { itemId } = useParams<{
    itemId: string;
  }>();
  const methods = useForm<CreateInventoryItemForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(countryDetails),
  });
  const { handleSubmit, setValue } = methods;

  const { clinic } = useClinic();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        setLoadingCountryDetails(true);
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
          setValue("sellingVat", getDefaultVat(response.data?.vatRates) || 0);
          setValue(
            "acquisitionVat",
            getDefaultVat(response.data?.vatRates) || 0
          );
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setLoadingCountryDetails(false);
        }
      }
    };

    void getCountryDetails();
  }, [clinic]);

  useEffect(() => {
    const fillForm = (data: InventoryItemResponse) => {
      if (data.accountingCode) {
        setValue(
          "accountingCode",
          countryDetails?.accountingCodes.filter(
            (a) => a.code === data.accountingCode
          ) || []
        );
      }
      if (data.acquisitionPrice) {
        setValue("acquisitionPrice", data.acquisitionPrice);
      }
      if (data.acquisitionUnit) {
        setValue("acquisitionUnit", data.acquisitionUnit);
      }
      if (data.acquisitionVat) {
        setValue("acquisitionVat", data.acquisitionVat || 0);
      }
      setValue("barcode", data.barcode);
      setValue("categoryId", data.categoryId);
      setValue("lowLevel", data.lowLevel);
      setValue("name", data.name);
      if (data.sellingPrice) {
        setValue("sellingPrice", data.sellingPrice);
      }
      if (data.sellingVat) {
        setValue("sellingVat", data.sellingVat || 0);
      }
      if (data.species && data.species.length > 0) {
        setValue("applicableToSpecificSpecies", true);
        setValue(
          "species",
          data.species?.map((s: PetSpecies) => ({
            value: s,
            title: PetSpecies[s],
          })) || []
        );
      }
      setValue("stock", data.stock);
      setValue("varaNplPackId", data.varaNplPackId);
      setValue("varaNvn", data.varaNvn);
    };

    const getItem = async (id: string) => {
      setLoadingEditItem(true);
      try {
        const response = await InventoryItemApi.getItem(id);
        setEditItem(response.data);
        fillForm(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        countryDetails && setLoadingEditItem(false);
      }
    };

    if (itemId && !loadingCountryDetails) {
      void getItem(itemId);
    }
  }, [itemId, countryDetails, loadingCountryDetails, setValue]);

  const submitForm = async (data: CreateInventoryItemForm) => {
    if (!data.categoryId || !data.name) return;

    const speciesValues = data.species.map((i) => i.value);
    const requestSpecies = Object.keys(PetSpecies)
      .filter((i) => speciesValues.includes(i))
      .map((i) => i as PetSpecies);

    const request: NewInventoryItemRequest = {
      name: data.name,
      acquisitionPrice: data.acquisitionPrice,
      acquisitionVat: data.acquisitionVat,
      sellingPrice: data.sellingPrice,
      sellingVat: data.sellingVat,
      species: requestSpecies,
      acquisitionUnit: data.acquisitionUnit,
      accountingCode: data.accountingCode?.[0]?.code,
      barcode: data.barcode,
      varaNplPackId: data.varaNplPackId,
      varaNvn: data.varaNvn,
      lowLevel: data.lowLevel,
      stock: data.stock,
    };

    if (itemId && clinic?.id && editItem?.categoryId) {
      setLoading(true);
      try {
        const response = await InventoryItemApi.updateItemInfo(
          clinic?.id,
          editItem.categoryId,
          itemId,
          { ...request, categoryId: data.categoryId }
        );
        setSuccess(true);
        setSelectedTreatmentItem(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    } else if (clinic?.id) {
      setLoading(true);
      try {
        const response = await InventoryItemApi.createItem(
          clinic.id,
          data.categoryId,
          request
        );
        setSuccess(true);
        setSelectedTreatmentItem(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <form
            className="w-full md:max-w-lg md:tw-card space-y-6 md:p-8"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="flex justify-between space-x-4">
              <div>
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                  {itemId ? strings.editItem : strings.newItem}
                </h1>
                <div className="text-gray-500">{editItem?.name}</div>
              </div>
            </div>
            {success ? (
              <div className="space-y-6">
                <AlertBox
                  message={strings.savedSuccessfully}
                  type={AlertType.SUCCESS}
                />
                <div className="flex items-center">
                  <div className="font-bold pr-4">
                    {selectedTreatmentItem?.name}
                  </div>
                  <div className="ml-auto">
                    <Button
                      variant="primary-outline"
                      onClick={() => {
                        setAddToTreatmentModal(true);
                      }}
                    >
                      {strings.addToTreatment}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {loadingEditItem ? (
                  <LoaderInline />
                ) : (
                  <FormProvider {...methods}>
                    <InventoryItemForm
                      clinicId={clinic?.id}
                      countryDetails={countryDetails}
                      selectedVaraPackage={selectedVaraPackage}
                      useVara={() => {
                        setUseVara(true);
                      }}
                    />
                  </FormProvider>
                )}
                <AlertBox message={error} />
                <Button
                  loading={loading}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {itemId ? strings.save : strings.create}
                </Button>
              </>
            )}
            <div>
              <Link className="text-sm" to={`/inventory`}>
                {strings.inventory}
              </Link>
            </div>
          </form>
        </div>
        <CopyItemToTreatmentModal
          clinicId={clinic?.id}
          isOpen={addToTreatmentModal}
          item={selectedTreatmentItem}
          close={() => {
            setAddToTreatmentModal(false);
          }}
        />
        <VaraItemModal
          close={() => {
            setUseVara(false);
          }}
          isOpen={useVara}
          returnSelectedVaraPackage={(p) => {
            setSelectedVaraPackage(p);
            setUseVara(false);
          }}
        />
      </section>
    </main>
  );
};

export default InventoryItemPage;
