/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import TreatmentList from "./TreatmentList";
import { strings } from "../../common/Strings/Strings";
import { canManageClinic, getGeneralError } from "../../util/helperFunctions";
import { TreatmentResponse } from "../../models/treatment/TreatmentResponse";
import TreatmentApi from "../../api/TreatmentApi";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import CountryApi from "../../api/CountryApi";
import LoaderInline from "../../components/LoaderInline";
import AlertBox from "../../components/AlertBox";
import SelectSpecies from "../../components/ReactHookFormFields/Specific/SelectSpecies";
import { useDeepLinking } from "../../hooks/DeepLinking";
import PageSizeSelect from "../../components/PageSizeSelect";
import Paging from "../../components/Paging";
import Field from "../../components/ReactHookFormFields/General/Field";
import { SearchTreatments } from "../../models/apiparams/SearchTreatments";
import DelayedOnChangeInput from "../../components/ReactHookFormFields/Specific/DelayedOnChangeInput";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import Button from "../../components/Button";
import EmptyListText from "../../components/EmptyListText";
import { getActiveUserId } from "../../util/LocalStorageVariables";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";

interface Props {
  clinic: ClinicResponse;
  isActiveTab: boolean;
}

const TreatmentPage: React.FC<Props> = (props: Props) => {
  const { isActiveTab, clinic } = props;

  const [treatments, setTreatments] = useState<TreatmentResponse[]>([]);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalTreatments, setTotalTreatments] = useState<number>(0);

  const [loaded, setLoaded] = useState<boolean>(false);
  const { control, register, getValues, setValue, reset } =
    useForm<SearchTreatments>({
      defaultValues: { pageNumber: 0, pageSize: 10 },
    });

  const [urlState, setUrlState, isUrlStateDeserialized] =
    useDeepLinking<SearchTreatments>();
  const [isUrlStateLoaded, setUrlStateLoaded] = useState<boolean>(false);

  const [reloadTreatments, setReloadTreatments] = useState<boolean>(false);

  const triggerReload = () => {
    setReloadTreatments(!reloadTreatments);
  };

  const getTreatmentsOfClinic = async () => {
    try {
      setLoaded(false);
      setError(null);
      const request = getValues();
      const response = await TreatmentApi.getTreatmentsOfClinic(
        clinic.id,
        request
      );
      setTreatments(response.data.elements);
      setTotalPages(response.data.totalPages);
      setTotalTreatments(response.data.totalElements);
      setUrlState(request);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!isActiveTab) {
      return;
    }

    if (clinic && isUrlStateDeserialized && isUrlStateLoaded) {
      void getTreatmentsOfClinic();
    }

    /* eslint-disable-next-line */
  }, [isActiveTab, isUrlStateDeserialized, reloadTreatments, isUrlStateLoaded]);

  useEffect(() => {
    const getCountryDetails = async () => {
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinic.id);
        setCountryDetails(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      }
    };

    if (!isActiveTab) {
      return;
    }

    void getCountryDetails();
  }, [isActiveTab, clinic]);

  const deleteTreatment = (treatmentId: string) => {
    const list = treatments.filter((t) => t.id !== treatmentId);
    setTreatments(list);
  };

  useEffect(() => {
    if (isUrlStateDeserialized) {
      if (urlState) {
        reset(urlState);
      }
      setUrlStateLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlState, isUrlStateDeserialized]);

  const currentUserId = getActiveUserId();
  const canEdit =
    canManageClinic(clinic) ||
    clinic.vets.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.assistants.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.authorizedAssistants.find((i) => i.userId === currentUserId) !==
      undefined;

  return (
    <>
      <div className="w-full md:tw-card md:max-w-4xl space-y-6 md:p-8">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
            {strings.treatments}
            <div className="ml-auto">
              <LoaderInline hidden={loaded} />
            </div>
          </h2>
        </div>
        <AlertBox message={error} className="mb-3" />
        <div>{strings.treatmentToolTip}</div>
        <div className="flex space-x-4">
          <DelayedOnChangeInput
            name="name"
            label={strings.search}
            register={register}
            onChange={() => {
              setValue("pageNumber", 0);
              triggerReload();
            }}
          />
          <div>
            <SelectSpecies
              control={control}
              onChange={() => {
                setValue("pageNumber", 0);
                triggerReload();
              }}
            />
          </div>
          <Field
            className="hidden"
            name="pageSize"
            register={register}
            type="number"
          />
          <Field
            className="hidden"
            name="pageNumber"
            register={register}
            type="number"
          />
        </div>
        <div hidden={!canEdit}>
          <Link to={`/treatments`}>
            <Button variant="link">
              <div className="flex">
                <PlusIcon /> <span>{strings.addTreatment}</span>
              </div>
            </Button>
          </Link>
        </div>
        {!loaded ? (
          <div className="tw-table-container py-3.5 px-4">
            <LoaderInline />
          </div>
        ) : (
          <>
            {treatments.length === 0 ? (
              <div className="tw-table-container py-3.5 px-4">
                <EmptyListText />
              </div>
            ) : (
              <TreatmentList
                clinicId={clinic.id}
                treatments={treatments}
                onRemove={deleteTreatment}
                countryDetails={countryDetails}
                canEdit={canEdit}
              />
            )}
          </>
        )}
        <div className="flex items-center flex-wrap">
          <PageSizeSelect
            pageSize={getValues("pageSize") || 50}
            setPageSize={(newPageSize: number) => {
              setValue("pageSize", newPageSize);
              triggerReload();
            }}
            totalPages={totalPages}
            totalResults={totalTreatments}
          />
          <div className="ml-auto">
            <Paging
              currentPage={getValues("pageNumber") || 0}
              totalPages={totalPages}
              selectPage={(newPageNumber: number) => {
                setValue("pageNumber", newPageNumber);
                triggerReload();
              }}
            />
          </div>
        </div>

        <div hidden={!canEdit}>
          <Link to={`/treatments`}>
            <Button variant="link">
              <div className="flex">
                <PlusIcon /> <span>{strings.addTreatment}</span>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TreatmentPage;
