/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { PageProps } from "../../models/PageProps";
import { useUser } from "../../contexts/UserContext";
import { Loader } from "../../components/Loader";
import AlertBox from "../../components/AlertBox";
import { useChosenRole } from "../Main/Onboarding/ChooseRole";

export const SiteManagerPage = (
  WrappedComponent: (props: PageProps) => any,
  title: string
) => {
  function SiteManagerComponent(props: PageProps) {
    const { user } = useUser();
    const { setPageLoading } = props;

    useChosenRole("manager");

    if (!user.isSiteManager) {
      setPageLoading(false);
      return <AlertBox message="Forbidden" className="m-3" closeAble={false} />;
    }

    return (
      <main className="main-default">
        <section>
          <div className="fix-container mt-5">
            <div className="background-rectangle" style={{ minHeight: "90vh" }}>
              <div className="justify-center">
                <div className="admin-title text-gray-600 dark:text-gray-300">
                  {title}
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12">
                  <WrappedComponent
                    {...props}
                    setPageLoading={setPageLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

  return Loader(SiteManagerComponent);
};
