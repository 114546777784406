/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import {
  getDefaultVat,
  getGeneralError,
  getVatOptions,
} from "../../../util/helperFunctions";
import { InventoryCategoryResponse } from "../../../models/inventory/category/InventoryCategoryResponse";
import { generateAnimalOptionsGeneral } from "../../../common/AnimalSpecies/SpeciesFunctions";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { Option } from "../../../models/Option";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import Field from "../../../components/ReactHookFormFields/General/Field";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import PriceInputs from "../../../components/ReactHookFormFields/Specific/PriceInputs";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import InventoryCategoryApi from "../../../api/InventoryCategoryApi";
import { positiveOrZeroDouble } from "../../../util/Validations";
import LoaderInline from "../../../components/LoaderInline";
import CreateCategoryModal from "../Modals/CreateCategoryModal";
import AlertBox from "../../../components/AlertBox";
import ControlledSelect from "../../../components/ReactHookFormFields/General/Controlled/ControlledSelect";

export interface CreateInventoryItemForm {
  accountingCode: AccountingCodeResponse[];
  acquisitionPrice: number;
  acquisitionUnit: InventoryItemUnit;
  acquisitionVat: number;
  applicableToSpecificSpecies: boolean;
  barcode?: string;
  categoryId?: string;
  lowLevel?: number;
  name?: string;
  sellingPrice: number;
  sellingVat: number;
  species: Option[];
  stock?: number;
  varaNplPackId?: string;
  varaNvn?: string;
}

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  selectedVaraPackage?: VaraItem;
  useVara(): void;
}

const InventoryItemForm: React.FC<Props> = ({
  clinicId,
  countryDetails,
  selectedVaraPackage,
  useVara,
}: Props) => {
  const [categories, setCategories] = useState<InventoryCategoryResponse[]>([]);
  const [newCategoryModal, setNewCategoryModal] = useState<boolean>(false);
  const [categoryLoaded, setCategoryLoaded] = useState<boolean>(false);
  const [reloadCategories, setReloadCategories] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const speciesOptions = generateAnimalOptionsGeneral();
  const {
    watch,
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<CreateInventoryItemForm>();

  const [acquisitionVat, applicableToSpecificSpecies, sellingVat] = watch([
    "acquisitionVat",
    "applicableToSpecificSpecies",
    "sellingVat",
  ]);

  useEffect(() => {
    const getInventoryCategories = async () => {
      if (!clinicId) return;
      try {
        setCategoryLoaded(false);
        const response = await InventoryCategoryApi.getAllCategories(clinicId);

        response.data.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));

        response.data.forEach((cat) => {
          cat.items.sort((item1, item2) =>
            item1.name.localeCompare(item2.name)
          );
        });

        setCategories(
          response.data.map((i) => {
            i.name = strings[i.name] || i.name;
            return i;
          })
        );
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setCategoryLoaded(true);
      }
    };

    void getInventoryCategories();
  }, [clinicId, reloadCategories]);

  const findUnitFromVara = useCallback((): InventoryItemUnit => {
    const unitText = selectedVaraPackage?.unit?.toLowerCase();

    if (unitText === "ml") {
      return InventoryItemUnit.MILLILITERS;
    }
    if (unitText === "g") {
      return InventoryItemUnit.GRAMS;
    }
    if (unitText === "l") {
      return InventoryItemUnit.LITERS;
    }
    if (unitText === "kg") {
      return InventoryItemUnit.KILOGRAMS;
    }

    return InventoryItemUnit.PIECES;
  }, [selectedVaraPackage]);

  useEffect(() => {
    if (selectedVaraPackage) {
      setValue("name", selectedVaraPackage.name);
      setValue("barcode", selectedVaraPackage.barcode ?? "");
      setValue("varaNplPackId", selectedVaraPackage.varaNplPackId ?? "");
      setValue("varaNvn", selectedVaraPackage.varaNvn ?? "");
      setValue("acquisitionUnit", findUnitFromVara());
    }
  }, [findUnitFromVara, selectedVaraPackage, setValue]);

  return (
    <>
      <div>
        {categoryLoaded ? (
          <div>
            <div className="relative w-full">
              <label htmlFor="search">{strings.category}</label>
              <div
                role="button"
                className="absolute right-0 top-0 tw-link text-sm"
                onClick={() => {
                  setNewCategoryModal(true);
                }}
                style={{ cursor: "pointer" }}
                tabIndex={0}
              >
                {strings.createCategory}
              </div>
            </div>
            <Select
              isChooseOption={false}
              label={strings.category}
              labelOff
              name="categoryId"
              options={categories.map((i) => ({
                title: strings[i.name] ?? i.name,
                value: i.id,
              }))}
              register={register}
              error={errors.categoryId}
              readOnly={categories.length === 1}
              required={categories.length !== 1}
            />
          </div>
        ) : (
          <LoaderInline />
        )}
      </div>
      <div>
        <div className="relative w-full">
          <label htmlFor="search">{strings.name}</label>
          <div
            role="button"
            className="absolute right-0 top-0 tw-link text-sm"
            onClick={useVara}
            style={{ cursor: "pointer" }}
            tabIndex={0}
          >
            {strings.addItemFromVara}
          </div>
        </div>
        <Field
          label={strings.name}
          labelOff
          name="name"
          register={register}
          required
        />
      </div>
      <CheckBox
        name="applicableToSpecificSpecies"
        id="applicableToSpecificSpecies"
        label={strings.applicableToSpecificSpecies}
        register={register}
        error={errors.applicableToSpecificSpecies}
      />
      {applicableToSpecificSpecies && (
        <div>
          <CombinedSelect
            allowNew={false}
            multiple
            name="species"
            labelKey="title"
            control={control}
            options={speciesOptions}
            placeholder={strings.chooseSpecies}
            clearButton
          />
        </div>
      )}
      <Field
        name="barcode"
        label={strings.barcode}
        register={register}
        autoComplete={AutoCompleteOptions.off}
        error={errors.barcode}
        optional
      />
      <div className="columns-2">
        <Field
          autoComplete={AutoCompleteOptions.off}
          className="mb-0"
          error={errors.varaNplPackId}
          label={strings.nplPackId}
          name="varaNplPackId"
          optional
          register={register}
        />
        <Field
          className="mb-0"
          name="varaNvn"
          label={strings.nvn}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={errors.varaNvn}
          optional
        />
      </div>
      <div className="flex space-x-4 items-end">
        <div className="w-3/4">
          <PriceInputs
            control={control}
            name="acquisitionPrice"
            className="mb-0"
            currency={countryDetails?.currency}
            label={strings.acquisitionPrice}
            required
            roundingDecimalLength={countryDetails?.roundingDecimalLength}
            vat={acquisitionVat}
          />
        </div>
        <div className="w-1/4">
          <ControlledSelect
            control={control}
            name="acquisitionVat"
            options={getVatOptions(countryDetails?.vatRates)}
            autoComplete={AutoCompleteOptions.off}
            className="mb-0"
            defaultValue={getDefaultVat(countryDetails?.vatRates)}
            fieldOptions={{ valueAsNumber: true }}
            isChooseOption={false}
            label={strings.acquisitionVat}
            required
            value={getValues("acquisitionVat")}
          />
        </div>
      </div>
      <div className="flex space-x-4 items-end">
        <div className="w-3/4">
          <PriceInputs
            control={control}
            name="sellingPrice"
            className="mb-0"
            currency={countryDetails?.currency}
            label={strings.sellingPrice}
            required
            roundingDecimalLength={countryDetails?.roundingDecimalLength}
            vat={sellingVat}
          />
        </div>
        <div className="w-1/4">
          <ControlledSelect
            control={control}
            name="sellingVat"
            options={getVatOptions(countryDetails?.vatRates)}
            autoComplete={AutoCompleteOptions.off}
            className="mb-0"
            defaultValue={getDefaultVat(countryDetails?.vatRates)}
            fieldOptions={{ valueAsNumber: true }}
            isChooseOption={false}
            label={strings.sellingVat}
            required
            value={getValues("sellingVat")}
          />
        </div>
      </div>
      <CombinedSelect
        allowNew={false}
        multiple={false}
        label={strings.accountingCode}
        name="accountingCode"
        labelKey="name"
        control={control}
        options={countryDetails?.accountingCodes ?? []}
        placeholder={strings.accountingCode}
        clearButton
      />
      <div className="flex space-x-4 items-end">
        <Select
          isChooseOption
          defaultValue={findUnitFromVara()}
          label={strings.acquisitionUnit}
          name="acquisitionUnit"
          options={Object.keys(InventoryItemUnit).map((i) => ({
            title: quantityUnits[i],
            value: i,
          }))}
          register={register}
          error={errors.acquisitionUnit}
          required
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.lowLevel}
          fieldOptions={{
            pattern: {
              value: positiveOrZeroDouble.matchRegexp,
              message: "",
            },
          }}
          label={strings.lowLevel}
          name="lowLevel"
          register={register}
          required
          type="number"
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.stock}
          fieldOptions={{
            pattern: {
              value: positiveOrZeroDouble.matchRegexp,
              message: "",
            },
          }}
          label={strings.stock}
          name="stock"
          register={register}
          type="number"
        />
      </div>
      <AlertBox message={error} />
      <CreateCategoryModal
        clinicId={clinicId}
        isOpen={newCategoryModal}
        close={() => {
          setNewCategoryModal(false);
        }}
        reloadCategories={() => {
          setReloadCategories((prev) => !prev);
        }}
      />
    </>
  );
};

export default InventoryItemForm;
