/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { Loader } from "../../components/Loader";
import { useClinic } from "../../contexts/ClinicContext";
import { PageProps } from "../../models/PageProps";
import WorkerInvoices from "../Accounting/WorkerInvoices";
import AlertBox from "../../components/AlertBox";

function ClinicInvoices({ setPageLoading }: PageProps) {
  const { clinic, fetchClinicError } = useClinic();

  useEffect(() => {
    setPageLoading(!clinic);
  }, [clinic, setPageLoading]);

  if (fetchClinicError) {
    return (
      <div className="background-rectangle">
        <div className="container">
          <AlertBox message={fetchClinicError} className="my-3"/>
        </div>
      </div>
    );
  }

  if (clinic) {
    return (
      <main className="main-signed-in" id="settings-tabs">
        <section>
          <div className="px-4 lg:px-6 py-6">
            <WorkerInvoices clinic={clinic} />
          </div>
        </section>
      </main>
    );
  }

  return <></>;
}

export default Loader(ClinicInvoices);
