/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError, getVatOptions } from "../../util/helperFunctions";
import ServiceFeeApi from "../../api/ServiceFeeApi";
import { ServiceFeeRequest } from "../../models/servicefee/ServiceFeeRequest";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import { AccountingCodeResponse } from "../../models/accounting/AccountingCodeResponse";
import CloseButton from "../../components/CloseButton";
import Field from "../../components/ReactHookFormFields/General/Field";
import AlertBox from "../../components/AlertBox";
import CombinedSelect from "../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { ServiceFeeResponse } from "../../models/servicefee/ServiceFeeResponse";
import Button from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import PriceInputs from "../../components/ReactHookFormFields/Specific/PriceInputs";
import Select from "../../components/ReactHookFormFields/General/Select/Select";

interface Props {
  addNewFee: (newFee: ServiceFeeResponse) => void;
  clinicId: string;
  close: () => void;
  countryDetails?: CountryDetailsResponse;
  fee?: ServiceFeeResponse;
  isOpen: boolean;
}

export interface ServiceFeeFormData {
  accountingCode: AccountingCodeResponse[];
  price: number;
  name: string;
  vat: number;
}

const NewServiceFeeModal: React.FC<Props> = (props: Props) => {
  const { addNewFee, clinicId, isOpen, close, countryDetails, fee } = props;

  const [error, setError] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useForm<ServiceFeeFormData>({
    defaultValues: { accountingCode: [], price: 0, name: "" },
  });
  const vat = watch("vat");

  const handleClose = () => {
    reset();
    close();
  };

  useEffect(() => {
    if(countryDetails){
      setValue("vat", countryDetails.vatRates[0]);
    }
  }, [countryDetails]);

  useEffect(() => {
    if (fee) {
      setValue("name", fee.name);
      setValue("price", fee.price);
      setValue("vat", fee.vat);
      setValue(
        "accountingCode",
        countryDetails?.accountingCodes?.filter(
          (a) => a.code === fee.accountingCode
        ) || []
      );
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fee]);

  const submitForm = async (data: ServiceFeeFormData) => {
    setSubmitLoading(true);

    const request: ServiceFeeRequest = {
      ...data,
      accountingCode:
        data?.accountingCode?.length > 0
          ? data.accountingCode[0].code
          : undefined,
    };
    try {
      if (fee) {
        const response = await ServiceFeeApi.updateServiceFee(
          clinicId,
          fee.id,
          request
        );
        addNewFee(response.data);
      } else {
        const response = await ServiceFeeApi.addServiceFee(clinicId, request);
        addNewFee(response.data);
      }
      reset();
      handleClose();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal handleClose={handleClose} show={isOpen}>
      <Modal.Header title={fee ? strings.edit : strings.addFee} />
      <Modal.Body>
        <form className="space-y-4" id="serviceFeeForm">
          <Field
            error={errors.name}
            label={strings.name}
            name="name"
            register={register}
            required
          />
          <CombinedSelect
            allowNew={false}
            control={control}
            labelKey="name"
            label={strings.accountingCode}
            name="accountingCode"
            options={countryDetails?.accountingCodes || []}
            optional
          />
          <Select
            error={errors.vat}
            isChooseOption={false}
            name="vat"
            options={getVatOptions(countryDetails?.vatRates)}
            register={register}
            fieldOptions={{ valueAsNumber: true }}
            label={strings.vatAsTax}
            required
            width="100px"
          />
          <PriceInputs
            control={control}
            name="price"
            label={strings.price}
            currency={countryDetails?.currency}
            vat={vat}
            required
            roundingDecimalLength={countryDetails?.roundingDecimalLength}
          />
          <AlertBox message={error} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitLoading}
          form="serviceFeeForm"
          loading={submitLoading}
          onClick={handleSubmit(submitForm)}
          type="submit"
        >
          {fee ? strings.save : strings.create}
        </Button>
        <CloseButton onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default NewServiceFeeModal;

NewServiceFeeModal.defaultProps = {
  countryDetails: undefined,
};
