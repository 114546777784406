/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { PhoneXMark } from "../../common/Icons/PhoneXMark";
import { Microphone } from "../../common/Icons/Microphone";
import { UserPlus } from "../../common/Icons/UserPlus";
import { VideoCamera } from "../../common/Icons/VideoCamera";
import { Check } from "../../common/Icons/Check";
import { VideoCameraOff } from "../../common/Icons/VideoCameraOff";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import { strings } from "../../common/Strings/Strings";
import {
  copyAddressToClipboard,
  getMicrophoneErrorString,
  getWebcamErrorString,
  MediaFeatures,
} from "./Utils";

interface Props {
  camEnabled: boolean;
  disconnectFromRoom: () => void;
  mediaFeatures: MediaFeatures;
  micEnabled: boolean;
  microphoneMissing: boolean;
  toggleCam: () => void;
  toggleMic: () => void;
  webcamMissing: boolean;
}
const CallControls: React.FC<Props> = ({
  camEnabled,
  disconnectFromRoom,
  mediaFeatures,
  micEnabled,
  microphoneMissing,
  toggleCam,
  toggleMic,
  webcamMissing,
}) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleLinkCopyClick = () => {
    copyAddressToClipboard();
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <div className="video-consultation-btn-group">
      {/* Copy Meeting Link Button */}
      <Tooltip
        content={
          linkCopied
            ? strings.linkCopied
            : strings.copyMeetingLinkCopiedToClipboard
        }
        placement="top-start"
      >
        <Button onClick={handleLinkCopyClick} variant="video-consultation">
          {linkCopied ? (
            <Check className="w-8 h-8" variant="outline" />
          ) : (
            <UserPlus className="w-8 h-8" variant="outline" />
          )}
        </Button>
      </Tooltip>

      {/* Toggle Camera Button */}
      <div className="relative">
        <Button
          disabled={webcamMissing}
          onClick={() => toggleCam()} // Don't simplify/remove arrow function!
          variant="video-consultation-gray"
        >
          {camEnabled ? (
            <VideoCamera className="w-8 h-8" variant="outline" />
          ) : (
            <VideoCameraOff className="w-8 h-8" variant="outline" />
          )}
        </Button>
        {webcamMissing && (
          <div className="absolute top-0 -right-2">
            <Tooltip
              content={getWebcamErrorString(mediaFeatures)}
              placement="top"
            >
              <ExclamationMark
                className="ml-1 text-orange-700 dark:text-orange-400"
                variant="outline"
              />
            </Tooltip>
          </div>
        )}
      </div>

      {/* Toggle Microphone Button */}
      <div className="relative">
        <Button
          disabled={microphoneMissing}
          onClick={() => toggleMic()} // Don't simplify/remove arrow function!
          variant="video-consultation-gray"
        >
          {micEnabled ? (
            <Microphone className="w-8 h-8" variant="outline" />
          ) : (
            <MicrophoneOff className="w-8 h-8" variant="outline" />
          )}
        </Button>
        {microphoneMissing && (
          <div className="absolute top-0 -right-2">
            <Tooltip
              content={getMicrophoneErrorString(mediaFeatures)}
              placement="top"
            >
              <ExclamationMark
                className="ml-1 text-orange-700 dark:text-orange-400"
                variant="outline"
              />
            </Tooltip>
          </div>
        )}
      </div>

      {/* Disconnect Button */}
      <Button onClick={disconnectFromRoom} variant="video-consultation-red">
        <PhoneXMark className="w-8 h-8" variant="outline" />
      </Button>
    </div>
  );
};

export default CallControls;
