/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import LoaderInline from "../../../../../components/LoaderInline";
import BeforeDeleteModal from "../../../../../components/Modals/BeforeDeleteModal";
import FortnoxIntegrationModal from "./FortnoxIntegrationModal";
import { FortnoxIntegrationRequest } from "../../../../../models/clinic/FortnoxIntegrationRequest";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../../util/helperFunctions";
import FortnoxAuthenticationError from "../../../../Accounting/InvoiceDetails/FortnoxAuthenticationError";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import FortnoxApi from "../../../../../api/FortnoxApi";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";

export type PrevStateSetter = (prevState: ClinicResponse) => ClinicResponse;

interface Props {
  clinic: ClinicResponse;
  setClinic: (clinic: PrevStateSetter) => void;
}

const FortnoxIntegration: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [showAddFortnoxModal, setShowAddFortnoxModal] =
    useState<boolean>(false);
  const [showDisableFortnoxModal, setShowDisableFortnoxModal] =
    useState<boolean>(false);

  const [disableFortnoxLoading, setDisableFortnoxLoading] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertClass, setAlertClass] = useState<AlertType | null>(null);

  const openAddFortnoxModal = () => setShowAddFortnoxModal(true);
  const closeAddFortnoxModal = () => setShowAddFortnoxModal(false);
  const closeDisableFortnoxModal = () => setShowDisableFortnoxModal(false);

  const disableFortnoxIntegration = async () => {
    setDisableFortnoxLoading(true);
    const request: FortnoxIntegrationRequest = {
      enabling: false,
    };

    try {
      await FortnoxApi.enableFortnox(clinic.id, request);
      setAlertMessage(strings.savedSuccessfully);
      setAlertClass(AlertType.SUCCESS);
      setClinic((prevState) => {
        const newState = prevState;
        newState.fortnoxEnabled = false;
        return newState;
      });
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertClass(AlertType.ERROR);
    } finally {
      setDisableFortnoxLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.fortnoxInvoiceSystem}</p>
          <div className="ml-auto">
            <Button
              onClick={openAddFortnoxModal}
              hidden={!canManageClinic(clinic)}
              variant="icon"
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="p-4 space-y-4">
          <div>{strings.fortnoxToolTip}</div>
          {!clinic.fortnoxEnabled && (
            <AlertBox
              type={AlertType.INFO}
              message={`${strings.state}: ${strings.notIntegrated}`}
              closeAble={false}
            />
          )}
          {clinic.fortnoxEnabled && !clinic.fortnoxAuthenticationRequired && (
            <AlertBox
              type={AlertType.SUCCESS}
              message={`${strings.state}: ${strings.integrated}`}
              closeAble={false}
            />
          )}
          {clinic.fortnoxEnabled && clinic.fortnoxAuthenticationRequired && (
            <FortnoxAuthenticationError
              clinicId={clinic.id}
              title={strings.failedToConnectToFortnox}
            />
          )}
          {alertClass !== null && !disableFortnoxLoading ? (
            <AlertBox
              type={alertClass}
              message={alertMessage}
              className="mt-3"
            />
          ) : null}
        </div>
        <LoaderInline hidden={!disableFortnoxLoading} className="m-5" />
        {!clinic.fortnoxEnabled && canManageClinic(clinic) && (
          <div className="hr-on-top d-flex p-3">
            <Button
              variant="primary"
              className="ml-auto"
              onClick={openAddFortnoxModal}
            >
              {strings.addIntegration}
            </Button>
          </div>
        )}
      </div>
      <FortnoxIntegrationModal
        clinic={clinic}
        show={showAddFortnoxModal}
        onHide={closeAddFortnoxModal}
      />
      <BeforeDeleteModal
        open={showDisableFortnoxModal}
        title={strings.fortnoxIntegration}
        content={strings.disableFortnoxConfirmation}
        returnYes={() => {
          void disableFortnoxIntegration();
          closeDisableFortnoxModal();
        }}
        onHide={closeDisableFortnoxModal}
        loading={disableFortnoxLoading}
      />
    </>
  );
};

export default FortnoxIntegration;
