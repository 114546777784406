/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const userAnalyticsEventTypeSv = {
  ADDRESS_ADDED: "Address added",
  ADDRESS_DELETED: "Address deleted",
  EMAIL_ADDED: "Email added",
  EMAIL_CONFIRMATION_RESENT: "Email confirmation resent",
  EMAIL_DELETED: "Email deleted",
  LOGIN_SUCCEEDED: "Login succeeded",
  PASSWORD_CHANGED: "Password changed",
  PHONE_ADDED: "Phone added",
  PHONE_CONFIRMATION_RESENT: "Phone confirmation resent",
  PHONE_CONFIRMED: "Phone confirmed",
  PHONE_DELETED: "Phone deleted",
  PHONE_UPDATED: "Phone updated",
  PIN_CODE_CHANGED: "Pin code changed",
  PIN_CODE_CONFIRMED: "Pin code confirmed",
  PREFERENCES_ADDED: "Preferences added",
  PRIMARY_ADDRESS_CHANGED: "Primary address changed",
  PRIMARY_EMAIL_CHANGED: "Primary email changed",
  PRIMARY_PHONE_CHANGED: "Primary phone changed",
  SOCIAL_ACCOUNT_LINKED: "Social account linked",
  USER_DELETED: "User deleted",
  USER_REGISTERED: "User registered",
  USER_UPDATED: "User updated",
  VET_DOCUMENT_ADDED: "Vet document added",
  VET_DOCUMENT_DELETED: "Vet document deleted",
  VET_REGISTRATION_NUMBER_ADDED: "Vet registration number added",
  VET_REGISTRATION_NUMBER_DELETED: "Vet registration number deleted",
  VET_REGISTRATION_NUMBER_UPDATED: "Vet registration number updated",
};
