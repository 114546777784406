/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { PaymentMethod } from "../../../models/costaccounting/PaymentMethod";
import Field from "../../../components/ReactHookFormFields/General/Field";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import SelectCountry from "../../../components/ReactHookFormFields/Specific/SelectCountry";
import { PaymentMethodResponse } from "../../../models/payment/PaymentMethodResponse";
import { useClinic } from "../../../contexts/ClinicContext";
import { defaultCountryCodeOfClinic } from "../../../common/DefaultCountry";

interface Props {
  namePostFix?: string;
  paymentMethod?: PaymentMethodResponse;
  readOnly?: boolean;
}

const BillingForm = ({
  namePostFix,
  paymentMethod,
  readOnly = false,
}: Props): ReactElement => {
  const { clinic } = useClinic();

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const defaultCountryCode = defaultCountryCodeOfClinic(clinic);

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <Field
          register={register}
          name={`name${namePostFix || ""}`}
          error={errors[`name${namePostFix || ""}`]}
          fieldOptions={{
            validate: {
              isNotRequired: (value: string) => {
                if (!getValues("isBillingInfoTheSame")) {
                  if (!!namePostFix) {
                    return value?.length > 0;
                  } else {
                    return true;
                  }
                }
                if (!namePostFix) {
                  return value?.length > 0;
                } else {
                  return true;
                }
              },
            },
          }}
          label={strings.name}
          readOnly={readOnly}
          showRequired={!readOnly}
        />
        <div className="hidden">
          {readOnly ? (
            <></>
          ) : (
            <Field register={register} name="customerUserId" />
          )}
        </div>
        <Field
          name={`taxNumber${namePostFix || ""}`}
          error={errors[`taxNumber${namePostFix || ""}`]}
          register={register}
          label={strings.taxNumber}
          readOnly={readOnly}
        />
        <Field
          name={`email${namePostFix || ""}`}
          error={errors[`email${namePostFix || ""}`]}
          fieldOptions={{
            validate: {
              isNotRequired: (v: string | undefined) => {
                const paymentMethodTrigger:
                  | PaymentMethodResponse[]
                  | undefined = getValues("paymentMethod");

                if (!paymentMethodTrigger) {
                  return true;
                }
                return (
                  (paymentMethodTrigger && paymentMethodTrigger[0]?.type) !==
                    PaymentMethod.ONLINE ||
                  (v && v.length > 0)
                );
              },
            },
          }}
          register={register}
          label={strings.email}
          readOnly={readOnly}
          showRequired={
            !readOnly && paymentMethod?.type === PaymentMethod.ONLINE
          }
        />
        <PhoneNumber
          control={control}
          country={defaultCountryCode}
          disabled={readOnly}
          id="phone"
          label={strings.phone}
          name={`phone${namePostFix || ""}`}
          readOnly={readOnly}
        />
        <Field
          name={`street${namePostFix || ""}`}
          error={errors[`street${namePostFix || ""}`]}
          register={register}
          label={strings.address}
          readOnly={readOnly}
        />
        <Field
          name={`houseDetails${namePostFix || ""}`}
          error={errors[`houseDetails${namePostFix || ""}`]}
          register={register}
          label={strings.addressLine2}
          readOnly={readOnly}
        />
        <Field
          name={`city${namePostFix || ""}`}
          error={errors[`city${namePostFix || ""}`]}
          register={register}
          label={strings.city}
          readOnly={readOnly}
        />
        <div className="grid grid-cols-2 gap-4">
          <Field
            name={`zip${namePostFix || ""}`}
            error={errors[`zip${namePostFix || ""}`]}
            register={register}
            label={strings.zip}
            readOnly={readOnly}
          />
          <SelectCountry
            control={control}
            name={`country${namePostFix || ""}`}
            readOnly={readOnly}
            setValue={setValue}
          />
        </div>
      </div>
    </>
  );
};

export default BillingForm;

BillingForm.defaultProps = {
  countries: [],
  namePostFix: undefined,
  paymentMethod: undefined,
  readOnly: false,
};
