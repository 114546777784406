/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { CountryNameResponse } from "../models/management/CountryNameResponse";
import { CountrySettingsResponse } from "../models/management/CountrySettingsResponse";
import { CountryUpdateRequest } from "../models/management/CountryUpdateRequest";
import { CountryResponse } from "../models/management/CountryResponse";
import Params from "../common/Params";
import { CountryDetailsResponse } from "../models/management/CountryDetailsResponse";
import { CountryPriceSettingsResponse } from "../models/management/CountryPriceSettingsResponse";
import { CountryPriceSettingsRequest } from "../models/management/CountryPriceSettingsRequest";
import { CountryFeesResponse } from "../models/management/CountryFeesResponse";

export default class CountryApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getCountryNames(): ApiPromise<CountryNameResponse[]> {
    const url = `${this.baseUrl}/countries/names`;
    return axiosCommon.get(url);
  }

  static getCountryDetails(
    countryCode: string
  ): ApiPromise<CountryDetailsResponse> {
    const url = `${this.baseUrl}/countries/${countryCode}`;
    return axiosCommon.get(url);
  }

  static getCountryDetailsOfClinic(
    clinicId: string
  ): ApiPromise<CountryDetailsResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/country-details`;
    return axiosCommon.get(url);
  }

  static getCountrySettings(): ApiPromise<CountrySettingsResponse[]> {
    const url = `${this.baseUrl}/managements/countries`;
    return axiosCommon.get(url);
  }

  static getCountrySettingsByCode(
    countryCode: string
  ): ApiPromise<CountrySettingsResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}`;
    return axiosCommon.get(url);
  }

  static updateCountry(
    countryId: string,
    country: CountryUpdateRequest
  ): ApiPromise<CountrySettingsResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryId}`;
    return axiosCommon.put(url, country);
  }

  static getCountryPriceSettings(
    countryCode: string
  ): ApiPromise<CountryPriceSettingsResponse[]> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/prices`;
    return axiosCommon.get(url);
  }

  static updateCountryPriceSettings(
    countryId: string,
    request: CountryPriceSettingsRequest
  ): ApiPromise<CountrySettingsResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryId}/prices`;
    return axiosCommon.put(url, request);
  }

  static deleteCountryPriceSettings(
    countryId: string,
    settingsId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/managements/countries/${countryId}/prices/${settingsId}`;
    return axiosCommon.delete(url);
  }

  static getCountries(): ApiPromise<CountryResponse[]> {
    const url = `${this.baseUrl}/countries`;
    return axiosCommon.get(url);
  }

  static getCountryFees(countryCode: string): ApiPromise<CountryFeesResponse> {
    const url = `${this.baseUrl}/countries/${countryCode}/fees`;
    return axiosCommon.get(url);
  }
}
