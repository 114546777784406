/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import { UsedInventoryItemType } from "../../../../models/medical/local/UsedInventoryItemType";
import logger from "../../../../util/logger";
import { getGeneralError } from "../../../../util/helperFunctions";
import InventoryItemApi from "../../../../api/InventoryItemApi";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import DynamicSelect from "../../../../components/ReactHookFormFields/General/Select/DynamicSelect";
import { InventoryItemResponse } from "../../../../models/inventory/item/InventoryItemResponse";
import Button from "../../../../components/Button";
import { XMark } from "../../../../common/Icons/XMark";
import { useMainSectionContext } from "../../MainSection/MainSectionContext";
import { InventoryRelatedTypes } from "../../../../models/medical/local/InventoryRelatedTypes";
import { UsedInventoryItemResponse } from "../../../../models/medical/UsedInventoryItemResponse";

interface Props {
  canEdit: boolean;
  clinicId: string;
}

interface FormData {
  inventoryItem: InventoryItemResponse[];
  quantity: number;
}

const ListItemCard: React.FC<{
  canEdit: boolean;
  deleteCurrent: (id: string, where: InventoryRelatedTypes) => void;
  idx: number;
  record: UsedInventoryItemResponse;
  where: InventoryRelatedTypes;
}> = ({ idx, record, deleteCurrent, canEdit, where }) => {
  return (
    <div key={idx} className="tw-card px-3 py-2">
      <div className="flex items-center">
        <div className="font-bold text-base flex space-x-3">
          <div>
            {record.quantity} {record.unit ? quantityUnits[record.unit] : "x"}
          </div>
          <div>{record.name}</div>
        </div>
        <div className="ml-auto flex items-center">
          {canEdit ? null : (
            <>
              <Button
                onClick={() => {
                  deleteCurrent(record.id, where);
                }}
                variant="basic"
              >
                <XMark className="w-5 h-5" />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const UseInventoryItems: React.FC<Props> = ({ canEdit, clinicId }: Props) => {
  const [allInventoryItems, setAllInventoryItems] = useState<
    InventoryItemResponse[]
  >([]);
  const [options, setOptions] = useState<InventoryItemResponse[]>([]);
  const [addedItem, setAddedItem] = useState<InventoryItemResponse[] | null>(
    null
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      quantity: 1,
    },
  });
  const { deleteCurrent, newlyAdded, handleInventoryItemChange } =
    useMainSectionContext();

  useEffect(() => {
    const getInventoryItems = async () => {
      try {
        const response = await InventoryItemApi.searchItemsOfClinic(clinicId, {
          pageNumber: 0,
          pageSize: 200,
        });
        setAllInventoryItems(response.data.elements);
        setOptions(response.data.elements);
      } catch (e) {
        logger.error(await getGeneralError(e));
      }
    };

    if (clinicId) {
      void getInventoryItems();
    }
  }, [clinicId]);

  const findInventoryItems = async (query: string) => {
    try {
      const response = await InventoryItemApi.searchItemsOfClinic(clinicId, {
        query,
      });
      setOptions(response.data.elements);
    } catch (e) {
      logger.error(await getGeneralError(e));
    }
  };

  const search = (query: string) => {
    if (query.length > 0) {
      void findInventoryItems(query);
    }
  };

  const addToList = ({ inventoryItem, quantity }: FormData) => {
    if (!inventoryItem || !inventoryItem[0]) {
      return;
    }

    const newItem = {
      id: inventoryItem[0].id,
      name: inventoryItem[0].name,
      quantity,
      unit: inventoryItem[0].acquisitionUnit,
      price: inventoryItem[0].sellingPrice || 0,
      vat: inventoryItem[0].sellingVat,
      accountingCode: inventoryItem[0].accountingCode,
    };

    if (inventoryItem[0]?.categoryName === "Vaccines") {
      handleInventoryItemChange(newItem, UsedInventoryItemType.Vaccines);
    } else if (inventoryItem[0]?.categoryName === "Medicines") {
      handleInventoryItemChange(newItem, UsedInventoryItemType.Medicines);
    } else {
      handleInventoryItemChange(newItem, UsedInventoryItemType.Other);
    }

    setAddedItem(null);
    setOptions(allInventoryItems);
    reset();
  };

  const handleChange = (input: Array<InventoryItemResponse> | null) => {
    setAddedItem(input);
  };

  return (
    <div
      className={
        canEdit &&
        newlyAdded.otherInventoryItems.length === 0 &&
        newlyAdded.vaccines.length === 0 &&
        newlyAdded.medicines.length === 0
          ? "hidden"
          : ""
      }
    >
      <form
        className={
          newlyAdded.otherInventoryItems.length === 0 &&
          newlyAdded.vaccines.length === 0 &&
          newlyAdded.medicines.length === 0
            ? ""
            : "pb-4"
        }
        onSubmit={handleSubmit(addToList)}
      >
        <div className="flex flex-wrap gap-4 items-end">
          <div className="relative flex-fill">
            <label htmlFor="inventoryItem">{strings.inventoryItems}</label>
            {canEdit ? (
              <></>
            ) : (
              <>
                <div className="absolute right-0 top-0">
                  <Link className="text-sm" to="/inventory/create-item">
                    {strings.createNewInventoryItem}
                  </Link>
                </div>
                <DynamicSelect
                  allowNew={false}
                  control={control}
                  defaultOptions={allInventoryItems}
                  labelKey="name"
                  name="inventoryItem"
                  onChange={handleChange}
                  onInputValueChange={search}
                  options={options}
                  placeholder={strings.searchInventoryItems}
                />
              </>
            )}
          </div>
          {addedItem && (
            <>
              <Field
                autoComplete={AutoCompleteOptions.off}
                error={errors.quantity}
                fieldOptions={{
                  validate: {
                    positive: (v) => v > 0,
                  },
                }}
                label={strings.quantity}
                name="quantity"
                register={register}
                required
                step="0.0001"
                suffix={
                  addedItem
                    ? quantityUnits[addedItem[0].acquisitionUnit || "PIECES"]
                    : undefined
                }
                type="number"
                width="100px"
              />
              <div>
                <Button variant="primary" className="text-sm" type="submit">
                  {strings.add}
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <div className="space-y-2">
        {newlyAdded.otherInventoryItems?.map((record, idx: number) => (
          <ListItemCard
            idx={idx}
            record={record}
            canEdit={canEdit}
            deleteCurrent={deleteCurrent}
            where={InventoryRelatedTypes.otherInventoryItems}
          />
        ))}
        {newlyAdded.medicines?.map((record, idx: number) => (
          <ListItemCard
            idx={idx}
            record={record}
            canEdit={canEdit}
            deleteCurrent={deleteCurrent}
            where={InventoryRelatedTypes.medicines}
          />
        ))}
        {newlyAdded.vaccines?.map((record, idx: number) => (
          <ListItemCard
            idx={idx}
            record={record}
            canEdit={canEdit}
            deleteCurrent={deleteCurrent}
            where={InventoryRelatedTypes.vaccines}
          />
        ))}
      </div>
    </div>
  );
};

export default UseInventoryItems;
