/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import {
  petSpecies,
  quantityUnits,
} from "../../../../../common/Strings/Strings";
import { InvoiceItemRequest } from "../../../../../models/invoice/InvoiceItemRequest";
import { PetSpecies } from "../../../../../models/pet/PetSpeciesEnum";
import NumberFormatter from "../../../../../util/NumberFormatter";
import SimpleInput from "../../../../../components/InputFieldsSimple/SimpleInput";
import CostCalculator from "../../../../../util/CostCalculator";
import LoaderInline from "../../../../../components/LoaderInline";
import { InventoryItemResponse } from "../../../../../models/inventory/item/InventoryItemResponse";
import Button from "../../../../../components/Button";
import { PlusIcon } from "../../../../../common/Icons/PlusIcon";

interface Props {
  addToCart(item: InvoiceItemRequest): void;
  index: number;
  result: InventoryItemResponse;
  roundingDecimalLength?: number;
}

const ResultRow = ({ result, index, addToCart, roundingDecimalLength }: Props): ReactElement => {
  const [quantity, setQuantity] = useState<number>(1);
  const [adding, setAdding] = useState<boolean>(false);

  const displayAdd = () => {
    setAdding(true);
    setTimeout(() => setAdding(false), 300);
  };
  const add = ({
    name,
    sellingPrice,
    sellingVat,
    id,
    accountingCode,
    acquisitionUnit,
  }: InventoryItemResponse) => {
    const newItem: InvoiceItemRequest = {
      name,
      price: sellingPrice || 1,
      quantity,
      vat: sellingVat || 0,
      inventoryItemId: id,
      accountingCode,
      unit: acquisitionUnit || undefined,
    };

    addToCart(newItem);
  };

  return (
    <tr key={index}>
      <td className="px-4 py-2 text-sm font-medium">
        <b>{result.name}</b>
        <div>
          {result?.species?.map((se: PetSpecies, idx) => (
            <span key={`${se}-${idx}`}>{petSpecies[se]} </span>
          ))}
        </div>
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(
          result.sellingPrice || 0,
          result.sellingCurrency
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {result.sellingVat} %
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
        {NumberFormatter.formatPrice(
          CostCalculator.getGrossPriceOfItem({
            ...result,
            quantity: 1,
            vat: result.sellingVat,
            price: result.sellingPrice || 0,
          }, roundingDecimalLength),
          result.sellingCurrency
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
        <SimpleInput
          className="m-0"
          name="quantity"
          type="number"
          value={quantity}
          onChange={(value: string) => {
            setQuantity(value.length > 0 ? Number(value) : 1);
          }}
          width="80px"
          suffix={
            result.acquisitionUnit && quantityUnits[result.acquisitionUnit]
          }
        />
      </td>
      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
        <div className="flex justify-end">
          {adding ? (
            <LoaderInline />
          ) : (
            <Button
              className="p-1"
              fullWidth={false}
              onClick={() => {
                add(result);
                displayAdd();
              }}
              small
            >
              <PlusIcon className="w-5 h-5" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ResultRow;
