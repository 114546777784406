/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { clinicFeeUnits, strings } from "../../../../common/Strings/Strings";
import {
  getGeneralError,
  getVatOptions,
} from "../../../../util/helperFunctions";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../../api/ClinicApi";
import { ClinicFeeRequest } from "../../../../models/clinic/ClinicFeeRequest";
import { ClinicFeeType } from "../../../../models/clinic/ClinicFeeType";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { AccountingCodeResponse } from "../../../../models/accounting/AccountingCodeResponse";
import CloseButton from "../../../../components/CloseButton";
import AlertBox from "../../../../components/AlertBox";
import CombinedSelect from "../../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";
import PriceInputs from "../../../../components/ReactHookFormFields/Specific/PriceInputs";
import { ClinicFeeUnit } from "../../../../models/clinic/ClinicFeeUnit";

interface Props {
  clinic: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  onRefresh: (clinic: ClinicResponse) => void;
  setModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
}

interface FormData {
  videoConsultationFee: number;
  videoConsultationVat?: number;
  videoConsultationUnit: ClinicFeeUnit;
  accountingCode?: AccountingCodeResponse[];
}

const VideoConsultationFeeModal: React.FC<Props> = (props: Props) => {
  const { clinic, countryDetails, onRefresh, setModalOpen, isModalOpen } =
    props;

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [videoConsultationVat, setVideoConsultationVat] = useState<number>();

  const { control, reset, register, handleSubmit } = useForm<FormData>({
    mode: "onChange",
  });

  useEffect(() => {
    const fee = clinic?.fees.find(
      (i) => i.type === ClinicFeeType.VIDEO_CONSULTATION_FEE
    );

    setVideoConsultationVat(fee?.vat || countryDetails?.vatRates[0]);

    reset({
      accountingCode: countryDetails?.accountingCodes?.filter(
        (i) => i.code === fee?.accountingCode
      ),
      videoConsultationFee: fee?.amount,
      videoConsultationVat: fee?.vat || countryDetails?.vatRates[0],
      videoConsultationUnit: ClinicFeeUnit.OCCASIONS,
    });
  }, [clinic, countryDetails, reset]);

  const submitFees = async (data: FormData) => {
    setLoading(true);
    const accCode =
      data.accountingCode &&
      data.accountingCode[0] &&
      data.accountingCode[0].code;

    const request: ClinicFeeRequest[] = [
      {
        amount: data.videoConsultationFee,
        vat: data.videoConsultationVat,
        accountingCode: accCode,
        unit: data.videoConsultationUnit,
        type: ClinicFeeType.VIDEO_CONSULTATION_FEE,
      },
    ];

    try {
      const response = await ClinicApi.updateClinicFees(clinic.id, request);
      setLoading(false);
      setError(null);
      setModalOpen(false);
      onRefresh(response.data);
    } catch (err) {
      setLoading(false);
      setError(await getGeneralError(err));
    }
  };

  return (
    <Modal handleClose={() => setModalOpen(false)} show={isModalOpen}>
      <Modal.Header title={strings.videoConsultationFee} />
      <Modal.Body noSpacing>
        <form className="modal-body" onSubmit={handleSubmit(submitFees)}>
          <Select
            name="videoConsultationUnit"
            register={register}
            label={strings.unit}
            options={Object.keys(ClinicFeeUnit).map((i) => ({
              title: clinicFeeUnits[i],
              value: i,
            }))}
            required
          />
          <Select
            key="videoConsultationFeeVat"
            name="videoConsultationFeeVat"
            register={register}
            isChooseOption={false}
            label={strings.vatAsTax}
            onChange={(e) => {
              setVideoConsultationVat(Number(e.target.value));
            }}
            options={getVatOptions(countryDetails?.vatRates)}
            required
          />
          <PriceInputs
            name="videoConsultationFee"
            control={control}
            currency={countryDetails?.currency}
            label={strings.videoConsultationFee}
            required
            vat={videoConsultationVat}
          />
          <CombinedSelect
            allowNew={false}
            control={control}
            name="accountingCode"
            label={strings.accountingCode}
            labelKey="name"
            options={countryDetails?.accountingCodes || []}
            placeholder={strings.accountingCode}
          />
          <AlertBox message={error} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          loading={loading}
          onClick={handleSubmit(submitFees)}
          type="submit"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={() => setModalOpen(false)} />
      </Modal.Footer>
    </Modal>
  );
};

export default VideoConsultationFeeModal;
