/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../common/Strings/Strings";
import { MedicinalProduct } from "../../../../models/ePrescription/VARA/MedicinalProduct";
import hardcodedStrings from "../../../ePrescription/fixValues/hardcodedStrings";
import VaraPackageSelection from "./VaraPackageSelection";
import { PackageType } from "../../../../models/ePrescription/VARA/PackageType";
import { VaraItem } from "../../../../models/inventory/item/VaraItem";
import { CommodityProduct } from "../../../../models/ePrescription/VARA/CommodityProduct";
import VaraCommodityPackageSelection from "./VaraCommodityPackageSelection";
import ProductInfo from "../../../ePrescription/productDetails/ProductInfo";
import ProductInfoCommodity from "../../../ePrescription/productDetails/ProductInfoCommodity";

interface Props {
  medicalProduct?: MedicinalProduct;
  commodityProduct?: CommodityProduct;
  packageTypes: PackageType[];
  addPackageToInventory?(p: VaraItem): void;
}

const VaraProductDetailSection: React.FC<Props> = ({
  medicalProduct,
  commodityProduct,
  packageTypes,
  addPackageToInventory,
}: Props) => {
  return (
    <div className="pt-3 space-y-4">
      {medicalProduct ? (
        <>
          <ProductInfo medicine={medicalProduct} />
          {medicalProduct?.nplId === hardcodedStrings.licensedNplId ? (
            <></>
          ) : (
            <>
              <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.packages}
              </h2>
              <VaraPackageSelection
                medicalProduct={medicalProduct}
                packageTypes={packageTypes}
                returnPackages={addPackageToInventory}
              />
            </>
          )}
        </>
      ) : (
        <>
          {commodityProduct ? (
            <>
              <ProductInfoCommodity medicine={commodityProduct} />
              <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.packages}
              </h2>
              <VaraCommodityPackageSelection
                commodityProduct={commodityProduct}
                returnPackages={addPackageToInventory}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default VaraProductDetailSection;

VaraProductDetailSection.defaultProps = {
  medicalProduct: undefined,
  commodityProduct: undefined,
};
