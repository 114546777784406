/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ItemRow, { InventoryItemRowRef } from "./InventoryItemRow";
import { strings } from "../../common/Strings/Strings";
import { generateOptions, getGeneralError } from "../../util/helperFunctions";
import { UpdateInventoryItemStockRequest } from "../../models/inventory/item/UpdateInventoryItemStockRequest";
import InventoryItemApi from "../../api/InventoryItemApi";
import LoaderInline from "../../components/LoaderInline";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { useDebouncedState } from "../../hooks/hooks";
import { PageResponse } from "../../models/PageResponse";
import PageSizeSelect from "../../components/PageSizeSelect";
import Paging from "../../components/Paging";
import { useDeepLinking } from "../../hooks/DeepLinking";
import { SearchInventoryItems } from "../../models/apiparams/SearchInventoryItems";
import Field from "../../components/ReactHookFormFields/General/Field";
import { generateUUId } from "../ePrescription/sections/finish/sendingRequestProcesser";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import logger from "../../util/logger";
import Button from "../../components/Button";
import { InventoryCategoryResponse } from "../../models/inventory/category/InventoryCategoryResponse";
import AlertBox from "../../components/AlertBox";
import { Pencil } from "../../common/Icons/Pencil";
import { Trash } from "../../common/Icons/Trash";
import EmptyListText from "../../components/EmptyListText";
import EditCategoryModal from "./Modals/EditCategoryModal";
import { InventoryItemResponse } from "../../models/inventory/item/InventoryItemResponse";
import Tooltip from "../../components/Tooltip";
import { QuestionMarkCircle } from "../../common/Icons/QuestionMarkCircle";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import CountryApi from "../../api/CountryApi";

interface Props {
  categories: InventoryCategoryResponse[];
  clinicId?: string;
  updateList: boolean;
  updateCategories(): void;
}

const InventoryList: React.FC<Props> = ({
  categories,
  clinicId,
  updateList,
  updateCategories,
}: Props) => {
  const [error, setError] = useState<string | null>(null);

  const [refilledItems, setRefilledItems] = useState<
    UpdateInventoryItemStockRequest[]
  >([]);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<
    InventoryCategoryResponse | undefined
  >();
  const [inventoryItems, setInventoryItems] = useState<
    PageResponse<InventoryItemResponse> | undefined
  >();
  const [urlState, setUrlState, isUrlStateDeserialized] =
    useDeepLinking<SearchInventoryItems>();
  const [isUrlStateLoaded, setUrlStateLoaded] = useState<boolean>(false);
  const [countryDetails, setCountryDetails] =
  useState<CountryDetailsResponse>(); 

  const { register, setValue, getValues, reset } =
    useForm<SearchInventoryItems>({
      defaultValues: { pageSize: 50, pageNumber: 0 },
    });
  const listFiltersChanged = useDebouncedState<string>("", 400);

  const [refillDirty, setRefillDirty] = useState<boolean>(false);

  const handleOnDirty = () => {
    setRefillDirty(true);
  };

  const rowRefs = useRef<Array<InventoryItemRowRef>>([]);

  

useEffect(() => {
  const getCountryDetails = async () => {
    if (!clinicId) {
      return;
    }

    try {
      const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
      setCountryDetails(response.data);
    } catch (err) {
      logger.error(err);
    }
  };
  void getCountryDetails();
}, [clinicId]);

  useEffect(() => {
    rowRefs.current = rowRefs.current.slice(0, inventoryItems?.elements.length);
  }, [inventoryItems?.elements.length]);

  const openEditCategoryModal = () => {
    setEditCategoryModal(true);
  };

  const closeEditCategoryModal = () => {
    setEditCategoryModal(false);
  };

  const triggerReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    setRefilledItems([]);
  }, [reload]);

  useEffect(() => {
    const getInventoryItems = async () => {
      if (!clinicId) {
        return;
      }
      try {
        const filterValues = getValues();
        const resp = await InventoryItemApi.searchItemsOfClinic(clinicId, {
          ...filterValues,
          stockFilter:
            filterValues.stockFilter === "ALL"
              ? undefined
              : filterValues.stockFilter,
          categoryId:
            filterValues?.categoryId === ""
              ? undefined
              : filterValues.categoryId,
        });
        setInventoryItems(resp.data);
        setUrlState(filterValues);
      } catch (e) {
        setError(await getGeneralError(e));
        logger.error(e);
      } finally {
        setLoading(false);
      }
    };
    if (isUrlStateLoaded) {
      void getInventoryItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFiltersChanged.value, reload, updateList, isUrlStateLoaded]);

  const onChange = (refreshPageNumber = true) => {
    if (refreshPageNumber) {
      setValue("pageNumber", 0);
    }
    setLoading(true);
    listFiltersChanged.setValue(generateUUId());
  };

  useEffect(() => {
    if (isUrlStateDeserialized) {
      if (urlState) {
        reset(urlState);
        if (urlState.categoryId && isUrlStateLoaded) {
          setSelectedCategory(
            categories.find(
              (c: InventoryCategoryResponse) => c.id === urlState.categoryId
            )
          );
        }
      }
      if (!isUrlStateLoaded) {
        setUrlStateLoaded(true);
        onChange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlState, categories, isUrlStateDeserialized, isUrlStateLoaded]);

  const stockRefillHandler = (value: number, item: InventoryItemResponse) => {
    const refilledItem = refilledItems.find((i) => i.itemId === item.id);

    if (refilledItem) {
      refilledItem.newQuantity = value;
    } else {
      setRefilledItems([
        ...refilledItems,
        { itemId: item.id, newQuantity: value },
      ]);
    }
  };

  const update = (data: InventoryItemResponse[]) => {
    data.forEach((item) => {
      categories.forEach((category) => {
        const found = category.items.find((i) => i.id === item.id);
        if (found) {
          found.stock = item.stock;
        }
      });
    });

    updateCategories();
  };

  const saveRefill = async () => {
    if (refilledItems.length > 0) {
      if (refilledItems.find((i) => i.newQuantity !== 0)) {
        setButtonsDisabled(true);
        if (!clinicId) return;
        try {
          const resp = await InventoryItemApi.updateStock(
            clinicId,
            refilledItems
          );
          update(resp.data.items);
          triggerReload();
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setButtonsDisabled(false);
        }
      }
    }
  };

  const deleteItem = async (id: string) => {
    setLoading(true);

    try {
      await InventoryItemApi.removeItem(id);
      triggerReload();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="space-y-6">
        <AlertBox message={error} />
        <form>
          <div className="flex flex-wrap items-end">
            <div className="mr-3">
              <Field
                className="mb-0"
                label={strings.search}
                name="query"
                onChange={() => {
                  onChange();
                }}
                register={register}
              />
            </div>
            <div className="mr-3">
              <Select
                autoComplete={AutoCompleteOptions.off}
                className="mb-0"
                isChooseOption={false}
                label={strings.stock}
                name="stockFilter"
                onChange={() => {
                  onChange();
                }}
                options={[
                  { title: strings.showEverything, value: "ALL" },
                  { title: strings.showOnlyLow, value: "LOW_STOCK" },
                  { title: strings.stock, value: "IN_STOCK" },
                ]}
                register={register}
              />
            </div>
            <div className="mr-3">
              <Select
                autoComplete={AutoCompleteOptions.off}
                className="mb-0"
                firstOption={{ value: "", title: strings.showAll }}
                isChooseOption={false}
                label={strings.categories}
                name="categoryId"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedCategory(
                    categories.find(
                      (c: InventoryCategoryResponse) =>
                        c.id.toString() === e.target.value
                    )
                  );
                  onChange();
                }}
                options={generateOptions(categories, "name", "id")}
                register={register}
              />
            </div>
            <div className="mr-3">
              <Field
                className="hidden"
                name="pageSize"
                onChange={() => {
                  listFiltersChanged.setValue(generateUUId());
                }}
                register={register}
                type="number"
              />
              <Field
                className="hidden"
                name="pageNumber"
                onChange={() => {
                  onChange();
                }}
                register={register}
                type="number"
              />
            </div>
            <div className="ml-auto">
              <Button
                onClick={() => {
                  setRefilledItems([]);
                  rowRefs.current.forEach((ref) => {
                    ref.resetForm();
                    setRefillDirty(false);
                  });
                }}
                hidden={!refillDirty}
                disabled={buttonsDisabled}
                variant="secondary"
              >
                {strings.resetRefillValues}
              </Button>
            </div>
            <div className="ml-4 flex justify-center items-center">
              <Button
                onClick={saveRefill}
                disabled={!refillDirty || buttonsDisabled}
              >
                {strings.refillStock}
              </Button>
              <Tooltip content={strings.refillStockInfo}>
                <QuestionMarkCircle className="w-6 h-6 mx-2 dark:text-white" />
              </Tooltip>
            </div>
          </div>
        </form>
        {loading ? (
          <div className="tw-table-container py-3.5 px-4">
            <LoaderInline />
          </div>
        ) : (
          <div className="tw-table-container">
            <table className="tw-table">
              <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
                <tr>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.name}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.barcode}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.species}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.stock}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.refill}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.acquisitionVat}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.acquisitionPrice}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.sellingVat}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.sellingPrice}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.accountingCode}
                  </th>
                  <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400 text-right">
                    {strings.operations}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                {selectedCategory && (
                  <tr
                    key={selectedCategory.id}
                    className="bg-gray-50 dark:bg-gray-800"
                  >
                    <td
                      className="px-3 py-3 text-medium font-bold"
                      colSpan={11}
                    >
                      <div className="flex items-center">
                        <div>{`${selectedCategory.name} `}</div>
                        <div className="ml-auto" hidden={!selectedCategory.deletable}>
                          <Button
                            className="tw-link"
                            onClick={() => {
                              openEditCategoryModal();
                            }}
                            variant="icon"
                          >
                            <Pencil />
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {inventoryItems && inventoryItems.elements.length > 0 ? (
                  inventoryItems.elements.map((item, idx) => (
                    <tr key={item.id}>
                      <ItemRow
                        action={(n, i) => {
                          stockRefillHandler(n, i);
                        }}
                        countryDetails={countryDetails}
                        item={item}
                        ref={(r: InventoryItemRowRef) => {
                          rowRefs.current[idx] = r;
                        }}
                        onDirty={handleOnDirty}
                      />
                      <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-center">
                        <div>
                          <Link to={`/inventory/item/${item.id}/edit`}>
                            <Button
                              variant="icon"
                              className="justify-center items-center"
                            >
                              <Pencil />
                            </Button>
                          </Link>
                          <Button
                            askAreYouSure={{
                              enable: true,
                              content: strings.formatString(
                                strings.areYouSureDeleteItem,
                                <b>{item.name}</b>
                              ),
                              title: strings.deletingItem,
                            }}
                            className="ml-3 justify-center items-center"
                            variant="icon"
                            onClick={() => {
                              void deleteItem(item.id);
                            }}
                          >
                            <Trash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-3 py-3 text-sm font-medium whitespace-nowrap" colSpan={11}>
                      <EmptyListText />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div>
          {inventoryItems && (
            <>
              <div className="flex items-center">
                <PageSizeSelect
                  pageSize={getValues("pageSize") || 50}
                  setPageSize={(newPageSize: number) => {
                    setValue("pageSize", newPageSize);
                    onChange();
                  }}
                  totalPages={inventoryItems.totalPages}
                  totalResults={inventoryItems.totalElements}
                />
                <div className="ml-auto">
                  <Paging
                    totalPages={inventoryItems.totalPages}
                    currentPage={getValues("pageNumber")}
                    selectPage={(newPageNumber: number) => {
                      setValue("pageNumber", newPageNumber);
                      onChange(false);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedCategory ? (
        <EditCategoryModal
          clinicId={clinicId}
          category={selectedCategory}
          isOpen={editCategoryModal}
          close={closeEditCategoryModal}
          reloadCategories={(isDeleted: boolean, newName?: string) => {
            if (isDeleted) {
              setValue("categoryId", "");
              setSelectedCategory(undefined);
            } else if (newName) {
              setSelectedCategory({ ...selectedCategory, name: newName });
            }
            triggerReload();
            updateCategories();
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default InventoryList;
