/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import printJS from "print-js";
import { AxiosResponse } from "axios";
import MedicalRecordApi from "../api/MedicalRecordApi";
import { InvoiceResponse } from "../models/invoice/InvoiceResponse";
import InvoiceApi from "../api/InvoiceApi";
import { ClinicChargeResponse } from "../models/charge/ClinicChargeResponse";
import ChargeApi from "../api/ChargeApi";
import { VetProfessionDocumentResponse } from "../models/vet/VetProfessionResponse";
import VetApi from "../api/VetApi";
import ClinicInvoiceApi from "../api/ClinicInvoiceApi";
import { TestInvoiceResponse } from "../models/invoice/TestInvoiceResponse";
import TestInvoiceApi from "../api/TestInvoiceApi";
import { MedicalRecordFileResponse } from "../models/medical/MedicalRecordFileResponse";

export const pickUserFile = (
  accept: string,
  multiple: boolean
): Promise<File[] | undefined> =>
  new Promise<File[] | undefined>((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = accept;
    input.multiple = multiple;
    let resolved = false;
    input.onchange = () => {
      if (input.files && !resolved) {
        resolved = true;
        resolve(input.files ? Array.from(input.files) : undefined);
      }
    };
    document.body.appendChild(input);
    input.click();
    input.remove();
    // We need a way to reject the promise on 'cancel' in order to avoid memory leak.
    // HACK: When the file dialog is closed even without file(s) selected, the window gets the focus back.
    const closeListener = () => {
      // HACK: The 'files' attribute is not necessarily updated before window focus.
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          resolve(undefined);
        }
      }, 1000);
      window.removeEventListener("focus", closeListener);
    };
    window.addEventListener("focus", closeListener);
  });

export const downloadUrl = (fileName: string, url: string): void => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadBlobPart = (fileName: string, content: BlobPart): void => {
  downloadUrl(fileName, window.URL.createObjectURL(new Blob([content])));
};

export const urlFromBlobPart = (b: BlobPart): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const r = new FileReader();
    r.readAsDataURL(new Blob([b]));
    r.onerror = reject;
    r.onloadend = () => {
      resolve(r.result as string);
    };
  });

export const downloadMedicalRecordPdfForReservation = (
  referenceId?: string,
  id?: string
): Promise<AxiosResponse | void> => {
  if (id && referenceId) {
    return MedicalRecordApi.getMedicalRecordPdf(id).then((resp) => {
      downloadBlobPart(`medical-record-${referenceId}.pdf`, resp.data);
    });
  }
  return new Promise<void>((reject, resolve) => {
    resolve();
  });
};

export const downloadExternalMedicalRecordPdf = (
  id: string
): Promise<AxiosResponse | void> =>
  MedicalRecordApi.getFreeTextMedicalRecordPdf(id).then((resp) => {
    downloadBlobPart(`medical-record-${id}.pdf`, resp.data);
  });

export const downloadInvoicePdf = (
  invoice: InvoiceResponse
): Promise<AxiosResponse | void> =>
  InvoiceApi.getInvoicePdf(invoice.id).then((resp) => {
    downloadBlobPart(`invoice-${invoice.invoiceNumber}.pdf`, resp.data);
  });

export const printInvoicePdf = (
  invoice: InvoiceResponse,
  onPrintingEnded: () => void
): Promise<AxiosResponse | void> =>
  InvoiceApi.getInvoicePdf(invoice.id).then((resp) => {
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    printJS({ printable: url, type: "pdf", onLoadingEnd: onPrintingEnded });
  });

export const downloadTestInvoicePdf = (
  countryCode: string,
  invoice: TestInvoiceResponse
): Promise<AxiosResponse | void> =>
  TestInvoiceApi.getInvoicePdf(countryCode, invoice.id).then((resp) => {
    downloadBlobPart(`test-invoice-${invoice.creationDate}.pdf`, resp.data);
  });

export const printTestInvoicePdf = (
  countryCode: string,
  invoice: TestInvoiceResponse,
  onPrintingEnded: () => void
): Promise<AxiosResponse | void> =>
  TestInvoiceApi.getInvoicePdf(countryCode, invoice.id).then((resp) => {
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    printJS({ printable: url, type: "pdf", onLoadingEnd: onPrintingEnded });
  });

export const downloadInvoicePdfForClinicCharge = async (
  charge: ClinicChargeResponse
): Promise<void> => {
  const resp = await ChargeApi.getInvoicePdfForClinicCharge(charge.id);
  downloadBlobPart(`monthly-invoice-${charge.id}.pdf`, resp.data);
};

export const downloadVetProfessionDocument = (
  doc: VetProfessionDocumentResponse
): Promise<AxiosResponse | void> =>
  VetApi.getVetProfessionDocument(doc.fileId).then((resp) => {
    downloadBlobPart(doc.fileName, resp.data);
  });

export const downloadPaymentSummaryOfClinic = (
  clinicId: string,
  startDate: string | null,
  endDate: string | null
): Promise<AxiosResponse | void> =>
  ClinicInvoiceApi.getPaymentSummaryPdf(clinicId, startDate, endDate).then(
    (resp) => {
      downloadBlobPart(`payment-summary.pdf`, resp.data);
    }
  );

export const downloadMedicalRecordFile = (
  reservationId: string,
  file: MedicalRecordFileResponse
): Promise<AxiosResponse | void> =>
  MedicalRecordApi.getFile(reservationId, file.fileId).then((resp) => {
    downloadBlobPart(file.fileName, resp.data);
  });

export const printMedicalRecordPdf = (
  id: string,
  onPrintingEnded: () => void
): Promise<AxiosResponse | void> =>
  MedicalRecordApi.getMedicalRecordPdf(id).then((resp) => {
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    printJS({ printable: url, type: "pdf", onLoadingEnd: onPrintingEnded });
  });
