/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import { UserResponse } from "../../../models/user/UserResponse";

interface Props {
  authorities: string[];
  user: UserResponse;
}

function Menu(props: Props): ReactElement {
  const { authorities, user } = props;

  const hasAuthority = (authority: string): boolean => {
    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  const hasAnySettingsAuthority = (): boolean => {
    return (
      hasAuthority("MANAGER_CONFIG") ||
      hasAuthority("MANAGER_FEES") ||
      hasAuthority("MANAGER_POLICIES") ||
      hasAuthority("MANAGER_MANAGERS")
    );
  };

  return (
    <>
      <div className="card d-flex flex-column">
        <div className="d-flex p-3 flex-fill align-items-center">
          <div className="paragraph bold">{user.details?.fullName}</div>
        </div>
        <MenuItem
          hasAuthority={hasAnySettingsAuthority()}
          route="/management/settings"
          name={strings.SITE_MANAGER_SETTINGS}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_USERS")}
          route="/management/users"
          name={strings.USERS}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_CLINICS")}
          route="/management/clinics"
          name={strings.CLINICS}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_BILLING")}
          route="/management/billing"
          name={strings.BILLING}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_DOCUMENTS")}
          route="/management/vet-documents"
          name={strings.VET_DOCUMENTS}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_INSURANCE")}
          route="/management/insurance"
          name={strings.INSURANCE}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_FEEDBACKS")}
          route="/management/feedbacks"
          name={strings.FEEDBACKS}
        />
        <MenuItem
          hasAuthority={hasAuthority("MANAGER_CLINICS")}
          route="/management/analytics/events"
          name={strings.ANALYTICS}
        />
      </div>
    </>
  );
}

interface ItemProps {
  hasAuthority: boolean;
  route: string;
  name: string;
}

export const MenuItem = (props: ItemProps): ReactElement => {
  const { hasAuthority, route, name } = props;

  if (!hasAuthority) {
    return <></>;
  }

  return (
    <Link to={route}>
      <div
        className="d-flex p-3 hr-on-top flex-fill align-items-center"
        key={name}
      >
        <div className="paragraph">{name}</div>
        <div className="ml-auto">
          <Button variant="secondary">
            <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
    </Link>
  );
};

export default Menu;
