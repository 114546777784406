/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import Button from "../../../components/Button";

interface Props {
  clinicId: string;
  title?: string;
  message?: string;
  hidden?: boolean;
}

const FortnoxAuthenticationError = ({
  clinicId,
  title,
  message,
  hidden,
}: Props): ReactElement => (
  <AlertBox
    hidden={hidden}
    closeAble={false}
    message={
      <div className="flex flex-wrap">
        <div className="space-y-1">
          <div className="font-bold">
            {title || strings.failedToSendToFortnox}
          </div>
          <div>{message || strings.authenticationNeeded}</div>
        </div>
        <div className="ml-auto">
          <Link to={`/clinics/${clinicId}/fortnox-auth`}>
            <Button variant="danger">{strings.authenticate}</Button>
          </Link>
        </div>
      </div>
    }
  />
);

export default FortnoxAuthenticationError;
