/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import BillingSection, { BillingSectionProps } from "./BillingSection";
import ChevronButtonUp from "../../../components/Buttons/ChevronButtonUp";
import ChevronButtonDown from "../../../components/Buttons/ChevronButtonDown";
import Button from "../../../components/Button";
import { Pencil } from "../../../common/Icons/Pencil";
import Modal from "../../../components/Modal/Modal";
import { ReservationState } from "../../../models/reservation/ReservationState";
import InvoiceStatusSelect from "./InvoiceStatusSelect";
import AlertBox from "../../../components/AlertBox";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import { useMainSectionContext } from "../MainSection/MainSectionContext";
import { ExtraCost } from "../../../models/medical/ExtraCost";
import { InvoiceItemRequest } from "../../../models/invoice/InvoiceItemRequest";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";
import NewCustomCost from "../../Accounting/CreateInvoice/Products/NewCustomCost";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import { Tooltip } from "flowbite-react";

const CostsSection = (props: BillingSectionProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isCostsModalOpen, setIsCostsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isExtraCostModalOpen, setisExtraCostModalOpen] =
    useState<boolean>(false);
  const openExtraCostModal = () => setisExtraCostModalOpen(true);
  const closeExtraCostModal = () => setisExtraCostModalOpen(false);

  const { countryDetails, isUserNotOwner, setUsedOthers, triggerChange } =
    useMainSectionContext();

  const submitExtraItem = (values: InvoiceItemRequest) => {
    const extraCost: ExtraCost = {
      ...values,
      id: generateUUId(),
    };
    setUsedOthers({ type: "add", itemToAdd: extraCost });
    triggerChange();
    closeExtraCostModal();
  };

  return (
    <>
      <div className="lg:tw-card p-4 lg:p-6 space-y-4">
        <button
          className="flex items-center justify-between w-full bg-transparent dark:bg-transparent bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          type="button"
        >
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.costs}
          </h2>
          {isOpen ? <ChevronButtonUp /> : <ChevronButtonDown />}
        </button>
        {isOpen && (
          <>
            <div className="flex items-center space-x-3">
              {props.reservationState === ReservationState.PAID ||
              props.reservationState === ReservationState.PENDING_PAYMENT ||
              props.reservationState === ReservationState.READY_FOR_PAYMENT ? (
                <div>
                  <Link to={`/invoices/clinic/${props.invoiceId}`}>
                    <Button variant="link-tertiary">{strings.invoice}</Button>
                  </Link>
                </div>
              ) : (
                <>
                  <div hidden={!isUserNotOwner}>
                    <Tooltip content={strings.edit} placement="top">
                      <Button
                        variant="icon"
                        onClick={() => {
                          setIsCostsModalOpen(true);
                        }}
                      >
                        <Pencil />
                      </Button>
                    </Tooltip>
                  </div>
                  <div hidden={!isUserNotOwner}>
                    <Tooltip content={strings.addExtraItem} placement="top">
                      <Button variant="icon" onClick={openExtraCostModal}>
                        <PlusIcon className="w-5 h-5" />
                      </Button>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            <BillingSection countryDetails={countryDetails} readonly {...props} />
            <div className="flex flex-wrap items-center">
              <div className="w-full" hidden={!isUserNotOwner}>
                <InvoiceStatusSelect
                  disabled={
                    !(props.accessScope !== ReservationAccessScope.PEEK)
                  }
                  medicalRecordId={props.medicalRecordId}
                  reservationId={props.reservationId}
                  reservationStatus={props.reservationState}
                  setError={setError}
                  updateMedicalRecord={props.updateMedicalRecord}
                />
              </div>
            </div>
            <AlertBox message={error} closeAble={false} />
          </>
        )}
      </div>
      <Modal
        show={isCostsModalOpen}
        handleClose={() => {
          setIsCostsModalOpen(false);
        }}
        size="modal-lg"
      >
        <Modal.Header title={strings.costs} />
        <Modal.Body>
          <BillingSection countryDetails={countryDetails} readonly={false} {...props} />
        </Modal.Body>
      </Modal>
      <Modal handleClose={closeExtraCostModal} show={isExtraCostModalOpen}>
        <Modal.Header title={strings.addExtraItem} />
        <Modal.Body>
          <NewCustomCost
            countryDetails={countryDetails}
            submit={submitExtraItem}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CostsSection;
