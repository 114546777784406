/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HealthApi from "../../../api/HealthApi";
import { IntegrationHealthResponse } from "../../../models/management/IntegrationHealthResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { strings } from "../../../common/Strings/Strings";

function Health(): ReactElement {
  const [successfulTests, setSuccessfulTests] = useState<
    IntegrationHealthResponse[]
  >([]);
  const [failedTests, setFailedTests] = useState<IntegrationHealthResponse[]>(
    []
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const testIntegrations = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await HealthApi.testSystemIntegrations();
        setSuccessfulTests(response.data.filter(i => i.success));
        setFailedTests(response.data.filter(i => !i.success));
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    };

    void testIntegrations();
  }, []);

  return (
    <>
      <div className="card d-flex flex-column">
        <div className="d-inline-flex p-3 align-items-center">
          <div className="paragraph bold flex-grow-1">
            {strings.systemIntegrations}
          </div>
          <div className="my-auto">
            <div className="align-items-center d-flex">
              <span
                hidden={!successfulTests.length}
                className="material-icons md-18 color-green"
              >
                check_circle
              </span>
              <div hidden={!successfulTests.length} className="ml-1 mr-2">
                {successfulTests.length}
              </div>
              <span
                hidden={!failedTests.length}
                className="material-icons md-18 text-red-500"
              >
                error
              </span>
              <div hidden={!failedTests.length} className="ml-1">
                {failedTests.length}
              </div>
            </div>
          </div>
        </div>
        <div hidden={!loading} className="hr-on-top text-center">
          <LoaderInline className="m-4" />
        </div>
        <div hidden={loading || !error} className="hr-on-top">
          <AlertBox message={error} className="m-3" closeAble={false} />
        </div>
        <div
          hidden={loading || error != null || failedTests.length > 0}
          className="hr-on-top text-center p-3"
        >
          <div className="material-icons md-48 color-green">check_circle</div>
          <div>{strings.systemIntegrationsAllWorking}</div>
        </div>
        {!loading &&
          !error &&
          failedTests.map(result => (
            <>
              <Link
                to={`management/countries/${result.country.code}/payment-and-billing`}
              >
                <div className="p-3 hr-on-top d-flex">
                  <div className="my-auto mr-3">
                    <span className="material-icons text-red-500">error</span>
                  </div>
                  <div>
                    <div className="paragraph mb-1" hidden={!result.error}>
                      {result.error}
                    </div>
                    <div className="paragraph-small text-muted">
                      {result.system} | {result.country.name}
                    </div>
                  </div>
                </div>
              </Link>
            </>
          ))}
      </div>
    </>
  );
}

export default Health;
