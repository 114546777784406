/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Dispatch, useState } from "react";
import { useForm } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { TreatmentItemResponse } from "../../../models/treatment/TreatmentItemResponse";
import InventoryItemSection from "./InventoryItemSection";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { PageResponse } from "../../../models/PageResponse";
import { InventoryItemResponse } from "../../../models/inventory/item/InventoryItemResponse";
import DynamicSelect from "../../../components/ReactHookFormFields/General/Select/DynamicSelect";
import CloseButton from "../../../components/CloseButton";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import EmptyListText from "../../../components/EmptyListText";
import Modal from "../../../components/Modal/Modal";

export type TreatmentItemActions =
  | { type: "fill"; itemsToAdd: TreatmentItemResponse[] }
  | { type: "add"; itemToAdd: TreatmentItemResponse }
  | { type: "changeQuantity"; itemToChange: TreatmentItemResponse }
  | { type: "remove"; itemToRemove: TreatmentItemResponse };

interface Props {
  clinicId?: string;
  close(): void;
  countryDetails?: CountryDetailsResponse;
  dispatch: Dispatch<TreatmentItemActions>;
  inventoryItems: InventoryItemResponse[];
  usedItems: Array<TreatmentItemResponse>;
  show: boolean;
}

export const treatmentItemReducer = (
  state: Array<TreatmentItemResponse>,
  action: TreatmentItemActions
) => {
  switch (action.type) {
    case "fill": {
      return action.itemsToAdd;
    }
    case "add": {
      const addActionId = action.itemToAdd.item.id;
      const alreadyAddedItemIndex = state.findIndex(
        (item: TreatmentItemResponse) => item.item.id === addActionId
      );
      if (alreadyAddedItemIndex >= 0) {
        state[alreadyAddedItemIndex].quantity += action.itemToAdd.quantity;
        return [...state];
      }
      return [...state, action.itemToAdd];
    }
    case "changeQuantity": {
      const changeActionId = action.itemToChange.item.id;
      const changeIndex = state.findIndex(
        (item: TreatmentItemResponse) => item.item.id === changeActionId
      );

      if (changeIndex >= 0) {
        state[changeIndex].quantity = action.itemToChange.quantity;
      }
      return [...state];
    }
    case "remove": {
      const removeActionId = action.itemToRemove.item.id;

      return [
        ...state.filter(
          (item: TreatmentItemResponse) => item.item.id !== removeActionId
        ),
      ];
    }
    default: {
      return [];
    }
  }
};

interface FormData {
  inventoryItem: InventoryItemResponse[];
  quantity: number;
}

const AddItemModal: React.FC<Props> = ({
  clinicId,
  close,
  countryDetails,
  dispatch,
  inventoryItems: defaultOptions,
  usedItems,
  show,
}: Props) => {
  const [loadingItemResults, setLoadingItemResults] = useState<boolean>(false);
  const [searchItemResults, setItemResults] =
    useState<PageResponse<InventoryItemResponse>>();
  const [addedItem, setAddedItem] = useState<InventoryItemResponse[] | null>(
    null
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      quantity: 1,
    },
  });

  const addItem = ({ inventoryItem, quantity }: FormData) => {
    if (inventoryItem.length === 1) {
      const treatmentItem: TreatmentItemResponse = {
        id: inventoryItem[0].id,
        item: {
          ...inventoryItem[0],
          acquisitionPrice: inventoryItem[0].acquisitionPrice,
          sellingPrice: inventoryItem[0].sellingPrice,
        },
        quantity,
      };
      dispatch({ type: "add", itemToAdd: treatmentItem });
      setAddedItem(null);
      reset();
    }
  };

  const deleteItem = (treatmentItem: TreatmentItemResponse) => {
    dispatch({ type: "remove", itemToRemove: treatmentItem });
  };

  const changeQuantityOfItem = (treatmentItem: TreatmentItemResponse) => {
    dispatch({ type: "changeQuantity", itemToChange: treatmentItem });
  };

  const search = async (queryText?: string) => {
    setLoadingItemResults(true);
    if (clinicId) {
      try {
        const response = await InventoryItemApi.searchItemsOfClinic(clinicId, {
          query: queryText,
        });
        setItemResults(response.data);
      } catch (err) {
        logger.error(err);
      } finally {
        setLoadingItemResults(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={show} size="modal-lg">
      <Modal.Header title={strings.inventoryItems} />
      <Modal.Body>
        <div className="space-y-6">
          <form
            className="flex space-x-4 items-end"
            onSubmit={handleSubmit(addItem)}
          >
            <DynamicSelect
              allowNew={false}
              className="md:min-w-96"
              control={control}
              defaultOptions={defaultOptions}
              labelKey="name"
              label={strings.addItem}
              name="inventoryItem"
              onChange={(selected: Array<any> | null) => {
                setAddedItem(selected);
              }}
              onInputValueChange={search}
              options={searchItemResults?.elements}
              placeholder={strings.searchInventoryItems}
            />
            {addedItem && (
              <>
                <Field
                  autoComplete={AutoCompleteOptions.off}
                  error={errors.quantity}
                  fieldOptions={{
                    validate: {
                      positive: (v) => v > 0,
                    },
                  }}
                  label={strings.quantity}
                  name="quantity"
                  register={register}
                  required
                  step="0.0001"
                  suffix={
                    addedItem
                      ? quantityUnits[addedItem[0].acquisitionUnit || "PIECES"]
                      : undefined
                  }
                  type="number"
                  width="100px"
                />
                <div>
                  <Button variant="primary" className="text-sm" type="submit">
                    {strings.add}
                  </Button>
                </div>
              </>
            )}
          </form>
          <div>
            {strings.usedItems}
            {usedItems.length === 0 ? (
              <div className="my-3">
                <EmptyListText text={strings.noItemsAdded} />
              </div>
            ) : (
              <InventoryItemSection
                changeQuantityOfItem={changeQuantityOfItem}
                countryDetails={countryDetails}
                deleteItem={deleteItem}
                usedItems={usedItems}
                showSum
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer extraClassName="justify-end">
        <CloseButton asMain onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddItemModal;
