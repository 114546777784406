/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../common/Strings/Strings";
import { canManageClinic, getGeneralError } from "../../util/helperFunctions";
import { ServiceFeeResponse } from "../../models/servicefee/ServiceFeeResponse";
import ServiceFeeApi from "../../api/ServiceFeeApi";
import CostCalculator from "../../util/CostCalculator";
import LoaderInline from "../../components/LoaderInline";
import NumberFormatter from "../../util/NumberFormatter";
import Paging from "../../components/Paging";
import CountryApi from "../../api/CountryApi";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import PageSizeSelect from "../../components/PageSizeSelect";
import Button from "../../components/Button";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import AlertBox from "../../components/AlertBox";
import NewServiceFeeModal from "./NewServiceFeeModal";
import { Pencil } from "../../common/Icons/Pencil";
import { Trash } from "../../common/Icons/Trash";
import SimpleInput from "../../components/InputFieldsSimple/SimpleInput";
import { useDebouncedState } from "../../hooks/hooks";
import { PageResponse } from "../../models/PageResponse";
import EmptyListText from "../../components/EmptyListText";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import { getActiveUserId } from "../../util/LocalStorageVariables";

interface Props {
  clinic: ClinicResponse;
  clinicId: string;
  isActiveTab: boolean;
}

const ClinicServiceFees: React.FC<Props> = ({
  clinic,
  clinicId,
  isActiveTab,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [serviceFees, setServiceFees] = useState<ServiceFeeResponse[]>([]);
  const [allServiceFees, setAllServiceFees] =
    useState<PageResponse<ServiceFeeResponse>>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalFees, setTotalFees] = useState<number>(0);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isNewFeeModalOpen, setNewFeeModalOpen] = useState<boolean>(false);
  const [feeForEdit, setFeeForEdit] = useState<ServiceFeeResponse>();
  const productSearchParams = useDebouncedState<string>("", 600);

  const closeNewFeeModal = () => {
    setNewFeeModalOpen(false);

    if (feeForEdit) {
      setFeeForEdit(undefined);
    }
  };

  useEffect(() => {
    const getCountryDetails = async () => {
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
        setCountryDetails(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      }
    };

    void getCountryDetails();
  }, [clinicId]);

  const deleteServiceFee = async (feeId: string) => {
    setLoaded(false);

    try {
      await ServiceFeeApi.removeServiceFee(clinicId, feeId);
      setServiceFees((prev) => prev.filter((f) => f.id !== feeId));
      setAllServiceFees((prev) => {
        if (prev) {
          return {
            ...prev,
            elements: prev?.elements.filter((f) => f.id !== feeId),
          };
        }
        return prev;
      });
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoaded(true);
    }
  };

  const setResults = (result: PageResponse<ServiceFeeResponse>) => {
    setServiceFees(result.elements);
    setTotalPages(result.totalPages);
    setTotalFees(result.totalElements);
  };

  useEffect(() => {
    const getServiceFees = async () => {
      setLoaded(false);

      try {
        const response = await ServiceFeeApi.getServiceFeesOfClinic(
          clinicId,
          pageSize,
          currentPage
        );
        setAllServiceFees(response.data);
        setResults(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoaded(true);
      }
    };

    if (isActiveTab) {
      void getServiceFees();
    }
    /* eslint-disable-next-line */
  }, [clinicId, isActiveTab, currentPage, pageSize]);

  useEffect(() => {
    const searchFee = async (query: string) => {
      setLoaded(false);

      try {
        const response = await ServiceFeeApi.searchServiceFeesOfClinic(
          clinicId,
          query
        );
        setResults(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoaded(true);
      }
    };
    if (productSearchParams.value === "") {
      if (allServiceFees) {
        setResults(allServiceFees);
      }
    } else {
      void searchFee(productSearchParams.value);
    }
    /* eslint-disable-next-line */
  }, [productSearchParams.value]);

  const currentUserId = getActiveUserId();
  const canEdit =
    canManageClinic(clinic) ||
    clinic.vets.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.assistants.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.authorizedAssistants.find((i) => i.userId === currentUserId) !==
      undefined;

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex">
          <p className="uppercase">{strings.customFees}</p>
        </div>
        <div>
          <div className="p-4 space-y-4">
            <AlertBox message={error} />
            <div className="flex">
              <SimpleInput
                className="mb-0"
                label={strings.search}
                name="searchFee"
                onChange={(e: string) => {
                  productSearchParams.setValue(e);
                }}
              />
            </div>
            <Button
              variant="link"
              onClick={() => setNewFeeModalOpen(true)}
              hidden={!canEdit}
            >
              <div className="flex">
                <PlusIcon /> <span>{strings.addFee}</span>
              </div>
            </Button>
            {!loaded ? (
              <div className="tw-table-container py-3.5 px-4">
                <LoaderInline />
              </div>
            ) : (
              <>
                {serviceFees.length === 0 ? (
                  <div className="tw-table-container py-3.5 px-4">
                    <EmptyListText />
                  </div>
                ) : (
                  <div className="tw-table-container">
                    <table className="tw-table">
                      <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
                        <tr>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            {strings.name}
                          </th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            {strings.price}
                          </th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            {strings.vat}
                          </th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            {strings.accountingCode}
                          </th>
                          <th hidden={!canEdit} />
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {serviceFees
                          .sort((f1, f2) => f1.name.localeCompare(f2.name))
                          .map((fee) => (
                            <tr key={fee.id}>
                              <td className="px-4 py-3 text-sm font-medium">
                                {fee.name}
                              </td>
                              <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                {NumberFormatter.formatPrice(
                                  CostCalculator.getGrossPriceOfItem(
                                    {
                                      ...fee,
                                      quantity: 1,
                                      price: fee.price,
                                    },
                                    countryDetails?.roundingDecimalLength
                                  ),
                                  fee.currency
                                )}

                                <div className="text-xs text-gray-500">
                                  {NumberFormatter.formatPrice(
                                    fee.price,
                                    fee.currency
                                  )}{" "}
                                  {strings.plusVat}
                                </div>
                              </td>
                              <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                {fee.vat}%
                              </td>
                              <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                {fee.accountingCode}
                              </td>
                              <td
                                hidden={!canEdit}
                                className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right"
                              >
                                <Button
                                  aria-label={strings.edit}
                                  onClick={() => {
                                    setFeeForEdit(fee);
                                    setNewFeeModalOpen(true);
                                  }}
                                  variant="icon"
                                >
                                  <span className="sr-only">
                                    {strings.edit}
                                  </span>
                                  <Pencil />
                                </Button>
                                <Button
                                  askAreYouSure={{ enable: true }}
                                  disabled={!loaded}
                                  aria-label={strings.delete}
                                  className="tw-link ml-3"
                                  onClick={() => {
                                    void deleteServiceFee(fee.id);
                                  }}
                                  variant="icon"
                                >
                                  <span className="sr-only">
                                    {strings.delete}
                                  </span>
                                  <Trash />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
            <div className="flex flex-wrap items-center">
              <PageSizeSelect
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalPages={
                  serviceFees.length > 0 ? Math.ceil(totalFees / pageSize) : 1
                }
                totalResults={totalFees}
              />
              <div className="ml-auto">
                <Paging
                  currentPage={currentPage}
                  totalPages={totalPages}
                  selectPage={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
            <Button
              variant="link"
              onClick={() => setNewFeeModalOpen(true)}
              hidden={!canEdit}
            >
              <div className="flex">
                <PlusIcon /> <span>{strings.addFee}</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <NewServiceFeeModal
        addNewFee={(newFee: ServiceFeeResponse) => {
          setServiceFees((prev) => [
            newFee,
            ...prev.filter((i) => i !== feeForEdit),
          ]);
          setAllServiceFees((prev) => {
            if (prev) {
              return {
                ...prev,
                elements: [
                  newFee,
                  ...prev.elements.filter((i) => i !== feeForEdit),
                ],
              };
            }
            return prev;
          });
        }}
        clinicId={clinicId}
        isOpen={isNewFeeModalOpen}
        close={closeNewFeeModal}
        countryDetails={countryDetails}
        fee={feeForEdit}
      />
    </>
  );
};

export default ClinicServiceFees;
