/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import logger from "../../util/logger";
import { combineClassNames } from "../../util/HtmlUtils";
import {
  AudioVideoToggleData,
  countMediaTracksForVideo,
  displayMediaDebug,
  isVideoLive,
  toggleMediaTrack,
  VideoChatUserConnection,
  VideoLoadState,
} from "./Utils";
import { Speaker } from "../../common/Icons/Speaker";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import HoverChecker from "../../util/HoverChecker";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { useFullscreen } from "../../hooks/useFullscreen";
import { SpeakerOff } from "../../common/Icons/SpeakerOff";
import { useVoiceDetector } from "./useVoiceDetector";

interface Props {
  callControls: ReactElement;
  remoteUser: VideoChatUserConnection;
}

const TRANSPARENT_BORDER = true;

/*
 * Video component for remote users.
 */
export const RemoteVideo: React.FC<Props> = ({
  callControls,
  remoteUser,
}: Props): ReactElement => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoLoadState, setVideoLoadState] = useState<VideoLoadState>(
    "NOT_STARTED_LOADING"
  );

  const [camEnabled, setCamEnabled] = useState(false);
  const [micEnabled, setMicEnabled] = useState(true);

  const [camMissing, setCamMissing] = useState(false);
  const [micMissing, setMicMissing] = useState(false);

  const isVoiceActive = useVoiceDetector(videoRef, micEnabled, micMissing);

  const videoIsLive = isVideoLive(videoRef);
  const showVideo =
    !camMissing &&
    camEnabled &&
    videoLoadState === "LOADING_SUCCESSFUL" &&
    videoIsLive;

  const [bgColor, setBgColor] = useState<string>("gray");
  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [volume, setVolume] = useState(1); // Default volume set to maximum (100%)
  const [savedVolume, setSavedVolume] = useState(1);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const sliderRef = useRef<HTMLInputElement | null>(null);
  const isAdjustingVolume = useRef(false);

  const remoteUserName = remoteUser.user?.name || remoteUser.id;

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  useEffect(() => {
    const mediaConfig = remoteUser.mediaConfig;

    if (mediaConfig) {
      setCamEnabled(mediaConfig?.camEnabled);
      setMicEnabled(mediaConfig?.micEnabled);
      setCamMissing(mediaConfig?.camMissing);
      setMicMissing(mediaConfig?.micMissing);
    }
  }, [remoteUser.mediaConfig]);

  useEffect(() => {
    if (!remoteUser.dataConnection) {
      return undefined;
    }

    // Synchronize the state to the remote peer's state
    const handleData = (message: any) => {
      const data: AudioVideoToggleData = JSON.parse(message);

      // Set microphone state and toggle the audio track
      setMicEnabled(data.micEnabled);
      toggleMediaTrack(videoRef, "audio", data.micEnabled);

      // Set camera state and toggle the video track
      setCamEnabled(data.camEnabled);
      toggleMediaTrack(videoRef, "video", data.camEnabled);
    };

    // Add peer callbacks
    remoteUser.dataConnection.on("data", handleData);

    logger.info(
      `[📹VideoChat] Added peer data callback for ${remoteUserName}.`
    );

    // Remove peer callback in the cleanup function
    return () => {
      remoteUser.dataConnection?.off("data", handleData);
      logger.info(
        `[📹VideoChat] Removed peer data callback for ${remoteUserName}.`
      );
    };
  }, [remoteUser.dataConnection, remoteUserName]);

  /**
   * Warning: Setting the video source in a useEffect by watching the mediaConnection as a dependency can cause
   * a strange bug where the dependency change is not detected sometimes, thus not setting the video source!
   * The issue happens when this component is created before the mediaConnection prop has a value,
   * and when the prop finally gets a non-null value, the useEffect oddly won't run.
   * This if statement still works every time though.
   */
  if (
    videoRef.current &&
    !videoRef.current.srcObject &&
    remoteUser.mediaConnection?.remoteStream
  ) {
    videoRef.current.srcObject = remoteUser.mediaConnection.remoteStream;
    logger.info(`[📹VideoChat] Stream has been loaded for ${remoteUserName}.`);
  }

  const handleCalculateAverageColor = (color: string) => {
    setBgColor(color);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    setSavedVolume(newVolume);

    if (newVolume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const handleVolumeSeekStart = () => {
    isAdjustingVolume.current = true;
  };

  const handleVolumeSeekEnd = () => {
    isAdjustingVolume.current = false;
  };

  const handleVolumeClick = () => {
    if (isMuted) {
      // Unmute
      setIsMuted(false);
      setVolume(savedVolume || 1);
    } else {
      // Mute
      setIsMuted(true);
      setSavedVolume(volume);
      setVolume(0);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  const calculateSoundSliderBackground = () => {
    const min = parseFloat(sliderRef.current?.min || "0");
    const max = parseFloat(sliderRef.current?.max || "1");

    const sliderValue = ((volume - min) / (max - min)) * 100;

    return `linear-gradient(to right, #fff ${sliderValue}%, #999 ${sliderValue}%)`;
  };

  return (
    <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
      {(_isHovered, isRecentlyHovered) => (
        <div
          className="relative w-full h-full flex justify-center items-center rounded-2xl"
          ref={containerRef}
        >
          {/* Peer video */}
          <video
            className={combineClassNames(
              "w-full h-full rounded-2xl object-cover",
              micEnabled && isVoiceActive
                ? "outline outline-4 -outline-offset-4 outline-blue-600 dark:outline-blue-400"
                : "",
              isFullscreen ? "fullscreen-video" : ""
            )}
            autoPlay
            hidden={!showVideo}
            id="peer-video"
            muted={micMissing || !micEnabled}
            onLoadStart={() => setVideoLoadState("STARTED_LOADING")}
            onCanPlay={() => setVideoLoadState("LOADING_SUCCESSFUL")}
            playsInline
            ref={videoRef}
          />
          {/* Peer video placeholder: Profile picture and loader */}
          {!showVideo && (
            <div
              className={combineClassNames(
                "flex justify-center items-center w-full h-full border-4 rounded-xl",
                micEnabled && isVoiceActive
                  ? "border-blue-600 dark:border-blue-400"
                  : TRANSPARENT_BORDER
                  ? "border-transparent"
                  : "border-gray-400 dark:border-gray-700"
              )}
              style={{
                backgroundColor:
                  camMissing || videoLoadState === "LOADING_SUCCESSFUL"
                    ? bgColor
                    : "gray",
              }}
            >
              {!camMissing && videoLoadState === "STARTED_LOADING" ? (
                <LoaderInline size={SpinnerSize.VideoChat} />
              ) : (
                (camMissing || !camEnabled) && (
                  <div className="absolute w-28 h-28">
                    <UserProfilePicture
                      onCalculateAverageColor={handleCalculateAverageColor}
                      userId={remoteUser.user?.id}
                    />
                  </div>
                )
              )}
            </div>
          )}
          {/* User information */}
          <p className="text-white absolute top-5 left-5 bg-gray-500 bg-opacity-40 rounded-full p-2">
            {remoteUserName}
          </p>
          {debugMode &&
            displayMediaDebug({
              camEnabled,
              camMissing,
              micEnabled,
              micMissing,
              videoLoadState,
              videoIsLive,
              streamId:
                (videoRef.current?.srcObject as MediaStream)?.id || "No stream",
              audioTracks: countMediaTracksForVideo(videoRef, "audio"),
              videoTracks: countMediaTracksForVideo(videoRef, "video"),
              liveAudioTracks: countMediaTracksForVideo(
                videoRef,
                "audio",
                true
              ),
              liveVideoTracks: countMediaTracksForVideo(
                videoRef,
                "video",
                true
              ),
            })}
          {!micEnabled && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <MicrophoneOff variant="outline" />
            </div>
          )}
          {micEnabled && isVoiceActive && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <Speaker />
            </div>
          )}
          {/* Sound slider */}
          <div
            className="absolute bottom-5 right-16 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            style={{ opacity: isRecentlyHovered ? 1 : 0 }}
          >
            <div className="flex justify-center items-center gap-2">
              <div onClick={handleVolumeClick} role="button" tabIndex={0}>
                {isMuted ? <SpeakerOff /> : <Speaker />}
              </div>
              <input
                className="volume-slider"
                max="1"
                min="0"
                onChange={handleVolumeChange}
                onMouseDown={handleVolumeSeekStart}
                onMouseUp={handleVolumeSeekEnd}
                onTouchEnd={handleVolumeSeekEnd}
                onTouchStart={handleVolumeSeekStart}
                ref={sliderRef}
                step="0.01"
                style={{
                  background: calculateSoundSliderBackground(),
                }}
                type="range"
                value={volume}
              />
            </div>
          </div>
          <div
            className="absolute bottom-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            onClick={toggleFullscreen}
            role="button"
            tabIndex={0}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </div>
          {isFullscreen && (
            <div
              className="absolute bottom-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              {callControls}
            </div>
          )}
        </div>
      )}
    </HoverChecker>
  );
};
